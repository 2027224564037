import React from 'react'
// import Swiper from 'swiper'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css"; // Import Swiper styles
import "swiper/css";

import { Link } from "react-router-dom"; // For navigation

function Home() {
    
    const users = [
        { id: 1, img: "assets/img/user4.jpg", status: "bg-success" },
        { id: 2, img: "assets/img/user2.jpg", status: "bg-danger" },
        { id: 3, img: "assets/img/user3.jpg", status: "bg-warning" },
        { id: 4, img: "assets/img/user1.jpg", status: "bg-success" },
        { id: 5, img: "assets/img/user4.jpg", status: "bg-success" },
        { id: 6, img: "assets/img/user2.jpg", status: null },
        { id: 7, img: "assets/img/user3.jpg", status: "bg-success" },
        { id: 8, img: "assets/img/user1.jpg", status: null },
    ];

    const companies = [
        { id: 1, img: "assets/img/company2.png" },
        { id: 2, img: "assets/img/company3.jpg" },
        { id: 3, img: "assets/img/company4.jpg" },
        { id: 4, img: "assets/img/company5.png" },
        { id: 5, img: "assets/img/company2.png" },
        { id: 6, img: "assets/img/company3.jpg" },
        { id: 7, img: "assets/img/company4.jpg" },
        { id: 8, img: "assets/img/company5.png" },
    ];

    const categories = [
        { icon: "bi-building", name: "All" },
        { icon: "bi-tv", name: "Electronics" },
        { icon: "bi-truck", name: "Transport" },
        { icon: "bi-speaker", name: "Music" },
        { icon: "bi-wallet2", name: "Accessories" },
        { icon: "bi-camera", name: "Camera" },
        { icon: "bi-gem", name: "Jewellery" }
    ];

    return (
        <>
            {/* loader section */}
            {/* <div className="container-fluid loader-wrap">
                    <div className="row h-100">
                        <div className="col-10 col-md-6 col-lg-5 col-xl-3 mx-auto text-center align-self-center">
                            <div className="logo-wallet">
                                <div className="wallet-bottom"></div>
                                <div className="wallet-cards" />
                                <div className="wallet-top"></div>
                            </div>
                            <p className="mt-4">
                                <span className="text-secondary">Track finance with Wallet app</span>
                                <br />
                                <strong>Please wait...</strong>
                            </p>
                        </div>
                    </div>
                </div> */}
            {/* loader section ends */}
            {/* Sidebar main menu */}
            <div className="sidebar-wrap  sidebar-overlay">
                {/* Add pushcontent or fullmenu instead overlay */}
                <div className="closemenu text-muted">Close Menu</div>
                <div className="sidebar ">
                    {/* user information */}
                    <div className="row my-3">
                        <div className="col-12 profile-sidebar">
                            <div className="clearfix" />
                            <div className="circle small one" />
                            <div className="circle small two" />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 194.287 141.794"
                                className="menubg"
                            >
                                <defs>
                                    <linearGradient
                                        id="linear-gradient"
                                        x1="0.5"
                                        x2="0.5"
                                        y2={1}
                                        gradientUnits="objectBoundingBox"
                                    >
                                        <stop offset={0} stopColor="#09b2fd" />
                                        <stop offset={1} stopColor="#6b00e5" />
                                    </linearGradient>
                                </defs>
                                <path
                                    id="menubg"
                                    d="M672.935,207.064c-19.639,1.079-25.462-3.121-41.258,10.881s-24.433,41.037-49.5,34.15-14.406-16.743-50.307-29.667-32.464-19.812-16.308-41.711S500.472,130.777,531.872,117s63.631,21.369,93.913,15.363,37.084-25.959,56.686-19.794,4.27,32.859,6.213,44.729,9.5,16.186,9.5,26.113S692.573,205.985,672.935,207.064Z"
                                    transform="translate(-503.892 -111.404)"
                                    fill="url(#linear-gradient)"
                                />
                            </svg>
                            <div className="row mt-3">
                                <div className="col-auto">
                                    <figure className="avatar avatar-80 rounded-20 p-1 bg-white shadow-sm">
                                        <img src="assets/img/user1.jpg" alt="" className="rounded-18" />
                                    </figure>
                                </div>
                                <div className="col px-0 align-self-center">
                                    <h5 className="mb-2">John Winsels</h5>
                                    <p className="text-muted size-12">
                                        New York City,
                                        <br />
                                        United States
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* user emnu navigation */}
                    <div className="row">
                        <div className="col-12">
                            <ul className="nav nav-pills">
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        aria-current="page"
                                        href="/"
                                    >
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-house-door" />
                                        </div>
                                        <div className="col">Dashboard</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>

                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        href="#"
                                        role="button"
                                        aria-expanded="false"
                                    >
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-cart" />
                                        </div>
                                        <div className="col">Shop</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-down plus" />{" "}
                                            <i className="bi bi-chevron-up minus" />
                                        </div>
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <a className="dropdown-item nav-link" href="/shop">
                                                <div className="avatar avatar-40  icon">
                                                    <i className="bi bi-shop" />
                                                </div>
                                                <div className="col align-self-center">Shop home</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/products">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-box-seam" />
                                                </div>
                                                <div className="col align-self-center">All Products</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/product">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-box-seam" />
                                                </div>
                                                <div className="col align-self-center">Product</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/cart">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-bag" />
                                                </div>
                                                <div className="col align-self-center">Cart</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/myorders">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-view-list" />
                                                </div>
                                                <div className="col align-self-center">My Orders</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/payment">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-cash-stack" />
                                                </div>
                                                <div className="col align-self-center">Payment</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/invoice">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-receipt" />
                                                </div>
                                                <div className="col align-self-center">Invoice</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/chat" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-chat-text" />
                                        </div>
                                        <div className="col">Messages</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/notifications" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-bell" />
                                        </div>
                                        <div className="col">Notification</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/blog" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-newspaper" />
                                        </div>
                                        <div className="col">Blogs</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/style" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-palette" />
                                        </div>
                                        <div className="col">
                                            Style <i className="bi bi-star-fill text-warning small" />
                                        </div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/pages" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-file-earmark-text" />
                                        </div>
                                        <div className="col">
                                            Pages <span className="badge bg-info fw-light">new</span>
                                        </div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/signin" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-box-arrow-right" />
                                        </div>
                                        <div className="col">Logout</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Sidebar main menu ends */}
            {/* Begin page */}
            <main className="h-100">
                {/* Header */}
                <header className="header position-fixed">
                    <div className="row">
                        <div className="col-auto">
                            <a
                                href="javascript:void(0)"
                                target="_self"
                                className="btn btn-light btn-44 menu-btn"
                            >
                                <i className="bi bi-list" />
                            </a>
                        </div>
                        <div className="col text-center">
                            <div className="logo-small">
                                <a href='/'> <img src="assets/img/logo.png" alt="" />
                                    <h5>
                                        <span className="text-secondary fw-light">Finance</span>
                                        <br />
                                        Wallet
                                    </h5>
                                </a>
                            </div>
                        </div>
                        <div className="col-auto">
                            <a
                                href="/profile"
                                target="_self"
                                className="btn btn-light btn-44"
                            >
                                <i className="bi bi-person-circle" />
                                <span className="count-indicator" />
                            </a>
                        </div>
                    </div>
                </header>
                {/* Header ends */}
                {/* main page content */}
                <div className="main-container container" style={{ marginTop: '15px', marginBottom: '20px' }}>
                    {/* balance */}
                    <div className="row my-4 text-center" >
                        <div className="col-12 " >
                            <h1 className="fw-light mb-2">$ 1,050.00</h1>
                            <p className="text-secondary">Total Balance</p>
                        </div>
                    </div>
                    {/* income expense*/}
                    <div className="row mb-4">
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-auto">
                                            <div className="avatar avatar-40 p-1 shadow-sm shadow-success rounded-15">
                                                <div className="icons bg-success text-white rounded-12">
                                                    <i className="bi bi-arrow-down-left" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col align-self-center ps-0">
                                            <p className="size-10 text-secondary mb-0">Income</p>
                                            <p>1542k</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-auto">
                                            <div className="avatar avatar-40 p-1 shadow-sm shadow-danger rounded-15">
                                                <div className="icons bg-danger text-white rounded-12">
                                                    <i className="bi bi-arrow-up-right" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col align-self-center ps-0">
                                            <p className="size-10 text-secondary mb-0">Expense</p>
                                            <p>1212k</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* categories list */}
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="card bg-theme text-white">
                                <div className="card-body pb-0">
                                    <div className="row justify-content-between gx-0 mx-0 pb-3">
                                        <div className="col-auto text-center">
                                            <a
                                                href="/pay"
                                                className="avatar avatar-60 p-1 shadow-sm rounded-15 bg-opac mb-2"
                                            >
                                                <div className="icons bg-success text-white rounded-12 bg-opac">
                                                    <i className="bi bi-receipt-cutoff size-22" />
                                                </div>
                                            </a>
                                            <p className="size-10">Pay</p>
                                        </div>
                                        <div className="col-auto text-center">
                                            <a
                                                href="/sendmoney"
                                                className="avatar avatar-60 p-1 shadow-sm rounded-15 bg-opac mb-2"
                                            >
                                                <div className="icons bg-success text-white rounded-12 bg-opac">
                                                    <i className="bi bi-arrow-up-right size-22" />
                                                </div>
                                            </a>
                                            <p className="size-10">Send</p>
                                        </div>
                                        <div className="col-auto text-center">
                                            <a
                                                href="/receivemoney"
                                                className="avatar avatar-60 p-1 shadow-sm rounded-15 bg-opac mb-2"
                                            >
                                                <div className="icons bg-success text-white rounded-12 bg-opac">
                                                    <i className="bi bi-arrow-down-left size-22" />
                                                </div>
                                            </a>
                                            <p className="size-10">Receive</p>
                                        </div>
                                        <div className="col-auto text-center">
                                            <a
                                                href="/withdraw"
                                                className="avatar avatar-60 p-1 shadow-sm rounded-15 bg-opac mb-2"
                                            >
                                                <div className="icons bg-success text-white rounded-12 bg-opac">
                                                    <i className="bi bi-bank size-22" />
                                                </div>
                                            </a>
                                            <p className="size-10">Withdraw</p>
                                        </div>
                                    </div>
                                    <div
                                        className="row justify-content-between gx-0 mx-0 collapse"
                                        id="collpasemorelink"
                                    >
                                        <div className="col-auto text-center">
                                            <a
                                                href="/bills"
                                                className="avatar avatar-60 p-1 shadow-sm rounded-15 bg-opac mb-2"
                                            >
                                                <div className="icons bg-success text-white rounded-12 bg-opac">
                                                    <i className="bi bi-tv size-22" />
                                                </div>
                                            </a>
                                            <p className="size-10">TV</p>
                                        </div>
                                        <div className="col-auto text-center">
                                            <a
                                                href="/addmoney"
                                                className="avatar avatar-60 p-1 shadow-sm rounded-15 bg-opac mb-2"
                                            >
                                                <div className="icons bg-success text-white rounded-12 bg-opac">
                                                    <i className="bi bi-wallet2 size-22" />
                                                </div>
                                            </a>
                                            <p className="size-10">Add Money</p>
                                        </div>
                                        <div className="col-auto text-center">
                                            <a
                                                href="/shop"
                                                className="avatar avatar-60 p-1 shadow-sm rounded-15 bg-opac mb-2"
                                            >
                                                <div className="icons bg-success text-white rounded-12 bg-opac">
                                                    <i className="bi bi-cart size-22" />
                                                </div>
                                            </a>
                                            <p className="size-10">Buy</p>
                                        </div>
                                        <div className="col-auto text-center">
                                            <a
                                                href="/rewards"
                                                className="avatar avatar-60 p-1 shadow-sm rounded-15 bg-opac mb-2"
                                            >
                                                <div className="icons bg-success text-white rounded-12 bg-opac">
                                                    <i className="bi bi-cash-coin size-22" />
                                                </div>
                                            </a>
                                            <p className="size-10">Cashback</p>
                                        </div>
                                    </div>
                                    <button
                                        className="btn btn-link mt-0 py-1 w-100 bar-more collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collpasemorelink"
                                        aria-expanded="false"
                                        aria-controls="collpasemorelink"
                                    >
                                        <span className="" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* People */}
                    <div className="row mb-2">
                        <div className="col">
                            <h6 className="title">People</h6>
                        </div>
                        <div className="col-auto">
                            <a href="/userlist" className="small">
                                View More
                            </a>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12 px-0">
                            <Swiper
                                spaceBetween={10}
                                slidesPerView="auto"
                                onSlideChange={() => console.log("slide change")}
                                onSwiper={(swiper) => console.log(swiper)}
                                className="connectionwiper"
                            >
                                {users.map((user) => (
                                    <SwiperSlide key={user.id}>
                                        <Link to="/profile" className="card text-center">
                                            <div className="card-body p-1">
                                                {user.status && (
                                                    <div
                                                        className={`position-absolute end-0 top-0 z-index-1 online-status ${user.status}`}
                                                    ></div>
                                                )}
                                                <figure className="avatar avatar-80 shadow-sm rounded-20">
                                                    <img src={user.img} alt={`User ${user.id}`} />
                                                </figure>
                                            </div>
                                        </Link>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                    {/* Companies */}
                    <div className="row mb-2">
                        <div className="col">
                            <h6 className="title">Companies</h6>
                        </div>
                        <div className="col-auto">
                            <a href="/bills" className="small">
                                Pay Bill
                            </a>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-12 px-0">
                            {/* swiper categories */}
                            <Swiper
                                spaceBetween={5}
                                slidesPerView='auto'
                                className="connectionwiper"
                            >
                                {categories.map((category, index) => (
                                    <SwiperSlide key={index}>
                                        <div className={`tag border ${index === 0 ? "active" : ""}`}>
                                            <i className={`bi ${category.icon}`} />
                                            <span className="text-uppercase">{category.name}</span>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                    
                        <div className="row mb-3">
                            <div className="col-12 px-0">
                                <Swiper
                                    spaceBetween={10} // Adjust space between slides
                                    slidesPerView="auto" // Number of visible slides at once
                                    onSlideChange={() => console.log("slide change")}
                                    onSwiper={(swiper) => console.log(swiper)}
                                    className="connectionwiper"
                                >
                                    {companies.map((company) => (
                                        <SwiperSlide key={company.id}>
                                            <Link to="/bills" className="card text-center">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-80 shadow-sm rounded-20">
                                                        <img src={company.img} alt={`Company ${company.id}`} />
                                                    </figure>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    
                    {/* My Goals and Targets */}
                    <div className="row mb-2">
                        <div className="col">
                            <h6 className="title">My Goals and Targets</h6>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4 mb-3">
                            <div className="card text-center overflow-hidden">
                                <figure className="m-0 p-0 position-absolute top-0 start-0 w-100 h-100 coverimg">
                                    <img src="assets/img/image1.png" alt="" />
                                </figure>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-6 col-md-4 col-lg-3">
                                            <div className="card text-center shadow-sm">
                                                <div className="card-body">
                                                    <div className="avatar avatar-80 rounded-circle shadow-danger shadow-sm p-1 mb-3">
                                                        <div id="circlesaving">
                                                            <svg viewBox="0 0 100 100" style={{ display: "block", width: "100%" }}>
                                                                <path
                                                                    d="M 50,50 m 0,-47 a 47,47 0 1 1 0,94 a 47,47 0 1 1 0,-94"
                                                                    stroke="#FFD6DF"
                                                                    strokeWidth={6}
                                                                    fillOpacity={0}
                                                                />
                                                                <path
                                                                    d="M 50,50 m 0,-47 a 47,47 0 1 1 0,94 a 47,47 0 1 1 0,-94"
                                                                    stroke="rgb(255,52,94)"
                                                                    strokeWidth={6}
                                                                    fillOpacity={0}
                                                                    style={{
                                                                        strokeDasharray: "295.416, 295.416",
                                                                        strokeDashoffset: "103.396"
                                                                    }}
                                                                />
                                                            </svg>
                                                            <div
                                                                className="progressbar-text"
                                                                style={{
                                                                    position: "absolute",
                                                                    left: "50%",
                                                                    top: "50%",
                                                                    padding: 0,
                                                                    margin: 0,
                                                                    transform: "translate(-50%, -50%)",
                                                                    color: "rgb(0, 0, 0)",
                                                                    fontSize: 24
                                                                }}
                                                            >
                                                                65
                                                                <small className="size-12">
                                                                    %<small />
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className="text-secondary size-10 mb-0">Dream Car</p>
                                                    <p>
                                                        15402k
                                                        <span className="text-secondary size-10"> Saving</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-4 mb-4">
                            <div className="card text-center overflow-hidden">
                                <figure className="m-0 p-0 position-absolute top-0 start-0 w-100 h-100 coverimg">
                                    <img src="assets/img/image4.jpg" alt="" />
                                </figure>
                                <div className="card-body">
                                    <div className="row justify-content-end">
                                        <div className="col-6 col-md-4 col-lg-3">
                                            <div className="card text-center shadow-sm">
                                                <div className="card-body">
                                                    <div className="avatar avatar-80 rounded-circle shadow-warning shadow-sm p-1 mb-3">
                                                        <div id="circlesaving2">
                                                            <svg
                                                                viewBox="0 0 100 100"
                                                                style={{ display: "block", width: "100%" }}
                                                            >
                                                                <path
                                                                    d="M 50,50 m 0,-47 a 47,47 0 1 1 0,94 a 47,47 0 1 1 0,-94"
                                                                    stroke="#ffedc0"
                                                                    strokeWidth={6}
                                                                    fillOpacity={0}
                                                                />
                                                                <path
                                                                    d="M 50,50 m 0,-47 a 47,47 0 1 1 0,94 a 47,47 0 1 1 0,-94"
                                                                    stroke="rgb(255,189,23)"
                                                                    strokeWidth={6}
                                                                    fillOpacity={0}
                                                                    style={{
                                                                        strokeDasharray: "295.416, 295.416",
                                                                        strokeDashoffset: "53.1749"
                                                                    }}
                                                                />
                                                            </svg>
                                                            <div
                                                                className="progressbar-text"
                                                                style={{
                                                                    position: "absolute",
                                                                    left: "50%",
                                                                    top: "50%",
                                                                    padding: 0,
                                                                    margin: 0,
                                                                    transform: "translate(-50%, -50%)",
                                                                    color: "rgb(0, 0, 0)",
                                                                    fontSize: 24
                                                                }}
                                                            >
                                                                82
                                                                <small className="size-12">
                                                                    %<small />
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <p className="text-secondary size-10 mb-0">Dream Home</p>
                                                    <p>
                                                        55402k
                                                        <span className="text-secondary size-10"> Saving</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Dark mode switch */}
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="card shadow-sm">
                                <div className="card-body">
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            id="darkmodeswitch"
                                        />
                                        <label
                                            className="form-check-label text-muted px-2 "
                                            htmlFor="darkmodeswitch"
                                        >
                                            Activate Dark Mode
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* offers banner */}
                    {/* <div className="row mb-4">
                        <div className="col-12">
                            <div className="card theme-bg overflow-hidden">
                                <figure className="m-0 p-0 position-absolute top-0 start-0 w-100 h-100 coverimg right-center-img">
                                    <img src="assets/img/offerbg.png" alt=""  style={{ objectFit: 'cover', objectPosition: 'center right' }}/>
                                </figure>
                                <div className="card-body py-4">
                                    <div className="row">
                                        <div className="col-9 align-self-center">
                                            <h1 className="mb-3">
                                                <span className="fw-light">15% OFF</span>
                                                <br />
                                                GiftCard
                                            </h1>
                                            <p>Thank you for spending with us, You have received Gift Card.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="card theme-bg overflow-hidden">
                                <figure className="m-0 p-0 position-absolute top-0 start-0 w-100 h-100 coverimg right-center-img">
                                    <img src="assets/img/offerbg.png" alt="Offer Background" className="position-absolute top-50 end-0 translate-middle-y"
                                        style={{ maxHeight: '100%', objectFit: 'cover' }} />
                                </figure>
                                <div className="card-body py-4">
                                    <div className="row">
                                        <div className="col-9 align-self-center">
                                            <h1 className="mb-3">
                                                <span className="fw-light">15% OFF</span>
                                                <br />
                                                GiftCard
                                            </h1>
                                            <p>Thank you for spending with us, You have received a Gift Card.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Transactions */}
                    <div className="row mb-3">
                        <div className="col">
                            <h6 className="title">
                                Transactions
                                <br />
                                <small className="fw-normal text-muted">Today, 24 Aug 2021</small>
                            </h6>
                        </div>
                        <div className="col-auto align-self-center">
                            <a href="#" className="small">
                                View all
                            </a>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <ul className="list-group list-group-flush bg-none">
                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <div className="avatar avatar-60 shadow-sm card rounded-15 p-1">
                                                        <img
                                                            src="assets/img/company4.jpg"
                                                            alt=""
                                                            className="rounded-15"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col align-self-center ps-0">
                                                    <p className="text-secondary size-10 mb-0">Food</p>
                                                    <p>Zomato</p>
                                                </div>
                                                <div className="col align-self-center text-end">
                                                    <p className="text-secondary text-muted size-10 mb-0">
                                                        15-10-2021 | 10:20am
                                                    </p>
                                                    <p>-25.00</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <div className="avatar avatar-60 shadow-sm card rounded-15 p-1">
                                                        <img
                                                            src="assets/img/company5.png"
                                                            alt=""
                                                            className="rounded-15"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col align-self-center ps-0">
                                                    <p className="text-secondary size-10 mb-0">Travel</p>
                                                    <p>Uber</p>
                                                </div>
                                                <div className="col align-self-center text-end">
                                                    <p className="text-secondary text-muted size-10 mb-0">
                                                        15-10-2021 | 10:20am
                                                    </p>
                                                    <p>-26.00</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <div className="avatar avatar-60 shadow-sm card rounded-15 p-1">
                                                        <img
                                                            src="assets/img/company2.png"
                                                            alt=""
                                                            className="rounded-15"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col align-self-center ps-0">
                                                    <p className="text-secondary size-10 mb-0">Food</p>
                                                    <p>Starbucks</p>
                                                </div>
                                                <div className="col align-self-center text-end">
                                                    <p className="text-secondary text-muted size-10 mb-0">
                                                        15-10-2021 | 10:20am
                                                    </p>
                                                    <p>-18.00</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <div className="avatar avatar-60 shadow-sm card rounded-15 p-1">
                                                        <img
                                                            src="assets/img/company3.jpg"
                                                            alt=""
                                                            className="rounded-15"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col align-self-center ps-0">
                                                    <p className="text-secondary size-10 mb-0">Clothing</p>
                                                    <p>Walmart</p>
                                                </div>
                                                <div className="col align-self-center text-end">
                                                    <p className="text-secondary text-muted size-10 mb-0">
                                                        15-10-2021 | 10:20am
                                                    </p>
                                                    <p>-105.00</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Blogs */}
                    <div className="row mb-3">
                        <div className="col">
                            <h6 className="title">News and Upcomming</h6>
                        </div>
                        <div className="col-auto align-self-center">
                            <a href="/blog" className="small">
                                Read more
                            </a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <a href="/blogdetails" className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-auto">
                                            <div className="avatar avatar-60 shadow-sm rounded-10 coverimg">
                                                <img src="assets/img/news.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div className="col align-self-center ps-0">
                                            <p className="mb-1">Do share and Earn a lot</p>
                                            <p className="text-muted size-12">
                                                Get $10 instant as reward while your friend or invited
                                                member join finwallapp
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <a href="/blogdetails" className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-auto">
                                            <div className="avatar avatar-60 shadow-sm rounded-10 coverimg">
                                                <img src="assets/img/news1.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div className="col align-self-center ps-0">
                                            <p className="mb-1">Walmart news latest picks</p>
                                            <p className="text-muted size-12">
                                                Get $10 instant as reward while your friend or invited
                                                member join finwallapp
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <a href="/blogdetails" className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-auto">
                                            <div className="avatar avatar-60 shadow-sm rounded-10 coverimg">
                                                <img src="assets/img/news2.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div className="col align-self-center ps-0">
                                            <p className="mb-1">Do share and Help us</p>
                                            <p className="text-muted size-12">
                                                Get $10 instant as reward while your friend or invited
                                                member join finwallapp
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                {/* main page content ends */}
            </main>
            {/* Page ends*/}
            {/* Footer */}
            <footer className="footer" >
                <div className="container">
                    <ul className="nav nav-pills nav-justified">
                        <li className="nav-item">
                            <a className="nav-link  active" href="/">
                                <span>
                                    <i className="nav-icon bi bi-house" />
                                    <span className="nav-text">Home</span>
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/Statistics">
                                <span>
                                    <i className="nav-icon bi bi-binoculars" />
                                    <span className="nav-text">Statistics</span>
                                </span>
                            </a>
                        </li>
                        <li className="nav-item centerbutton">
                            <button
                                type="button"
                                className="nav-link"
                                data-bs-toggle="modal"
                                data-bs-target="#menumodal"
                                id="centermenubtn"
                            >
                                <span className="theme-radial-gradient">
                                    <i className="bi bi-grid size-22" />
                                </span>
                            </button>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/shop">
                                <span>
                                    <i className="nav-icon bi bi-bag" />
                                    <span className="nav-text">Shop</span>
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/wallet">
                                <span>
                                    <i className="nav-icon bi bi-wallet2" />
                                    <span className="nav-text">Wallet</span>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </footer>
            {/* Menu Modal */}
            <div className="modal fade" id="menumodal" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content shadow">
                        <div className="modal-body">
                            <h1 className="mb-4">
                                <span className="text-secondary fw-light">Quick</span>
                                <br />
                                Actions!
                            </h1>
                            <div className="text-center">
                                <img src="assets/img/QRCode.png" alt="" className="mb-2" />
                                <p className="small text-secondary mb-4">
                                    Ask to scan this QR-Code
                                    <br />
                                    to accept money
                                </p>
                            </div>
                            <div className="row justify-content-center mb-4">
                                <div className="col-auto text-center">
                                    <a
                                        href="/bills"
                                        className="avatar avatar-70 p-1 shadow-sm shadow-danger rounded-20 bg-opac mb-2"
                                        data-bs-dismiss="modal"
                                    >
                                        <div className="icons text-danger">
                                            <i className="bi bi-receipt-cutoff size-24" />
                                        </div>
                                    </a>
                                    <p className="size-10 text-secondary">Pay Bill</p>
                                </div>
                                <div className="col-auto text-center">
                                    <a
                                        href="/sendmoney"
                                        className="avatar avatar-70 p-1 shadow-sm shadow-purple rounded-20 bg-opac mb-2"
                                        data-bs-dismiss="modal"
                                    >
                                        <div className="icons text-purple">
                                            <i className="bi bi-arrow-up-right size-24" />
                                        </div>
                                    </a>
                                    <p className="size-10 text-secondary">Send Money</p>
                                </div>
                                <div className="col-auto text-center">
                                    <a
                                        href="/receivemoney"
                                        className="avatar avatar-70 p-1 shadow-sm shadow-success rounded-20 bg-opac mb-2"
                                        data-bs-dismiss="modal"
                                    >
                                        <div className="icons text-success">
                                            <i className="bi bi-arrow-down-left size-24" />
                                        </div>
                                    </a>
                                    <p className="size-10 text-secondary">Receive Money</p>
                                </div>
                            </div>
                            <div className="row justify-content-center mb-2">
                                <div className="col-auto text-center">
                                    <a
                                        href="/withdraw"
                                        className="avatar avatar-70 p-1 shadow-sm shadow-secondary rounded-20 bg-opac mb-2"
                                        data-bs-dismiss="modal"
                                    >
                                        <div className="icons text-secondary">
                                            <i className="bi bi-bank size-24" />
                                        </div>
                                    </a>
                                    <p className="size-10 text-secondary">Withdraw</p>
                                </div>
                                <div className="col-auto text-center">
                                    <a
                                        href="/addmoney"
                                        className="avatar avatar-70 p-1 shadow-sm shadow-warning rounded-20 bg-opac mb-2"
                                        data-bs-dismiss="modal"
                                    >
                                        <div className="icons text-warning">
                                            <i className="bi bi-wallet2 size-24" />
                                        </div>
                                    </a>
                                    <p className="size-10 text-secondary">Add Money</p>
                                </div>
                                <div className="col-auto text-center">
                                    <div
                                        className="avatar avatar-70 p-1 shadow-sm shadow-info rounded-20 bg-opac mb-2"
                                        data-bs-dismiss="modal"
                                    >
                                        <div className="icons text-info">
                                            <i className="bi bi-tv size-24" />
                                        </div>
                                    </div>
                                    <p className="size-10 text-secondary">Recharge</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer ends*/}

        </>
    )
}

export default Home