import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; // Import Swiper styles

function Product() {

    const cards = [
        { image: 'assets/img/categories1.png' },
        { image: 'assets/img/categories1.png' },
        { image: 'assets/img/categories1.png' },
    ];

   
    const products = [
        {
            image: 'assets/img/categories3.jpg',
            title: 'LAVAA 3500',
            price: '$ 459.00',
        },
        {
            image: 'assets/img/categories4.png',
            title: 'Galaxy S20',
            price: '$ 459.00',
        },
        {
            image: 'assets/img/categories5.png',
            title: 'iPhone 12SR',
            price: '$ 1012.00',
        },
        {
            image: 'assets/img/categories3.jpg',
            title: 'LAVAA 3500',
            price: '$ 459.00',
        },
        {
            image: 'assets/img/categories4.png',
            title: 'Galaxy S20',
            price: '$ 459.00',
        },
    ];


    return (
        <>
            {/* loader section */}
            {/* loader section ends */}
            {/* Sidebar main menu */}
            <div className="sidebar-wrap  sidebar-overlay">
                {/* Add pushcontent or fullmenu instead overlay */}
                <div className="closemenu text-muted">Close Menu</div>
                <div className="sidebar ">
                    {/* user information */}
                    <div className="row my-3">
                        <div className="col-12 profile-sidebar">
                            <div className="clearfix" />
                            <div className="circle small one" />
                            <div className="circle small two" />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 194.287 141.794"
                                className="menubg"
                            >
                                <defs>
                                    <linearGradient
                                        id="linear-gradient"
                                        x1="0.5"
                                        x2="0.5"
                                        y2={1}
                                        gradientUnits="objectBoundingBox"
                                    >
                                        <stop offset={0} stopColor="#09b2fd" />
                                        <stop offset={1} stopColor="#6b00e5" />
                                    </linearGradient>
                                </defs>
                                <path
                                    id="menubg"
                                    d="M672.935,207.064c-19.639,1.079-25.462-3.121-41.258,10.881s-24.433,41.037-49.5,34.15-14.406-16.743-50.307-29.667-32.464-19.812-16.308-41.711S500.472,130.777,531.872,117s63.631,21.369,93.913,15.363,37.084-25.959,56.686-19.794,4.27,32.859,6.213,44.729,9.5,16.186,9.5,26.113S692.573,205.985,672.935,207.064Z"
                                    transform="translate(-503.892 -111.404)"
                                    fill="url(#linear-gradient)"
                                />
                            </svg>
                            <div className="row mt-3">
                                <div className="col-auto">
                                    <figure className="avatar avatar-80 rounded-20 p-1 bg-white shadow-sm">
                                        <img src="assets/img/user1.jpg" alt="" className="rounded-18" />
                                    </figure>
                                </div>
                                <div className="col px-0 align-self-center">
                                    <h5 className="mb-2">John Winsels</h5>
                                    <p className="text-muted size-12">
                                        New York City,
                                        <br />
                                        United States
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* user emnu navigation */}
                    <div className="row">
                        <div className="col-12">
                            <ul className="nav nav-pills">
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        aria-current="page"
                                        href="/"
                                    >
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-house-door" />
                                        </div>
                                        <div className="col">Dashboard</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        href="#"
                                        role="button"
                                        aria-expanded="false"
                                    >
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-cart" />
                                        </div>
                                        <div className="col">Shop</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-down plus" />{" "}
                                            <i className="bi bi-chevron-up minus" />
                                        </div>
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <a className="dropdown-item nav-link" href="/shop">
                                                <div className="avatar avatar-40  icon">
                                                    <i className="bi bi-shop" />
                                                </div>
                                                <div className="col align-self-center">Shop home</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/products">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-box-seam" />
                                                </div>
                                                <div className="col align-self-center">All Products</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/product">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-box-seam" />
                                                </div>
                                                <div className="col align-self-center">Product</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/cart">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-bag" />
                                                </div>
                                                <div className="col align-self-center">Cart</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/myorders">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-view-list" />
                                                </div>
                                                <div className="col align-self-center">My Orders</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/payment">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-cash-stack" />
                                                </div>
                                                <div className="col align-self-center">Payment</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/invoice">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-receipt" />
                                                </div>
                                                <div className="col align-self-center">Invoice</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/chat" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-chat-text" />
                                        </div>
                                        <div className="col">Messages</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/notifications" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-bell" />
                                        </div>
                                        <div className="col">Notification</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/blog" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-newspaper" />
                                        </div>
                                        <div className="col">Blogs</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/style" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-palette" />
                                        </div>
                                        <div className="col">
                                            Style <i className="bi bi-star-fill text-warning small" />
                                        </div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/pages" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-file-earmark-text" />
                                        </div>
                                        <div className="col">
                                            Pages <span className="badge bg-info fw-light">new</span>
                                        </div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/signin" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-box-arrow-right" />
                                        </div>
                                        <div className="col">Logout</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Sidebar main menu ends */}
            {/* Begin page */}
            <main className="h-100">
                {/* Header */}
                <header className="header position-fixed">
                    <div className="row">
                        <div className="col-auto">
                            <a
                                href="javascript:void(0)"
                                target="_self"
                                className="btn btn-light btn-44 menu-btn"
                            >
                                <i className="bi bi-list" />
                            </a>
                        </div>
                        <div className="col text-center">
                            <div className="logo-small">
                                <a href='/'> <img src="assets/img/logo.png" alt="" />
                                    <h5>
                                        <span className="text-secondary fw-light">Finance</span>
                                        <br />
                                        Wallet
                                    </h5>
                                </a>
                            </div>
                        </div>
                        <div className="col-auto">
                            <a
                                href="/profile"
                                target="_self"
                                className="btn btn-light btn-44"
                            >
                                <i className="bi bi-person-circle" />
                                <span className="count-indicator" />
                            </a>
                        </div>
                    </div>
                </header>
                {/* Header ends */}
                {/* main page content */}
                <div className="main-container container">
                    {/* product banner */}
                    <div className="row">
                        <div className="col-12 px-0 position-relative">
                            <Swiper
                                spaceBetween={10}
                                slidesPerView={2} // Adjust this number for how many you want to display

                            >
                                {cards.map((card, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="card shadow-sm mb-3">
                                            <div className="card-body p-2">
                                                <img
                                                    src={card.image}
                                                    alt=""
                                                    className="w-100 rounded-15"
                                                />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                    <div className="row">
                    </div>
                    {/* name and description */}
                    <div className="row mb-2">
                        <div className="col">
                            <p className="text-secondary small">Electronics </p>
                        </div>
                        <div className="col-auto">
                            <p className="small">
                                <span className="text-secondary">5.0 (1445 Reviews)</span>
                                <i className="bi bi-star-fill text-warning" />
                            </p>
                        </div>
                    </div>
                    <h4 className="mb-2">Samsungs TV SM365200</h4>
                    <div className="row mb-3">
                        <div className="col align-self-center">
                            <h5>
                                $ 524.00 <s className="small text-secondary fw-light">$ 650.00</s>
                            </h5>
                        </div>
                        <div className="col-auto">
                            <div className="counter-number">
                                <button className="btn btn-sm avatar avatar-30 p-0 rounded-circle">
                                    <i className="bi bi-dash size-22" />
                                </button>
                                <span>2</span>
                                <button className="btn btn-sm avatar avatar-30 p-0 rounded-circle">
                                    <i className="bi bi-plus size-22" />
                                </button>
                            </div>
                        </div>
                    </div>
                    {/* delivery time */}
                    <div className="row">
                        <div className="col-12">
                            <div className="card bg-opac-50 shadow-sm mb-4">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-auto">
                                            <div className="card">
                                                <div className="card-body p-1">
                                                    <figure className="text-center mb-0 avatar avatar-50 bg-theme rounded-15">
                                                        <i className="bi bi-clock size-24 " />
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col align-self-center ps-0">
                                            <h6 className="mb-1">
                                                San Jose, USA
                                                <span className="text-color-theme float-end small">
                                                    Change <i className="bi bi-chevron-right" />
                                                </span>
                                            </h6>
                                            <p>
                                                <span className="text-opac">Delivery on:</span>{" "}
                                                <strong>7 Dec 2021</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* buttons */}
                    <div className="row mb-4">
                        <div className="col">
                            <a
                                // href="cart"
                                className="btn btn-default btn-lg shadow-sm w-100"
                            >
                                Add to Cart
                            </a>
                        </div>
                        <div className="col">
                            <a
                                href="/cart"
                                className="btn btn-success btn-lg shadow-sm w-100 text-white"
                            >
                                Buy Now
                            </a>
                        </div>
                    </div>
                    <h5 className="mb-3">Product Description</h5>
                    <p className="text-secondary">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
                        sollicitudin dignissim nisi, eget malesuada ligula ultricies sit amet.
                        Suspendisse efficitur ex eu est placerat mattis.
                    </p>
                    <p className="text-secondary">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
                        sollicitudin dignissim nisi, eget malesuada ligula ultricies sit amet.
                        Suspendisse efficitur ex eu est placerat mattis.
                    </p>
                    <br />
                    <p className="text-secondary small">
                        ** Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque
                        sollicitudin dignissim nisi, eget malesuada ligula ultricies sit amet.
                        Suspendisse efficitur ex eu est placerat mattis.
                    </p>
                    <br />
                    <div className="row mb-3">
                        <div className="col-12 text-center mb-3">
                            <button
                                className="btn btn-sm btn-outline-info me-2"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseExample"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                            >
                                <i className="bi bi-share" /> Share
                            </button>
                            <button className="btn btn-sm btn-outline-danger">
                                <i className="bi bi-heart" /> Wishlist
                            </button>
                        </div>
                        <div className="collapse col-12 " id="collapseExample">
                            <div className="justify-content-center text-center">
                                <p className="mb-1 text-opac">Share product with</p>
                                <a href="#" className="btn btn-link text-color-theme">
                                    <i className="bi bi-twitter" />
                                </a>
                                <a href="#" className="btn btn-link text-color-theme">
                                    <i className="bi bi-facebook" />
                                </a>
                                <a href="#" className="btn btn-link text-color-theme">
                                    <i className="bi bi-linkedin" />
                                </a>
                                <a href="#" className="btn btn-link text-color-theme">
                                    <i className="bi bi-google" />
                                </a>
                            </div>
                        </div>
                    </div>
                    {/* related product  */}
                    <div className="row mb-3">
                        <div className="col">
                            <h6 className="title">Related Products</h6>
                        </div>
                    </div>
                    {/*products */}
                    <div className="swiper-container connectionwiper">
                        <Swiper
                            spaceBetween={10}
                            slidesPerView="auto" // Adjust as needed

                        >
                            {products.map((product, index) => (
                                <SwiperSlide key={index} className="text-center">
                                    <a href="/product" className="card text-center bg-theme">
                                        <div className="card-body p-1">
                                            <figure className="avatar avatar-90 rounded-15 mb-1">
                                                <img src={product.image} alt={product.title} />
                                            </figure>
                                            <p className="text-center size-12">
                                                <small className="text-muted">{product.title}</small>
                                                <br />{product.price}
                                            </p>
                                        </div>
                                    </a>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
                {/* main page content ends */}
            </main>
            {/* Page ends*/}
        </>

    )
}

export default Product