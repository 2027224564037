import React from 'react'

function Blog() {
    return (
        <>
            {/* loader section */}
            
            {/* loader section ends */}
            {/* Sidebar main menu */}
            <div className="sidebar-wrap  sidebar-overlay">
                {/* Add pushcontent or fullmenu instead overlay */}
                <div className="closemenu text-muted">Close Menu</div>
                <div className="sidebar ">
                    {/* user information */}
                    <div className="row my-3">
                        <div className="col-12 profile-sidebar">
                            <div className="clearfix" />
                            <div className="circle small one" />
                            <div className="circle small two" />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 194.287 141.794"
                                className="menubg"
                            >
                                <defs>
                                    <linearGradient
                                        id="linear-gradient"
                                        x1="0.5"
                                        x2="0.5"
                                        y2={1}
                                        gradientUnits="objectBoundingBox"
                                    >
                                        <stop offset={0} stopColor="#09b2fd" />
                                        <stop offset={1} stopColor="#6b00e5" />
                                    </linearGradient>
                                </defs>
                                <path
                                    id="menubg"
                                    d="M672.935,207.064c-19.639,1.079-25.462-3.121-41.258,10.881s-24.433,41.037-49.5,34.15-14.406-16.743-50.307-29.667-32.464-19.812-16.308-41.711S500.472,130.777,531.872,117s63.631,21.369,93.913,15.363,37.084-25.959,56.686-19.794,4.27,32.859,6.213,44.729,9.5,16.186,9.5,26.113S692.573,205.985,672.935,207.064Z"
                                    transform="translate(-503.892 -111.404)"
                                    fill="url(#linear-gradient)"
                                />
                            </svg>
                            <div className="row mt-3">
                                <div className="col-auto">
                                    <figure className="avatar avatar-80 rounded-20 p-1 bg-white shadow-sm">
                                        <img src="assets/img/user1.jpg" alt="" className="rounded-18" />
                                    </figure>
                                </div>
                                <div className="col px-0 align-self-center">
                                    <h5 className="mb-2">John Winsels</h5>
                                    <p className="text-muted size-12">
                                        New York City,
                                        <br />
                                        United States
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* user emnu navigation */}
                    <div className="row">
                        <div className="col-12">
                            <ul className="nav nav-pills">
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        aria-current="page"
                                        href="/"
                                    >
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-house-door" />
                                        </div>
                                        <div className="col">Dashboard</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        href="#"
                                        role="button"
                                        aria-expanded="false"
                                    >
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-cart" />
                                        </div>
                                        <div className="col">Shop</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-down plus" />{" "}
                                            <i className="bi bi-chevron-up minus" />
                                        </div>
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <a className="dropdown-item nav-link" href="/shop">
                                                <div className="avatar avatar-40  icon">
                                                    <i className="bi bi-shop" />
                                                </div>
                                                <div className="col align-self-center">Shop home</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/products">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-box-seam" />
                                                </div>
                                                <div className="col align-self-center">All Products</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/product">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-box-seam" />
                                                </div>
                                                <div className="col align-self-center">Product</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/cart">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-bag" />
                                                </div>
                                                <div className="col align-self-center">Cart</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/myorders">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-view-list" />
                                                </div>
                                                <div className="col align-self-center">My Orders</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/payment">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-cash-stack" />
                                                </div>
                                                <div className="col align-self-center">Payment</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/invoice">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-receipt" />
                                                </div>
                                                <div className="col align-self-center">Invoice</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/chat" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-chat-text" />
                                        </div>
                                        <div className="col">Messages</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/notifications" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-bell" />
                                        </div>
                                        <div className="col">Notification</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/blog" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-newspaper" />
                                        </div>
                                        <div className="col">Blogs</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/style" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-palette" />
                                        </div>
                                        <div className="col">
                                            Style <i className="bi bi-star-fill text-warning small" />
                                        </div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/pages" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-file-earmark-text" />
                                        </div>
                                        <div className="col">
                                            Pages <span className="badge bg-info fw-light">new</span>
                                        </div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/signin" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-box-arrow-right" />
                                        </div>
                                        <div className="col">Logout</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Sidebar main menu ends */}
            {/* Begin page */}
            <main className="h-100">
                {/* Header */}
                <header className="header position-fixed">
                    <div className="row">
                        <div className="col-auto">
                            <a
                                href="javascript:void(0)"
                                target="_self"
                                className="btn btn-light btn-44 menu-btn"
                            >
                                <i className="bi bi-list" />
                            </a>
                        </div>
                        <div className="col text-center">
                                <div className="logo-small">
                                   <a href='/'> <img src="assets/img/logo.png" alt="" />
                                    <h5>
                                        <span className="text-secondary fw-light">Finance</span>
                                        <br />
                                        Wallet
                                    </h5>
                                    </a>
                                </div>
                            </div>
                        <div className="col-auto">
                            <a
                                href="/profile"
                                target="_self"
                                className="btn btn-light btn-44"
                            >
                                <i className="bi bi-person-circle" />
                                <span className="count-indicator" />
                            </a>
                        </div>
                    </div>
                </header>
                {/* Header ends */}
                {/* main page content */}
                <div className="main-container container">
                    {/* Blogs news pages */}
                    <div className="row mb-3">
                        <div className="col align-self-center">
                            <h6 className="mb-0">Today </h6>
                            <p className="text-secondary small">26/12/2021</p>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-44 btn-outline-secondary">
                                <i className="bi bi-chevron-left" />
                            </button>
                            <button className="btn btn-44 btn-outline-secondary">
                                <i className="bi bi-chevron-right" />
                            </button>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-12 px-0">
                            {/* swiper categories */}
                            <div className="swiper-container connectionwiper">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <div className="tag border active">
                                            <i className="bi bi-building" />
                                            <span className="text-uppercase">All</span>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="tag border ">
                                            <i className="bi bi-tv" />
                                            <span className="text-uppercase">Electronics</span>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="tag border ">
                                            <i className="bi bi-truck" />
                                            <span className="text-uppercase">Transport</span>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="tag border ">
                                            <i className="bi bi-speaker" />
                                            <span className="text-uppercase">Music</span>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="tag border ">
                                            <i className="bi bi-wallet2" />
                                            <span className="text-uppercase">Accessories</span>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="tag border ">
                                            <i className="bi bi-camera" />
                                            <span className="text-uppercase">Camera</span>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="tag border ">
                                            <i className="bi bi-gem" />
                                            <span className="text-uppercase">Jwellery</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="card card-theme mb-4 overflow-hidden shadow-sm text-white">
                                <div className="overlay" />
                                <div className="coverimg h-100 w-100 position-absolute opacity-3">
                                    <img src="assets/img/news1.jpg" alt="" />
                                </div>
                                <div className="card-body">
                                    <div className="row mb-5">
                                        <div className="col">
                                            <span className="tag bg-theme">Popular</span>
                                        </div>
                                        <div className="col-auto">
                                            <button className="btn btn-theme avatar avatar-40 p-0 rounded-circle shadow-sm">
                                                <i className="bi bi-share" />
                                            </button>
                                            <button className="btn btn-success text-white avatar avatar-40 p-0 rounded-circle shadow-sm">
                                                <i className="bi bi-bookmark" />
                                            </button>
                                        </div>
                                    </div>
                                    <br />
                                    <a
                                        href="/blogdetails"
                                        className="h4 mb-2 text-normal d-block text-white"
                                    >
                                        Best Discovery ever in UX
                                    </a>
                                    <p>
                                        Lorem ipsum dolor sit amet, consect etur adipiscing elit.
                                        Sndisse conv allis.
                                    </p>
                                    <div className="">
                                        <figure className="avatar avatar-30  rounded-10 mx-1">
                                            <img src="assets/img/user2.jpg" alt="" />
                                        </figure>
                                        Alessia Monks
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="card card-theme mb-4 overflow-hidden shadow-sm text-white">
                                <div className="overlay" />
                                <div className="coverimg h-100 w-100 position-absolute opacity-3">
                                    <img src="assets/img/news2.jpg" alt="" />
                                </div>
                                <div className="card-body">
                                    <div className="row mb-5">
                                        <div className="col">
                                            <span className="tag bg-theme">Trending</span>
                                        </div>
                                        <div className="col-auto">
                                            <button className="btn btn-theme avatar avatar-40 p-0 rounded-circle shadow-sm">
                                                <i className="bi bi-share" />
                                            </button>
                                            <button className="btn btn-success text-white avatar avatar-40 p-0 rounded-circle shadow-sm">
                                                <i className="bi bi-bookmark" />
                                            </button>
                                        </div>
                                    </div>
                                    <br />
                                    <a
                                        href="/blogdetails"
                                        className="h4 mb-2 text-normal d-block text-white "
                                    >
                                        Best Discovery ever in UX
                                    </a>
                                    <p>
                                        Lorem ipsum dolor sit amet, consect etur adipiscing elit.
                                        Sndisse conv allis.
                                    </p>
                                    <div className="">
                                        <figure className="avatar avatar-30 rounded-10 mx-1">
                                            <img src="assets/img/user3.jpg" alt="" />
                                        </figure>
                                        Jason Mandla
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-6 mb-3">
                            <div className="row">
                                <div className="col-5">
                                    <figure className="rounded-15 position-relative h-190 overflow-hidden shadow-sm">
                                        <div className="coverimg h-100 w-100 position-absolute start-0 top-0">
                                            <img src="assets/img/news1.jpg" alt="" />
                                        </div>
                                    </figure>
                                </div>
                                <div className="col align-self-center">
                                    <a
                                        href="/blogdetails"
                                        className="h5 mb-3 d-block text-normal"
                                    >
                                        Maxartkiller has awesome templae to start
                                    </a>
                                    <p className="text-secondary small">
                                        Lorem ipsum dolor sit amet, consect etur adipiscing elit.
                                        Sndisse conv allis.
                                    </p>
                                    <a href="/blogdetails" className="text-normal small">
                                        Read More <i className="bi bi-arrow-right" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                            <div className="row">
                                <div className="col-5">
                                    <figure className="rounded-15 position-relative h-190 overflow-hidden shadow-sm">
                                        <div className="coverimg h-100 w-100 position-absolute start-0 top-0">
                                            <img src="assets/img/news2.jpg" alt="" />
                                        </div>
                                    </figure>
                                </div>
                                <div className="col align-self-center">
                                    <a
                                        href="/blogdetails"
                                        className="h5 mb-3 d-block text-normal"
                                    >
                                        For the people by people Best Mobile template is here
                                    </a>
                                    <p className="text-secondary small">
                                        Lorem ipsum dolor sit amet, consect etur adipiscing elit.
                                        Sndisse conv allis.
                                    </p>
                                    <a href="/blogdetails" className="text-normal small">
                                        Read More <i className="bi bi-arrow-right" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                            <div className="row">
                                <div className="col-5">
                                    <figure className="rounded-15 position-relative h-190 overflow-hidden shadow-sm">
                                        <div className="coverimg h-100 w-100 position-absolute start-0 top-0">
                                            <img src="assets/img/news3.jpg" alt="" />
                                        </div>
                                    </figure>
                                </div>
                                <div className="col align-self-center">
                                    <a
                                        href="/blogdetails"
                                        className="h5 mb-3 d-block text-normal"
                                    >
                                        Maxartkiller has awesome templae to start
                                    </a>
                                    <p className="text-secondary small">
                                        Lorem ipsum dolor sit amet, consect etur adipiscing elit.
                                        Sndisse conv allis.
                                    </p>
                                    <a href="/blogdetails" className="text-normal small">
                                        Read More <i className="bi bi-arrow-right" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                            <div className="row">
                                <div className="col-5">
                                    <figure className="rounded-15 position-relative h-190 overflow-hidden shadow-sm">
                                        <div className="coverimg h-100 w-100 position-absolute start-0 top-0">
                                            <img src="assets/img/news1.jpg" alt="" />
                                        </div>
                                    </figure>
                                </div>
                                <div className="col align-self-center">
                                    <a
                                        href="/blogdetails"
                                        className="h5 mb-3 d-block text-normal"
                                    >
                                        For the people by people Best Mobile template is here
                                    </a>
                                    <p className="text-secondary small">
                                        Lorem ipsum dolor sit amet, consect etur adipiscing elit.
                                        Sndisse conv allis.
                                    </p>
                                    <a href="/blogdetails" className="text-normal small">
                                        Read More <i className="bi bi-arrow-right" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* main page content ends */}
            </main>
            {/* Page ends*/}
        </>

    )
}

export default Blog