import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css"; // Import Swiper styles
import "swiper/css";

function Withdraw() {

    const cardsData = [
        {
          id: 1,
          cardClass: "theme-bg shadow-sm shadow-purple mb-3",
          imgSrc: "assets/img/card-bg.png",
          cardLogo: "assets/img/maestro.png",
          bank: "City Bank",
          cardType: "Credit Card",
          cardNumber: "000 0000 0001 546598",
          expiry: "09/023",
          cardHolder: "Maxartkiller",
          expense: "1500.00",
          limitRemain: "13500.00",
          expenseChange: "18.2",
          expenseChangeType: "up",
        },
        {
          id: 2,
          cardClass: "bg-danger shadow-sm shadow-danger mb-3",
          // imgSrc: "assets/img/visa.png",
          cardLogo: "assets/img/visa.png",
          bank: "City Bank",
          cardType: "Credit Card",
          cardNumber: "000 0000 0001 546598",
          expiry: "09/023",
          cardHolder: "Maxartkiller",
          expense: "3500.00",
          limitRemain: "13500.00",
          expenseChange: "10.2",
          expenseChangeType: "down",
        },
        {
          id: 3,
          cardClass: "theme-radial-gradient border-0 mb-3",
          // imgSrc: "assets/img/maestro.png",
          cardLogo: "assets/img/maestro.png",
          bank: "City Bank",
          cardType: "Credit Card",
          cardNumber: "000 0000 0001 546598",
          expiry: "09/023",
          cardHolder: "Maxartkiller",
          expense: "1254.00",
          limitRemain: "13500.00",
          expenseChange: "18.2",
          expenseChangeType: "up",
        },
      ];


    return (
        <>
            {/* loader section */}
          
            {/* loader section ends */}
            {/* Begin page */}
            <main className="h-100">
                {/* Header */}
                <header className="header position-fixed">
                    <div className="row">
                        <div className="col-auto">
                            <button type="button" className="btn btn-light btn-44 back-btn">
                                <i className="bi bi-arrow-left" />
                            </button>
                        </div>
                        <div className="col text-center">
                                <div className="logo-small">
                                   <a href='/'> <img src="assets/img/logo.png" alt="" />
                                    <h5>
                                        <span className="text-secondary fw-light">Finance</span>
                                        <br />
                                        Wallet
                                    </h5>
                                    </a>
                                </div>
                            </div>
                        <div className="col-auto">
                            <a
                                href="/profile"
                                target="_self"
                                className="btn btn-light btn-44"
                            >
                                <i className="bi bi-person-circle" />
                                <span className="count-indicator" />
                            </a>
                        </div>
                    </div>
                </header>
                {/* Header ends */}
                {/* main page content */}
                <div className="main-container container">
                    {/* select Amount */}
                    <div className="row">
                        <div className="col-12 text-center mb-4">
                            <input
                                type="text"
                                className="trasparent-input text-center"
                                defaultValue={110.0}
                                placeholder="Enter Amount"
                            />
                            <div className="text-center">
                                <span className="text-muted">Amount in USD</span>
                                <button
                                    className="btn btn-link btn-sm text-muted px-1 dropdown-toggle"
                                    type="button"
                                    id="ln1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                ></button>
                                <ul
                                    className="dropdown-menu dropdown-menu-end shadow border-0 w-110"
                                    aria-labelledby="ln1"
                                >
                                    <li>
                                        <a className="dropdown-item active" href="#">
                                            USD
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            AUD
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            BTC
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            ETH
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Amount breakdown */}
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col">
                                    <p>Transfer Amount</p>
                                </div>
                                <div className="col-auto text-end">
                                    <p className="text-muted">110.00</p>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col">
                                    <p>Transaction Charge</p>
                                </div>
                                <div className="col-auto text-end">
                                    <p className="text-muted">8.00</p>
                                </div>
                            </div>
                            <div className="row fw-medium">
                                <div className="col-12">
                                    <div className="dashed-line mb-3" />
                                </div>
                                <div className="col">
                                    <p>Total Deduction</p>
                                </div>
                                <div className="col-auto text-end">
                                    <p className="text-muted">118.00</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Saving targets */}
                    <div className="row mb-2">
                        <div className="col">
                            <h6 className="title">Amount withdraw to</h6>
                        </div>
                        <div className="col-auto">
                            <a href="" className="small">
                                Add New
                            </a>
                        </div>
                    </div>
                    {/* swiper credit cards */}
                    <div className="row mb-3">
                        <div className="col-12 px-0">
                            <Swiper
                                spaceBetween={30} // Adjust space between cards
                                slidesPerView='auto' // Number of visible slides at once
                                className="cardswiper"

                            >
                                {cardsData.map((card) => (
                                    <SwiperSlide key={card.id}>
                                        <div className={`card ${card.cardClass}`} style={{ height: "180px", width: "300px" }}>
                                            {card.imgSrc && (
                                                <img src={card.imgSrc} alt="" className="cardimg" />
                                            )}
                                            <div className="card-body">
                                                <div className="row mb-4">
                                                    <div className="col-auto align-self-center">
                                                        <img src={card.cardLogo} alt="" />
                                                    </div>
                                                    <div className="col align-self-center text-end">
                                                        <p className="small">
                                                            <span className="text-muted size-10">{card.bank}</span>
                                                            <br />
                                                            <span className="">{card.cardType}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <h6 className="fw-normal mb-3">{card.cardNumber}</h6>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <p className="mb-0 text-muted size-10">Expiry</p>
                                                        <p>{card.expiry}</p>
                                                    </div>
                                                    <div className="col text-end">
                                                        <p className="mb-0 text-muted size-10">Card Holder</p>
                                                        <p>{card.cardHolder}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-auto">
                                                <p className="mb-0 text-secondary size-10">Expense</p>
                                                <p>
                                                    {card.expense}
                                                    <small
                                                        className={`text-${card.expenseChangeType === "up" ? "success" : "danger"
                                                            }`}
                                                    >
                                                        {card.expenseChange}
                                                        <i
                                                            className={`bi bi-arrow-${card.expenseChangeType === "up" ? "up" : "down"
                                                                }`}
                                                        />
                                                    </small>
                                                </p>
                                            </div>
                                            <div className="col text-end">
                                                <p className="mb-0 text-secondary size-10">Limit Remain</p>
                                                <p>{card.limitRemain}</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12 ">
                            <a
                                href="/thankyou"
                                className="btn btn-default btn-lg shadow-sm w-100"
                            >
                                Add to Wallet
                            </a>
                        </div>
                    </div>
                </div>
                {/* main page content ends */}
            </main>
            {/* Page ends*/}
        </>

    )
}

export default Withdraw