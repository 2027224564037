import logo from './logo.svg';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Statistics from './Pages/Statistics';
import Shop from './Pages/Shop';
import Wallet from './Pages/Wallet';
import Pay from './Pages/Pay';
import Sendmoney from './Pages/Sendmoney';
import Thankyou from './Pages/Thankyou';
import Withdraw from './Pages/Withdraw';
import Userlist from './Pages/Userlist';
import Bills from './Pages/Bills';
import Profile from './Pages/Profile';
import Message from './Pages/Message';
import Blogdetails from './Pages/Blogdetails';
import Blog from './Pages/Blog';
import Chat from './Pages/Chat';
import Notifications from './Pages/Notifications';
import Products from './Pages/Products';
import Product from './Pages/Product';
import Addmoney from './Pages/Addmoney';
import Addcurrency from './Pages/Addcurrency';
import Rewards from './Pages/Rewards';
import Receivemoney from './Pages/Receivemoney';
import Signup from './Pages/Signup';
import Signin from './Pages/Signin';
import ForgotPassword from './Pages/ForgotPassword';
import ResetPassword from './Pages/ResetPassword';
import PayBill from './Pages/PayBill';
import Verify from './Pages/Verify';
function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/signup" element={<Signup/>} />
      <Route path='/signin' element={<Signin/>}/>
      <Route path='/statistics' element={<Statistics/>}/>
      <Route path='/shop' element={<Shop/>}/>
      <Route path='/wallet' element={<Wallet/>}/>
      <Route path='/forgot-password' element={<ForgotPassword/>}/>
      <Route path='/reset-password' element={<ResetPassword/>}/>


      <Route path='/pay' element={<Pay/>}/>
      <Route path='/paybill' element={<PayBill/>}/>
      <Route path='/verify' element={<Verify/>}/>
      <Route path='/sendmoney' element={<Sendmoney/>}/>
      <Route path='/thankyou' element={<Thankyou/>}/>
      <Route path='/withdraw' element={<Withdraw/>}/>
      <Route path='/userlist' element={<Userlist/>}/>
      <Route path='/bills' element={<Bills/>}/>
      <Route path='/profile' element={<Profile/>}/>
      <Route path='/message' element={<Message/>}/>
      <Route path='/blogdetails' element={<Blogdetails/>}/>
      <Route path='/blog' element={<Blog/>}/>
      <Route path='/chat' element={<Chat/>}/>
      <Route path='/notifications' element={<Notifications/>}/>
      <Route path='/products' element={<Products/>}/>
      <Route path='/product' element={<Product/>}/>
      <Route path='/addmoney' element={<Addmoney/>}/>
      <Route path='/addcurrency' element={<Addcurrency/>}/>
      <Route path='/rewards' element={<Rewards/>}/>
      <Route path='/receivemoney' element={<Receivemoney/>}/>
    </Routes>
    </BrowserRouter>
  );
}

export default App;
