import React from 'react'

function Message() {
    return (
        <>
            {/* loader section */}

            {/* loader section ends */}
            {/* Sidebar main menu */}
            <div className="sidebar-wrap  sidebar-overlay">
                {/* Add pushcontent or fullmenu instead overlay */}
                <div className="closemenu text-muted">Close Menu</div>
                <div className="sidebar ">
                    {/* user information */}
                    <div className="row my-3">
                        <div className="col-12 profile-sidebar">
                            <div className="clearfix" />
                            <div className="circle small one" />
                            <div className="circle small two" />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 194.287 141.794"
                                className="menubg"
                            >
                                <defs>
                                    <linearGradient
                                        id="linear-gradient"
                                        x1="0.5"
                                        x2="0.5"
                                        y2={1}
                                        gradientUnits="objectBoundingBox"
                                    >
                                        <stop offset={0} stopColor="#09b2fd" />
                                        <stop offset={1} stopColor="#6b00e5" />
                                    </linearGradient>
                                </defs>
                                <path
                                    id="menubg"
                                    d="M672.935,207.064c-19.639,1.079-25.462-3.121-41.258,10.881s-24.433,41.037-49.5,34.15-14.406-16.743-50.307-29.667-32.464-19.812-16.308-41.711S500.472,130.777,531.872,117s63.631,21.369,93.913,15.363,37.084-25.959,56.686-19.794,4.27,32.859,6.213,44.729,9.5,16.186,9.5,26.113S692.573,205.985,672.935,207.064Z"
                                    transform="translate(-503.892 -111.404)"
                                    fill="url(#linear-gradient)"
                                />
                            </svg>
                            <div className="row mt-3">
                                <div className="col-auto">
                                    <figure className="avatar avatar-80 rounded-20 p-1 bg-white shadow-sm">
                                        <img src="assets/img/user1.jpg" alt="" className="rounded-18" />
                                    </figure>
                                </div>
                                <div className="col px-0 align-self-center">
                                    <h5 className="mb-2">John Winsels</h5>
                                    <p className="text-muted size-12">
                                        New York City,
                                        <br />
                                        United States
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* user emnu navigation */}
                    <div className="row">
                        <div className="col-12">
                            <ul className="nav nav-pills">
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        aria-current="page"
                                        href="\"
                                    >
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-house-door" />
                                        </div>
                                        <div className="col">Dashboard</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        href="#"
                                        role="button"
                                        aria-expanded="false"
                                    >
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-cart" />
                                        </div>
                                        <div className="col">Shop</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-down plus" />{" "}
                                            <i className="bi bi-chevron-up minus" />
                                        </div>
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <a className="dropdown-item nav-link" href="/shop">
                                                <div className="avatar avatar-40  icon">
                                                    <i className="bi bi-shop" />
                                                </div>
                                                <div className="col align-self-center">Shop home</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/products">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-box-seam" />
                                                </div>
                                                <div className="col align-self-center">All Products</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/product">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-box-seam" />
                                                </div>
                                                <div className="col align-self-center">Product</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/cart">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-bag" />
                                                </div>
                                                <div className="col align-self-center">Cart</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/myorders">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-view-list" />
                                                </div>
                                                <div className="col align-self-center">My Orders</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/payment">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-cash-stack" />
                                                </div>
                                                <div className="col align-self-center">Payment</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/invoice">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-receipt" />
                                                </div>
                                                <div className="col align-self-center">Invoice</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/chat" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-chat-text" />
                                        </div>
                                        <div className="col">Messages</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/notifications" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-bell" />
                                        </div>
                                        <div className="col">Notification</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/blog" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-newspaper" />
                                        </div>
                                        <div className="col">Blogs</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/style" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-palette" />
                                        </div>
                                        <div className="col">
                                            Style <i className="bi bi-star-fill text-warning small" />
                                        </div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/pages" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-file-earmark-text" />
                                        </div>
                                        <div className="col">
                                            Pages <span className="badge bg-info fw-light">new</span>
                                        </div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/signin" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-box-arrow-right" />
                                        </div>
                                        <div className="col">Logout</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Sidebar main menu ends */}
            {/* Begin page */}
            <main className="h-100">
                {/* Header */}
                <header className="header position-fixed">
                    <div className="row">
                        <div className="col-auto">
                            <a
                                href="javascript:void(0)"
                                target="_self"
                                className="btn btn-light btn-44 back-btn"
                            >
                                <i className="bi bi-arrow-left" />
                            </a>
                        </div>
                        <div className="col text-center">
                                <div className="logo-small">
                                   <a href='/'> <img src="assets/img/logo.png" alt="" />
                                    <h5>
                                        <span className="text-secondary fw-light">Finance</span>
                                        <br />
                                        Wallet
                                    </h5>
                                    </a>
                                </div>
                            </div>
                        <div className="col-auto">
                            <a
                                href="/profile"
                                target="_self"
                                className="btn btn-light btn-44"
                            >
                                <i className="bi bi-person-circle" />
                                <span className="count-indicator" />
                            </a>
                        </div>
                    </div>
                </header>
                {/* Header ends */}
                {/* main page content */}
                <div className="main-container container">
                    {/* Chat list   */}
                    <div className="row mb-5">
                        <div className="col-12 chat-list scroll-y mb-3">
                            <div className="row no-margin left-chat">
                                <div className="col-12">
                                    <div className="chat-block">
                                        <div className="row">
                                            <div className="col">Hi!</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <p className="text-secondary small time">
                                        <i className="bi bi-check" /> 9:00 pm
                                    </p>
                                </div>
                            </div>
                            <div className="row no-margin right-chat">
                                <div className="col-12">
                                    <div className="chat-block">
                                        <div className="row">
                                            <div className="col">
                                                Hi!
                                                <br />
                                                Yes please tell us your query.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 text-end">
                                    <p className="text-secondary small time">
                                        <i className="bi bi-check" /> 9:10 pm
                                    </p>
                                </div>
                            </div>
                            <div className="row no-margin">
                                <div className="col-12 text-center">
                                    <span className="alert-primary text-secondary mx-auto btn btn-sm py-1 mb-3">
                                        26 November 2021
                                    </span>
                                </div>
                            </div>
                            <div className="row no-margin left-chat">
                                <div className="col-12">
                                    <div className="chat-block">
                                        <div className="row">
                                            <div className="col">
                                                How are you? Mobileux we were wait since long to be here.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <p className="text-secondary small time">
                                        <i className="bi bi-check-all text-primary" /> 8:00 pm
                                    </p>
                                </div>
                            </div>
                            <div className="row no-margin right-chat">
                                <div className="col-12">
                                    <div className="chat-block">
                                        <div className="row">
                                            <div className="col">
                                                <div className="mw-100 position-relative mb-2 figure">
                                                    <div className="position-absolute end-0 top-0">
                                                        <button className="avatar avatar-36 rounded-circle p-0 btn btn-info text-white shadow-sm m-2">
                                                            <i className="bi bi-download" />
                                                        </button>
                                                    </div>
                                                    <img
                                                        src="assets/img/news1.jpg"
                                                        alt=""
                                                        className="mw-100"
                                                    />
                                                </div>
                                                How are you? Mobileux we were waited since long to be here.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 text-end">
                                    <p className="text-secondary small time">
                                        <i className="bi bi-check-all text-primary" /> 8:00 pm
                                    </p>
                                </div>
                            </div>
                            <div className="row no-margin">
                                <div className="col-12  text-center">
                                    <span className="alert-primary text-secondary mx-auto btn btn-sm py-1 mb-3">
                                        25 November 2019
                                    </span>
                                </div>
                            </div>
                            <div className="row no-margin left-chat">
                                <div className="col-12">
                                    <div className="chat-block">
                                        <div className="row">
                                            <div className="col">
                                                <div className="mw-100 position-relative mb-2 figure">
                                                    <div className="position-absolute start-50 top-50 translate-middle z-index-1">
                                                        <button className="avatar avatar-36 rounded-circle p-0 btn btn-danger text-white shadow-sm m-2 mx-0 videoplaybtn">
                                                            <i className="bi bi-play" />
                                                        </button>
                                                    </div>
                                                    <video
                                                        src="https://maxartkiller.com/website/maxartkiller.mp4"
                                                        controls=""
                                                    />
                                                </div>
                                                How are you? Mobileux we were waited since long to be here.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <p className="text-secondary small time">
                                            <i className="bi bi-check-all text-primary" /> 8:00 pm
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row no-margin right-chat">
                                <div className="col-12">
                                    <div className="chat-block">
                                        <div className="row">
                                            <div className="col">
                                                <p>
                                                    Mobileux is HTML template can be used in various business
                                                    domains like Manufacturing, inventory, IT, administration
                                                    etc. for mobile application for news and broadcasting{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 text-end">
                                    <p className="text-secondary small time">
                                        <i className="bi bi-check-all text-primary" /> 8:00 pm
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* main page content ends */}
                {/* chat post message */}
                <div className="position-fixed bottom-0 start-0 chat-post">
                    <div className="row gx-3">
                        <div className="col-auto">
                            <button
                                className="btn btn-outline-primary btn-44 avatar p-0"
                                type="button"
                                data-bs-toggle="modal"
                                data-bs-target="#attachefiles"
                            >
                                <i className="bi bi-plus" />
                            </button>
                        </div>
                        <div className="col">
                            <div className="input-group">
                                <input
                                    type="text"
                                    className="form-control "
                                    placeholder="Write your message"
                                />
                                <button className="btn btn-44 btn-outline-primary" type="button">
                                    <i className="bi bi-cursor" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* chat post message ends*/}
                {/* modal attachement */}
                <div
                    className="modal fade "
                    id="attachefiles"
                    tabIndex={-1}
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-sm modal-dialog-ends ">
                        <div className="modal-content shadow border-0">
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12 mx-auto">
                                        <div className="row justify-content-center align-items-center mb-3 gx-3">
                                            <div className="col-auto">
                                                <button
                                                    className="btn btn-outline-secondary avatar-50 avatar p-0 rounded-circle"
                                                    data-bs-dismiss="modal"
                                                >
                                                    <i className="bi bi-file-earmark-image fs-5" />
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                                <button
                                                    className="btn btn-outline-secondary avatar-50 avatar p-0 rounded-circle"
                                                    data-bs-dismiss="modal"
                                                >
                                                    <i className="bi bi-file-earmark-richtext fs-5" />
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                                <button
                                                    className="btn btn-outline-secondary avatar-50 avatar p-0 rounded-circle"
                                                    data-bs-dismiss="modal"
                                                >
                                                    <i className="bi bi-file-earmark-zip fs-5" />
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                                <button
                                                    className="btn btn-outline-secondary avatar-50 avatar p-0 rounded-circle"
                                                    data-bs-dismiss="modal"
                                                >
                                                    <i className="bi bi-file-earmark-music fs-5" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="row justify-content-center align-items-center gx-3">
                                            <div className="col-auto">
                                                <button
                                                    className="btn btn-outline-secondary avatar-50 avatar p-0 rounded-circle"
                                                    data-bs-dismiss="modal"
                                                >
                                                    <i className="bi bi-file-earmark-pdf fs-5" />
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                                <button
                                                    className="btn btn-outline-secondary avatar-50 avatar p-0 rounded-circle"
                                                    data-bs-dismiss="modal"
                                                >
                                                    <i className="bi bi-file-earmark-person fs-5" />
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                                <button
                                                    className="btn btn-outline-secondary avatar-50 avatar p-0 rounded-circle"
                                                    data-bs-dismiss="modal"
                                                >
                                                    <i className="bi bi-camera fs-5" />
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                                <button
                                                    className="btn btn-outline-secondary avatar-50 avatar p-0 rounded-circle"
                                                    data-bs-dismiss="modal"
                                                >
                                                    <i className="bi bi-camera-video fs-5" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* modal attachement ends */}
            </main>
            {/* Page ends*/}
        </>

    )
}

export default Message