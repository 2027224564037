import React from 'react'

function Sendmoney() {
    return (
        <>
            {/* loader section */}
          
            {/* loader section ends */}
            {/* Begin page */}
            <main className="h-100">
                {/* Header */}
                <header className="header position-fixed">
                    <div className="row">
                        <div className="col-auto">
                            <button type="button" className="btn btn-light btn-44 back-btn">
                                <i className="bi bi-arrow-left" />
                            </button>
                        </div>
                        <div className="col text-center">
                                <div className="logo-small">
                                   <a href='/'> <img src="assets/img/logo.png" alt="" />
                                    <h5>
                                        <span className="text-secondary fw-light">Finance</span>
                                        <br />
                                        Wallet
                                    </h5>
                                    </a>
                                </div>
                            </div>
                        <div className="col-auto">
                            <a
                                href="/profile"
                                target="_self"
                                className="btn btn-light btn-44"
                            >
                                <i className="bi bi-person-circle" />
                                <span className="count-indicator" />
                            </a>
                        </div>
                    </div>
                </header>
                {/* Header ends */}
                {/* main page content */}
                <div className="main-container container">
                    {/* select contacts */}
                    <div className="row mb-3">
                        <div className="col-auto">
                            <div className="card">
                                <div className="card-body p-1">
                                    <div className="avatar avatar-44 rounded-15 shadow-sm">
                                        <img src="assets/img/company3.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col align-self-center ps-0">
                            <p className="mb-1 text-color-theme">Walmart</p>
                            <p className="text-muted size-12">Invoice ID: IDE0515416385</p>
                        </div>
                        <div className="col-auto">
                            <a href="/bills" className="btn btn-default btn-44 shadow-sm">
                                <i className="bi bi-receipt" />
                            </a>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-auto">
                            <div className="avatar-group">
                                <div className="avatar avatar-50 rounded-20 shadow-sm bg-white p-1">
                                    <img src="assets/img/user2.jpg" alt="" className="rounded-15" />
                                </div>
                                <div className="avatar avatar-50 rounded-20 shadow-sm bg-white p-1">
                                    <img src="assets/img/user3.jpg" alt="" className="rounded-15" />
                                </div>
                                <div className="avatar avatar-50 rounded-20 shadow-sm bg-white p-1">
                                    <img src="assets/img/user4.jpg" alt="" className="rounded-15" />
                                </div>
                                <div className="avatar avatar-50 rounded-20 shadow-sm bg-white p-1">
                                    <img src="assets/img/user1.jpg" alt="" className="rounded-15" />
                                </div>
                            </div>
                        </div>
                        <div className="col align-self-center ps-0">
                            <p className="text-muted small">
                                +15
                                <br />
                                More
                            </p>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-default btn-44 shadow-sm">
                                <i className="bi bi-person-badge" />
                            </button>
                        </div>
                    </div>
                    {/* select Amount */}
                    <div className="row">
                        <div className="col-12 text-center mb-4">
                            <input
                                type="text"
                                className="trasparent-input text-center"
                                defaultValue={100.0}
                                placeholder="Enter Amount"
                            />
                            <div className="text-center">
                                <span className="text-muted">Amount in USD</span>
                                <button
                                    className="btn btn-link btn-sm text-muted px-1 dropdown-toggle"
                                    type="button"
                                    id="ln1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                ></button>
                                <ul
                                    className="dropdown-menu dropdown-menu-end shadow border-0 w-110"
                                    aria-labelledby="ln1"
                                >
                                    <li>
                                        <a className="dropdown-item active" href="#">
                                            USD
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            AUD
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            BTC
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            ETH
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* coupon code*/}
                    <div className="row">
                        <div className="col-12 mb-4">
                            <div className="form-group form-floating is-valid">
                                <input
                                    type="text"
                                    className="form-control "
                                    id="coupon"
                                    placeholder="Coupon Code"
                                    defaultValue="CASHBACK05NEW"
                                />
                                <label className="form-control-label" htmlFor="coupon">
                                    Coupon Code
                                </label>
                                <div className=" tooltip-btn">
                                    <span className="tag bg-success text-white border-0 px-2 float-end mt-1">
                                        Applied
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Amount breakdown */}
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col">
                                    <p>Transfer Amount</p>
                                </div>
                                <div className="col-auto text-end">
                                    <p className="text-muted">110.00</p>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col">
                                    <p>Transaction Charge (1%)</p>
                                </div>
                                <div className="col-auto text-end">
                                    <p className="text-muted">8.00</p>
                                </div>
                            </div>
                            <div className="row fw-medium">
                                <div className="col-12">
                                    <div className="dashed-line mb-3" />
                                </div>
                                <div className="col">
                                    <p>Total Deduction</p>
                                </div>
                                <div className="col-auto text-end">
                                    <p className="text-muted">118.00</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Saving targets */}
                    <div className="row mb-2">
                        <div className="col">
                            <h6 className="title">Amount pay from</h6>
                        </div>
                        <div className="col-auto">
                            <a href="" className="small">
                                Add New
                            </a>
                        </div>
                    </div>
                    {/* swiper credit cards */}
                    <div className="row mb-2">
                        <div className="col-12 px-0">
                            <div className="swiper-container cardswiper">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <div className="card theme-bg shadow-sm shadow-purple mb-3">
                                            <img
                                                src="assets/img/card-bg.png"
                                                alt=""
                                                className="cardimg"
                                            />
                                            <div className="card-body">
                                                <div className="row mb-4">
                                                    <div className="col-auto align-self-center">
                                                        <img src="assets/img/maestro.png" alt="" />
                                                    </div>
                                                    <div className="col align-self-center text-end">
                                                        <p className="small">
                                                            <span className="text-muted size-10">City Bank</span>
                                                            <br />
                                                            <span className="">Credit Card</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <h6 className="fw-normal mb-3">000 0000 0001 546598</h6>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <p className="mb-0 text-muted size-10">Expiry</p>
                                                        <p>09/023</p>
                                                    </div>
                                                    <div className="col text-end">
                                                        <p className="mb-0 text-muted size-10">Card Holder</p>
                                                        <p>Maxartkiller</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="card bg-danger shadow-sm shadow-danger mb-3">
                                            <div className="card-body">
                                                <div className="row mb-4">
                                                    <div className="col-auto align-self-center">
                                                        <img src="assets/img/visa.png" alt="" />
                                                    </div>
                                                    <div className="col align-self-center text-end">
                                                        <p className="small">
                                                            <span className="text-muted size-10">City Bank</span>
                                                            <br />
                                                            <span className="">Credit Card</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <h6 className="fw-normal mb-3">000 0000 0001 546598</h6>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <p className="mb-0 text-muted size-10">Expiry</p>
                                                        <p>09/023</p>
                                                    </div>
                                                    <div className="col text-end">
                                                        <p className="mb-0 text-muted size-10">Card Holder</p>
                                                        <p>Maxartkiller</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="card theme-radial-gradient border-0 mb-3">
                                            <div className="card-body">
                                                <div className="row mb-4">
                                                    <div className="col-auto align-self-center">
                                                        <img src="assets/img/maestro.png" alt="" />
                                                    </div>
                                                    <div className="col align-self-center text-end">
                                                        <p className="small">
                                                            <span className="text-muted size-10">City Bank</span>
                                                            <br />
                                                            <span className="">Credit Card</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <h6 className="fw-normal mb-3">000 0000 0001 546598</h6>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <p className="mb-0 text-muted size-10">Expiry</p>
                                                        <p>09/023</p>
                                                    </div>
                                                    <div className="col text-end">
                                                        <p className="mb-0 text-muted size-10">Card Holder</p>
                                                        <p>Maxartkiller</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12 ">
                            <a
                                href="/thankyou"
                                className="btn btn-default btn-lg shadow-sm w-100"
                            >
                                Add to Wallet
                            </a>
                        </div>
                    </div>
                </div>
                {/* main page content ends */}
            </main>
            {/* Page ends*/}

        </>

    )
}

export default Sendmoney