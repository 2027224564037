import React from 'react'

function Profile() {
    return (
        <>
            {/* loader section */}
          
            {/* loader section ends */}
            {/* Sidebar main menu */}
            <div className="sidebar-wrap  sidebar-overlay">
                {/* Add pushcontent or fullmenu instead overlay */}
                <div className="closemenu text-muted">Close Menu</div>
                <div className="sidebar ">
                    {/* user information */}
                    <div className="row my-3">
                        <div className="col-12 profile-sidebar">
                            <div className="clearfix" />
                            <div className="circle small one" />
                            <div className="circle small two" />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 194.287 141.794"
                                className="menubg"
                            >
                                <defs>
                                    <linearGradient
                                        id="linear-gradient1"
                                        x1="0.5"
                                        x2="0.5"
                                        y2={1}
                                        gradientUnits="objectBoundingBox"
                                    >
                                        <stop offset={0} stopColor="#09b2fd" />
                                        <stop offset={1} stopColor="#6b00e5" />
                                    </linearGradient>
                                </defs>
                                <path
                                    id="menubg"
                                    d="M672.935,207.064c-19.639,1.079-25.462-3.121-41.258,10.881s-24.433,41.037-49.5,34.15-14.406-16.743-50.307-29.667-32.464-19.812-16.308-41.711S500.472,130.777,531.872,117s63.631,21.369,93.913,15.363,37.084-25.959,56.686-19.794,4.27,32.859,6.213,44.729,9.5,16.186,9.5,26.113S692.573,205.985,672.935,207.064Z"
                                    transform="translate(-503.892 -111.404)"
                                    fill="url(#linear-gradient1)"
                                />
                            </svg>
                            <div className="row mt-3">
                                <div className="col-auto">
                                    <figure className="avatar avatar-80 rounded-20 p-1 bg-white shadow-sm">
                                        <img src="assets/img/user1.jpg" alt="" className="rounded-18" />
                                    </figure>
                                </div>
                                <div className="col px-0 align-self-center">
                                    <h5 className="mb-2">John Winsels</h5>
                                    <p className="text-muted size-12">
                                        New York City,
                                        <br />
                                        United States
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* user emnu navigation */}
                    <div className="row">
                        <div className="col-12">
                            <ul className="nav nav-pills">
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        aria-current="page"
                                        href="/"
                                    >
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-house-door" />
                                        </div>
                                        <div className="col">Dashboard</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        href="#"
                                        role="button"
                                        aria-expanded="false"
                                    >
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-cart" />
                                        </div>
                                        <div className="col">Shop</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-down plus" />{" "}
                                            <i className="bi bi-chevron-up minus" />
                                        </div>
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <a className="dropdown-item nav-link" href="/shop">
                                                <div className="avatar avatar-40  icon">
                                                    <i className="bi bi-shop" />
                                                </div>
                                                <div className="col align-self-center">Shop home</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/products">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-box-seam" />
                                                </div>
                                                <div className="col align-self-center">All Products</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/product">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-box-seam" />
                                                </div>
                                                <div className="col align-self-center">Product</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/cart">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-bag" />
                                                </div>
                                                <div className="col align-self-center">Cart</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/myorders">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-view-list" />
                                                </div>
                                                <div className="col align-self-center">My Orders</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/payment">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-cash-stack" />
                                                </div>
                                                <div className="col align-self-center">Payment</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/invoice">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-receipt" />
                                                </div>
                                                <div className="col align-self-center">Invoice</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/chat" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-chat-text" />
                                        </div>
                                        <div className="col">Messages</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/notifications" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-bell" />
                                        </div>
                                        <div className="col">Notification</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/blog" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-newspaper" />
                                        </div>
                                        <div className="col">Blogs</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/style" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-palette" />
                                        </div>
                                        <div className="col">
                                            Style <i className="bi bi-star-fill text-warning small" />
                                        </div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/pages" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-file-earmark-text" />
                                        </div>
                                        <div className="col">
                                            Pages <span className="badge bg-info fw-light">new</span>
                                        </div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/signin" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-box-arrow-right" />
                                        </div>
                                        <div className="col">Logout</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Sidebar main menu ends */}
            {/* Begin page */}
            <main className="h-100">
                {/* Header */}
                <header className="header position-fixed">
                    <div className="row">
                        <div className="col-auto">
                            <a
                                href="javascript:void(0)"
                                target="_self"
                                className="btn btn-light btn-44 menu-btn"
                            >
                                <i className="bi bi-list" />
                            </a>
                        </div>
                        <div className="col text-center">
                                <div className="logo-small">
                                   <a href='/'> <img src="assets/img/logo.png" alt="" />
                                    <h5>
                                        <span className="text-secondary fw-light">Finance</span>
                                        <br />
                                        Wallet
                                    </h5>
                                    </a>
                                </div>
                            </div>
                        <div className="col-auto">
                            <a
                                href="/profile"
                                target="_self"
                                className="btn btn-light btn-44"
                            >
                                <i className="bi bi-person-circle" />
                                <span className="count-indicator" />
                            </a>
                        </div>
                    </div>
                </header>
                {/* Header ends */}
                {/* main page content */}
                <div className="main-container container">
                    <div className="row">
                        <div className="col-12 profile-page">
                            <div className="clearfix" />
                            <div className="circle small one" />
                            <div className="circle small two" />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="282.062"
                                height="209.359"
                                viewBox="0 0 282.062 209.359"
                                className="menubg"
                            >
                                <defs>
                                    <linearGradient
                                        id="linear-gradient"
                                        x1="0.5"
                                        x2="0.5"
                                        y2={1}
                                        gradientUnits="objectBoundingBox"
                                    >
                                        <stop offset={0} stopColor="#09b2fd" />
                                        <stop offset={1} stopColor="#6b00e5" />
                                    </linearGradient>
                                </defs>
                                <path
                                    id="profilebg"
                                    d="M751.177,233.459c-28.511,1.567-38.838,7.246-61.77,27.573s-27.623,71.926-65.15,70.883-27.624-21.369-79.744-40.132-47.13-53.005-23.676-84.8,4.009-57.671,33-75.867,83.269,30.223,127.232,21.5,64.157-41.353,82.329-26,5.953,29.138,8.773,46.369,13.786,23.5,13.786,37.91S779.688,231.893,751.177,233.459Z"
                                    transform="translate(-503.892 -122.573)"
                                    fill="url(#linear-gradient)"
                                />
                            </svg>
                            <div className="row my-3 py-4">
                                <div className="col-7 align-self-center">
                                    <h1 className="mb-2">
                                        <span className="fw-light text-secondary">Hello!</span>
                                        <br />
                                        John
                                    </h1>
                                    <p className="text-muted size-12">
                                        New York City,
                                        <br />
                                        United States
                                    </p>
                                </div>
                                <div className="col align-self-center">
                                    <figure className="avatar avatar-100 rounded-20 p-1 bg-white shadow-sm">
                                        <img src="assets/img/user1.jpg" alt="" className="rounded-18" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* buttons */}
                    <div className="row mb-4">
                        <div className="col">
                            <a href="" className="btn btn-light btn-lg shadow-sm w-100">
                                Invite
                            </a>
                        </div>
                        <div className="col">
                            <a
                                href="/message"
                                className="btn btn-default btn-lg shadow-sm w-100"
                            >
                                Message
                            </a>
                        </div>
                    </div>
                    {/* location map*/}
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="card shadow-sm">
                                <div className="card-body">
                                    <figure className="w-100">
                                        <img src="assets/img/map@2x.png" className="mw-100" alt="" />
                                    </figure>
                                    <div className="row">
                                        <div className="col">
                                            <p className="mb-1">Jon Winsels</p>
                                            <p className="text-secondary small">
                                                3023 New Cross Rd, New Cross, London SE14 6AS, New York
                                                City, US
                                            </p>
                                        </div>
                                        <div className="col-auto align-self-center">
                                            <button className="btn btn-link text-color-theme btn-44">
                                                <i className="bi bi-arrow-up-right-circle fs-2" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* My Frequent Payments */}
                    <div className="row mb-3">
                        <div className="col">
                            <h6 className="title">My Frequent Payments</h6>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-12 col-md-6">
                            <div className="card overflow-hidden mb-3">
                                <figure className="m-0 p-0 position-absolute top-0 end-0 w-50 h-100 coverimg">
                                    <img src="assets/img/image2.jpg" alt="" />
                                </figure>
                                <div className="card-body p-0">
                                    <div className="row mx-0">
                                        <div className="col-8 py-3 aftercircle">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <a href="/profile" className="card text-center">
                                                        <div className="card-body p-1">
                                                            <div className="position-absolute end-0 top-0 bg-success z-index-1 online-status"></div>
                                                            <figure className="avatar avatar-70 shadow-sm rounded-18">
                                                                <img src="assets/img/user4.jpg" alt="" />
                                                            </figure>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="col align-self-center px-0">
                                                    <p className="text-secondary size-10 mb-0">Send Money</p>
                                                    <p className="mb-2">John Winsels</p>
                                                    <p>
                                                        150.00
                                                        <small className="text-success">
                                                            18.2
                                                            <i className="bi bi-arrow-up" />
                                                        </small>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="card overflow-hidden mb-3">
                                <figure className="m-0 p-0 position-absolute top-0 end-0 w-50 h-100 coverimg">
                                    <img src="assets/img/image3.jpg" alt="" />
                                </figure>
                                <div className="card-body p-0">
                                    <div className="row mx-0">
                                        <div className="col-8 py-3 aftercircle">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <div className="card text-center">
                                                        <div className="card-body p-1">
                                                            <div className="position-absolute end-0 top-0 bg-success z-index-1 online-status"></div>
                                                            <figure className="avatar avatar-70 shadow-sm rounded-18">
                                                                <img src="assets/img/company5.png" alt="" />
                                                            </figure>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col align-self-center px-0">
                                                    <p className="text-secondary size-10 mb-0">
                                                        Transportation
                                                    </p>
                                                    <p className="mb-2">ID0582Max</p>
                                                    <p>
                                                        320.00
                                                        <small className="text-success">
                                                            1.65
                                                            <i className="bi bi-arrow-up" />
                                                        </small>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* People */}
                    <div className="row mb-2">
                        <div className="col">
                            <h6 className="title">People</h6>
                        </div>
                        <div className="col-auto">
                            <a href="/userlist" className="small">
                                View More
                            </a>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12 px-0">
                            {/* swiper users connections */}
                            <div className="swiper-container connectionwiper">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <a href="/profile" className="card text-center">
                                            <div className="card-body p-1">
                                                <div className="position-absolute end-0 top-0 bg-success z-index-1 online-status"></div>
                                                <figure className="avatar avatar-80 shadow-sm rounded-18">
                                                    <img src="assets/img/user4.jpg" alt="" />
                                                </figure>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="swiper-slide">
                                        <a href="/profile" className="card text-center">
                                            <div className="card-body p-1">
                                                <div className="position-absolute end-0 top-0 bg-danger z-index-1 online-status"></div>
                                                <figure className="avatar avatar-80 shadow-sm rounded-20">
                                                    <img src="assets/img/user2.jpg" alt="" />
                                                </figure>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="swiper-slide">
                                        <a href="/profile" className="card text-center">
                                            <div className="card-body p-1">
                                                <div className="position-absolute end-0 top-0 bg-warning z-index-1 online-status"></div>
                                                <figure className="avatar avatar-80 shadow-sm rounded-20">
                                                    <img src="assets/img/user3.jpg" alt="" />
                                                </figure>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="swiper-slide">
                                        <a href="/profile" className="card text-center">
                                            <div className="card-body p-1">
                                                <div className="position-absolute end-0 top-0 bg-success z-index-1 online-status"></div>
                                                <figure className="avatar avatar-80 shadow-sm rounded-20">
                                                    <img src="assets/img/user1.jpg" alt="" />
                                                </figure>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="swiper-slide">
                                        <a href="/profile" className="card text-center">
                                            <div className="card-body p-1">
                                                <div className="position-absolute end-0 top-0 bg-success z-index-1 online-status"></div>
                                                <figure className="avatar avatar-80 shadow-sm rounded-20">
                                                    <img src="assets/img/user4.jpg" alt="" />
                                                </figure>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="swiper-slide">
                                        <a href="/profile" className="card text-center">
                                            <div className="card-body p-1">
                                                <figure className="avatar avatar-80 shadow-sm rounded-20">
                                                    <img src="assets/img/user2.jpg" alt="" />
                                                </figure>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="swiper-slide">
                                        <a href="/profile" className="card text-center">
                                            <div className="card-body p-1">
                                                <div className="position-absolute end-0 top-0 bg-success z-index-1 online-status"></div>
                                                <figure className="avatar avatar-80 shadow-sm rounded-20">
                                                    <img src="assets/img/user3.jpg" alt="" />
                                                </figure>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="swiper-slide">
                                        <a href="/profile" className="card text-center">
                                            <div className="card-body p-1">
                                                <figure className="avatar avatar-80 shadow-sm rounded-20">
                                                    <img src="assets/img/user1.jpg" alt="" />
                                                </figure>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* main page content ends */}
            </main>
            {/* Page ends*/}
            {/* Footer */}
            <footer className="footer">
                <div className="container">
                    <ul className="nav nav-pills nav-justified">
                        <li className="nav-item">
                            <a className="nav-link" href="/">
                                <span>
                                    <i className="nav-icon bi bi-house" />
                                    <span className="nav-text">Home</span>
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/statistics">
                                <span>
                                    <i className="nav-icon bi bi-binoculars" />
                                    <span className="nav-text">Statistics</span>
                                </span>
                            </a>
                        </li>
                        <li className="nav-item centerbutton">
                            <button
                                type="button"
                                className="nav-link"
                                data-bs-toggle="modal"
                                data-bs-target="#menumodal"
                                id="centermenubtn"
                            >
                                <span className="theme-radial-gradient">
                                    <i className="bi bi-grid size-22" />
                                </span>
                            </button>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/shop">
                                <span>
                                    <i className="nav-icon bi bi-bag" />
                                    <span className="nav-text">Shop</span>
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/wallet">
                                <span>
                                    <i className="nav-icon bi bi-wallet2" />
                                    <span className="nav-text">Wallet</span>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </footer>
            {/* Menu Modal */}
            <div className="modal fade" id="menumodal" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content shadow">
                        <div className="modal-body">
                            <h1 className="mb-4">
                                <span className="text-secondary fw-light">Quick</span>
                                <br />
                                Actions!
                            </h1>
                            <div className="text-center">
                                <img src="assets/img/QRCode.png" alt="" className="mb-2" />
                                <p className="small text-secondary mb-4">
                                    Ask to scan this QR-Code
                                    <br />
                                    to accept money
                                </p>
                            </div>
                            <div className="row justify-content-center mb-4">
                                <div className="col-auto text-center">
                                    <a
                                        href="/bills"
                                        className="avatar avatar-70 p-1 shadow-sm shadow-danger rounded-20 bg-opac mb-2"
                                        data-bs-dismiss="modal"
                                    >
                                        <div className="icons text-danger">
                                            <i className="bi bi-receipt-cutoff size-24" />
                                        </div>
                                    </a>
                                    <p className="size-10 text-secondary">Pay Bill</p>
                                </div>
                                <div className="col-auto text-center">
                                    <a
                                        href="/sendmoney"
                                        className="avatar avatar-70 p-1 shadow-sm shadow-purple rounded-20 bg-opac mb-2"
                                        data-bs-dismiss="modal"
                                    >
                                        <div className="icons text-purple">
                                            <i className="bi bi-arrow-up-right size-24" />
                                        </div>
                                    </a>
                                    <p className="size-10 text-secondary">Send Money</p>
                                </div>
                                <div className="col-auto text-center">
                                    <a
                                        href="/receivemoney"
                                        className="avatar avatar-70 p-1 shadow-sm shadow-success rounded-20 bg-opac mb-2"
                                        data-bs-dismiss="modal"
                                    >
                                        <div className="icons text-success">
                                            <i className="bi bi-arrow-down-left size-24" />
                                        </div>
                                    </a>
                                    <p className="size-10 text-secondary">Receive Money</p>
                                </div>
                            </div>
                            <div className="row justify-content-center mb-2">
                                <div className="col-auto text-center">
                                    <a
                                        href="/withdraw"
                                        className="avatar avatar-70 p-1 shadow-sm shadow-secondary rounded-20 bg-opac mb-2"
                                        data-bs-dismiss="modal"
                                    >
                                        <div className="icons text-secondary">
                                            <i className="bi bi-bank size-24" />
                                        </div>
                                    </a>
                                    <p className="size-10 text-secondary">Withdraw</p>
                                </div>
                                <div className="col-auto text-center">
                                    <a
                                        href="/addmoney"
                                        className="avatar avatar-70 p-1 shadow-sm shadow-warning rounded-20 bg-opac mb-2"
                                        data-bs-dismiss="modal"
                                    >
                                        <div className="icons text-warning">
                                            <i className="bi bi-wallet2 size-24" />
                                        </div>
                                    </a>
                                    <p className="size-10 text-secondary">Add Money</p>
                                </div>
                                <div className="col-auto text-center">
                                    <div
                                        className="avatar avatar-70 p-1 shadow-sm shadow-info rounded-20 bg-opac mb-2"
                                        data-bs-dismiss="modal"
                                    >
                                        <div className="icons text-info">
                                            <i className="bi bi-tv size-24" />
                                        </div>
                                    </div>
                                    <p className="size-10 text-secondary">Recharge</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer ends*/}

        </>

    )
}

export default Profile