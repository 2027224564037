import React from 'react'

function ResetPassword() {
    return (
        <>
            {/* loader section */}

            {/* loader section ends */}
            {/* Begin page content */}
            <main className="container-fluid h-100 ">
                <div className="row h-100">
                    <div className="col-11 col-sm-11 mx-auto">
                        {/* header */}
                        <div className="row">
                            <header className="header">
                                <div className="row">
                                    <div className="col">
                                        <div className="logo-small">
                                            <a href='/'> <img src="assets/img/logo.png" alt="" />
                                                <h5>
                                                    <span className="text-secondary fw-light">Finance</span>
                                                    <br />
                                                    Wallet
                                                </h5>
                                            </a>

                                        </div>
                                    </div>
                                    <div className="col-auto align-self-center">
                                        <a href="/signin">Sing in</a>
                                    </div>
                                </div>
                            </header>
                        </div>
                        {/* header ends */}
                    </div>
                    <div className="col-11 col-sm-11 col-md-6 col-lg-5 col-xl-3 mx-auto align-self-center py-4">
                        <h1 className="mb-3">
                            <span className="text-secondary fw-light">Change your</span>
                            <br />
                            password
                        </h1>
                        <p className="text-secondary mb-4">
                            Thank you for confirming email address and username.
                        </p>
                        <div className="form-group form-floating mb-3 is-valid">
                            <input
                                type="password"
                                className="form-control"
                                defaultValue="maxartkiller"
                                id="passowrd1"
                                placeholder="New Password"
                            />
                            <label className="form-control-label" htmlFor="passowrd1">
                                New Password
                            </label>
                        </div>
                        <div className="form-group form-floating is-invalid mb-3">
                            <input
                                type="password"
                                className="form-control "
                                id="password"
                                placeholder="Confirm Password"
                            />
                            <label className="form-control-label" htmlFor="password">
                                Confirm Password
                            </label>
                            <button
                                type="button"
                                className="text-danger tooltip-btn"
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title="Enter valid Password"
                                id="passworderror"
                            >
                                <i className="bi bi-info-circle" />
                            </button>
                        </div>
                    </div>
                    <div className="col-11 col-sm-11 mt-auto mx-auto py-4">
                        <div className="row ">
                            <div className="col-12 d-grid">
                                <a
                                    href="/thankyou"
                                    className="btn btn-default btn-lg shadow-sm"
                                >
                                    Update
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {/* Required jquery and libraries */}
            {/* Customized jquery file  */}
            {/* PWA app service registration and works */}
            {/* page level custom script */}
        </>

    )
}

export default ResetPassword