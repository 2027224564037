import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css"; // Import Swiper styles
import "swiper/css";

function Wallet() {
    const cardsData = [
        {
          id: 1,
          cardClass: "theme-bg shadow-sm shadow-purple mb-3",
          imgSrc: "assets/img/card-bg.png",
          cardLogo: "assets/img/maestro.png",
          bank: "City Bank",
          cardType: "Credit Card",
          cardNumber: "000 0000 0001 546598",
          expiry: "09/023",
          cardHolder: "Maxartkiller",
          expense: "1500.00",
          limitRemain: "13500.00",
          expenseChange: "18.2",
          expenseChangeType: "up",
        },
        {
          id: 2,
          cardClass: "bg-danger shadow-sm shadow-danger mb-3",
          // imgSrc: "assets/img/visa.png",
          cardLogo: "assets/img/visa.png",
          bank: "City Bank",
          cardType: "Credit Card",
          cardNumber: "000 0000 0001 546598",
          expiry: "09/023",
          cardHolder: "Maxartkiller",
          expense: "3500.00",
          limitRemain: "13500.00",
          expenseChange: "10.2",
          expenseChangeType: "down",
        },
        {
          id: 3,
          cardClass: "theme-radial-gradient border-0 mb-3",
          // imgSrc: "assets/img/maestro.png",
          cardLogo: "assets/img/maestro.png",
          bank: "City Bank",
          cardType: "Credit Card",
          cardNumber: "000 0000 0001 546598",
          expiry: "09/023",
          cardHolder: "Maxartkiller",
          expense: "1254.00",
          limitRemain: "13500.00",
          expenseChange: "18.2",
          expenseChangeType: "up",
        },
      ];


    return (
        <>
            {/* loader section */}

            {/* loader section ends */}
            {/* Sidebar main menu */}
            <div className="sidebar-wrap  sidebar-overlay">
                {/* Add pushcontent or fullmenu instead overlay */}
                <div className="closemenu text-muted">Close Menu</div>
                <div className="sidebar ">
                    {/* user information */}
                    <div className="row my-3">
                        <div className="col-12 profile-sidebar">
                            <div className="clearfix" />
                            <div className="circle small one" />
                            <div className="circle small two" />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 194.287 141.794"
                                className="menubg"
                            >
                                <defs>
                                    <linearGradient
                                        id="linear-gradient"
                                        x1="0.5"
                                        x2="0.5"
                                        y2={1}
                                        gradientUnits="objectBoundingBox"
                                    >
                                        <stop offset={0} stopColor="#09b2fd" />
                                        <stop offset={1} stopColor="#6b00e5" />
                                    </linearGradient>
                                </defs>
                                <path
                                    id="menubg"
                                    d="M672.935,207.064c-19.639,1.079-25.462-3.121-41.258,10.881s-24.433,41.037-49.5,34.15-14.406-16.743-50.307-29.667-32.464-19.812-16.308-41.711S500.472,130.777,531.872,117s63.631,21.369,93.913,15.363,37.084-25.959,56.686-19.794,4.27,32.859,6.213,44.729,9.5,16.186,9.5,26.113S692.573,205.985,672.935,207.064Z"
                                    transform="translate(-503.892 -111.404)"
                                    fill="url(#linear-gradient)"
                                />
                            </svg>
                            <div className="row mt-3">
                                <div className="col-auto">
                                    <figure className="avatar avatar-80 rounded-20 p-1 bg-white shadow-sm">
                                        <img src="assets/img/user1.jpg" alt="" className="rounded-18" />
                                    </figure>
                                </div>
                                <div className="col px-0 align-self-center">
                                    <h5 className="mb-2">John Winsels</h5>
                                    <p className="text-muted size-12">
                                        New York City,
                                        <br />
                                        United States
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* user emnu navigation */}
                    <div className="row">
                        <div className="col-12">
                            <ul className="nav nav-pills">
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        aria-current="page"
                                        href="/"
                                    >
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-house-door" />
                                        </div>
                                        <div className="col">Dashboard</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        href="#"
                                        role="button"
                                        aria-expanded="false"
                                    >
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-cart" />
                                        </div>
                                        <div className="col">Shop</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-down plus" />{" "}
                                            <i className="bi bi-chevron-up minus" />
                                        </div>
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <a className="dropdown-item nav-link" href="/shop">
                                                <div className="avatar avatar-40  icon">
                                                    <i className="bi bi-shop" />
                                                </div>
                                                <div className="col align-self-center">Shop home</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/products">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-box-seam" />
                                                </div>
                                                <div className="col align-self-center">All Products</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/product">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-box-seam" />
                                                </div>
                                                <div className="col align-self-center">Product</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/cart">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-bag" />
                                                </div>
                                                <div className="col align-self-center">Cart</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/myorders">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-view-list" />
                                                </div>
                                                <div className="col align-self-center">My Orders</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/payment">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-cash-stack" />
                                                </div>
                                                <div className="col align-self-center">Payment</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/invoice">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-receipt" />
                                                </div>
                                                <div className="col align-self-center">Invoice</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/chat" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-chat-text" />
                                        </div>
                                        <div className="col">Messages</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/notifications" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-bell" />
                                        </div>
                                        <div className="col">Notification</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/blog" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-newspaper" />
                                        </div>
                                        <div className="col">Blogs</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/style" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-palette" />
                                        </div>
                                        <div className="col">
                                            Style <i className="bi bi-star-fill text-warning small" />
                                        </div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/pages" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-file-earmark-text" />
                                        </div>
                                        <div className="col">
                                            Pages <span className="badge bg-info fw-light">new</span>
                                        </div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/signin" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-box-arrow-right" />
                                        </div>
                                        <div className="col">Logout</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Sidebar main menu ends */}
            {/* Begin page */}
            <main className="h-100">
                {/* Header */}
                <header className="header position-fixed">
                    <div className="row">
                        <div className="col-auto">
                            <a
                                href="javascript:void(0)"
                                target="_self"
                                className="btn btn-light btn-44 menu-btn"
                            >
                                <i className="bi bi-list" />
                            </a>
                        </div>
                        <div className="col text-center">
                            <div className="logo-small">
                                <a href='/'> <img src="assets/img/logo.png" alt="" />
                                    <h5>
                                        <span className="text-secondary fw-light">Finance</span>
                                        <br />
                                        Wallet
                                    </h5>
                                </a>
                            </div>
                        </div>
                        <div className="col-auto">
                            <a
                                href="/profile"
                                target="_self"
                                className="btn btn-light btn-44"
                            >
                                <i className="bi bi-person-circle" />
                                <span className="count-indicator" />
                            </a>
                        </div>
                    </div>
                </header>
                {/* Header ends */}
                {/* main page content */}
                <div className="main-container container">
                    {/* balance */}
                    <div className="row my-4 text-center">
                        <div className="col-12">
                            <h1 className="fw-light mb-2">$ 1,050.00</h1>
                            <p className="text-secondary">
                                <a href="/addmoney">+ Add Money</a>
                            </p>
                        </div>
                    </div>
                    {/* income expense*/}
                    <div className="row mb-4">
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-auto">
                                            <div className="avatar avatar-40 p-1 shadow-sm shadow-success rounded-15">
                                                <div className="icons bg-success text-white rounded-12">
                                                    <i className="bi bi-arrow-down-left" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col align-self-center ps-0">
                                            <p className="size-10 text-secondary mb-0">Income</p>
                                            <p>1542k</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-auto">
                                            <div className="avatar avatar-40 p-1 shadow-sm shadow-danger rounded-15">
                                                <div className="icons bg-danger text-white rounded-12">
                                                    <i className="bi bi-arrow-up-right" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col align-self-center ps-0">
                                            <p className="size-10 text-secondary mb-0">Expense</p>
                                            <p>1212k</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* currency */}
                    <div className="row mb-2">
                        <div className="col">
                            <h6 className="title">Currency</h6>
                        </div>
                        <div className="col-auto align-self-center">
                            <a href="/addcurrency" className="small">
                                Add New
                            </a>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <ul className="list-group list-group-flush bg-none">
                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <div className="avatar avatar-44 shadow-sm card rounded-15 p-1">
                                                        <img
                                                            src="assets/img/company6.png"
                                                            alt=""
                                                            className="rounded-12"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col align-self-center ps-0">
                                                    <p className="text-secondary size-10 mb-0">BitCoin BTC</p>
                                                    <p>Zomato</p>
                                                </div>
                                                <div className="col-auto align-self-center text-end">
                                                    <canvas className="areachartsmall" />
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <div className="avatar avatar-44 shadow-sm card rounded-15 p-1">
                                                        <img
                                                            src="assets/img/company7.jpg"
                                                            alt=""
                                                            className="rounded-12"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col align-self-center ps-0">
                                                    <p className="text-secondary size-10 mb-0">
                                                        Etherium ETH
                                                    </p>
                                                    <p>Uber</p>
                                                </div>
                                                <div className="col-auto align-self-center text-end">
                                                    <canvas className="areachartsmall" />
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <div className="avatar avatar-44 p-1 shadow-sm shadow-warning rounded-15">
                                                        <div className="icons bg-warning text-white rounded-12">
                                                            <i className="bi bi-star" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col align-self-center ps-0">
                                                    <p className="text-secondary size-10 mb-0">Super Coin</p>
                                                    <p>15000.00</p>
                                                </div>
                                                <div className="col-auto align-self-center text-end">
                                                    <canvas className="areachartsmall" />
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* cards expense data  */}
                    <div className="row mb-2">
                        <div className="col">
                            <h6 className="title">My Credit available</h6>
                        </div>
                        <div className="col-auto align-self-center">
                            <a href="" className="small">
                                Add New
                            </a>
                        </div>
                    </div>
                    {/* swiper credit cards */}
                    <div className="row mb-3">
                        <div className="col-12 px-0">
                            <Swiper
                                spaceBetween={30} // Adjust space between cards
                                slidesPerView='auto' // Number of visible slides at once
                                className="cardswiper"

                            >
                                {cardsData.map((card) => (
                                    <SwiperSlide key={card.id}>
                                        <div className={`card ${card.cardClass}`} style={{ height: "180px", width: "300px" }}>
                                            {card.imgSrc && (
                                                <img src={card.imgSrc} alt="" className="cardimg" />
                                            )}
                                            <div className="card-body">
                                                <div className="row mb-4">
                                                    <div className="col-auto align-self-center">
                                                        <img src={card.cardLogo} alt="" />
                                                    </div>
                                                    <div className="col align-self-center text-end">
                                                        <p className="small">
                                                            <span className="text-muted size-10">{card.bank}</span>
                                                            <br />
                                                            <span className="">{card.cardType}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <h6 className="fw-normal mb-3">{card.cardNumber}</h6>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <p className="mb-0 text-muted size-10">Expiry</p>
                                                        <p>{card.expiry}</p>
                                                    </div>
                                                    <div className="col text-end">
                                                        <p className="mb-0 text-muted size-10">Card Holder</p>
                                                        <p>{card.cardHolder}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-auto">
                                                <p className="mb-0 text-secondary size-10">Expense</p>
                                                <p>
                                                    {card.expense}
                                                    <small
                                                        className={`text-${card.expenseChangeType === "up" ? "success" : "danger"
                                                            }`}
                                                    >
                                                        {card.expenseChange}
                                                        <i
                                                            className={`bi bi-arrow-${card.expenseChangeType === "up" ? "up" : "down"
                                                                }`}
                                                        />
                                                    </small>
                                                </p>
                                            </div>
                                            <div className="col text-end">
                                                <p className="mb-0 text-secondary size-10">Limit Remain</p>
                                                <p>{card.limitRemain}</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
                {/* main page content ends */}
            </main>
            {/* Page ends*/}
            {/* Footer */}
            <footer className="footer">
                <div className="container">
                    <ul className="nav nav-pills nav-justified">
                        <li className="nav-item">
                            <a className="nav-link" href="/">
                                <span>
                                    <i className="nav-icon bi bi-house" />
                                    <span className="nav-text">Home</span>
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/statistics">
                                <span>
                                    <i className="nav-icon bi bi-binoculars" />
                                    <span className="nav-text">Statistics</span>
                                </span>
                            </a>
                        </li>
                        <li className="nav-item centerbutton">
                            <button
                                type="button"
                                className="nav-link"
                                data-bs-toggle="modal"
                                data-bs-target="#menumodal"
                                id="centermenubtn"
                            >
                                <span className="theme-radial-gradient">
                                    <i className="bi bi-grid size-22" />
                                </span>
                            </button>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/shop">
                                <span>
                                    <i className="nav-icon bi bi-bag" />
                                    <span className="nav-text">Shop</span>
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" href="/wallet">
                                <span>
                                    <i className="nav-icon bi bi-wallet2" />
                                    <span className="nav-text">Wallet</span>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </footer>
            {/* Menu Modal */}
            <div className="modal fade" id="menumodal" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content shadow">
                        <div className="modal-body">
                            <h1 className="mb-4">
                                <span className="text-secondary fw-light">Quick</span>
                                <br />
                                Actions!
                            </h1>
                            <div className="text-center">
                                <img src="assets/img/QRCode.png" alt="" className="mb-2" />
                                <p className="small text-secondary mb-4">
                                    Ask to scan this QR-Code
                                    <br />
                                    to accept money
                                </p>
                            </div>
                            <div className="row justify-content-center mb-4">
                                <div className="col-auto text-center">
                                    <a
                                        href="/bills"
                                        className="avatar avatar-70 p-1 shadow-sm shadow-danger rounded-20 bg-opac mb-2"
                                        data-bs-dismiss="modal"
                                    >
                                        <div className="icons text-danger">
                                            <i className="bi bi-receipt-cutoff size-24" />
                                        </div>
                                    </a>
                                    <p className="size-10 text-secondary">Pay Bill</p>
                                </div>
                                <div className="col-auto text-center">
                                    <a
                                        href="/sendmoney"
                                        className="avatar avatar-70 p-1 shadow-sm shadow-purple rounded-20 bg-opac mb-2"
                                        data-bs-dismiss="modal"
                                    >
                                        <div className="icons text-purple">
                                            <i className="bi bi-arrow-up-right size-24" />
                                        </div>
                                    </a>
                                    <p className="size-10 text-secondary">Send Money</p>
                                </div>
                                <div className="col-auto text-center">
                                    <a
                                        href="/receivemoney"
                                        className="avatar avatar-70 p-1 shadow-sm shadow-success rounded-20 bg-opac mb-2"
                                        data-bs-dismiss="modal"
                                    >
                                        <div className="icons text-success">
                                            <i className="bi bi-arrow-down-left size-24" />
                                        </div>
                                    </a>
                                    <p className="size-10 text-secondary">Receive Money</p>
                                </div>
                            </div>
                            <div className="row justify-content-center mb-2">
                                <div className="col-auto text-center">
                                    <a
                                        href="/withdraw"
                                        className="avatar avatar-70 p-1 shadow-sm shadow-secondary rounded-20 bg-opac mb-2"
                                        data-bs-dismiss="modal"
                                    >
                                        <div className="icons text-secondary">
                                            <i className="bi bi-bank size-24" />
                                        </div>
                                    </a>
                                    <p className="size-10 text-secondary">Withdraw</p>
                                </div>
                                <div className="col-auto text-center">
                                    <a
                                        href="/addmoney"
                                        className="avatar avatar-70 p-1 shadow-sm shadow-warning rounded-20 bg-opac mb-2"
                                        data-bs-dismiss="modal"
                                    >
                                        <div className="icons text-warning">
                                            <i className="bi bi-wallet2 size-24" />
                                        </div>
                                    </a>
                                    <p className="size-10 text-secondary">Add Money</p>
                                </div>
                                <div className="col-auto text-center">
                                    <div
                                        className="avatar avatar-70 p-1 shadow-sm shadow-info rounded-20 bg-opac mb-2"
                                        data-bs-dismiss="modal"
                                    >
                                        <div className="icons text-info">
                                            <i className="bi bi-tv size-24" />
                                        </div>
                                    </div>
                                    <p className="size-10 text-secondary">Recharge</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer ends*/}

        </>
    )
}

export default Wallet