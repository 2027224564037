import React from 'react'
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
function Bills() {

    const services = [
        { icon: "bi-flower1", name: "Gas" },
        { icon: "bi-lightning-charge", name: "Electricity" },
        { icon: "bi-credit-card", name: "Creditcard" },
        { icon: "bi-cast", name: "DTH" },
        { icon: "bi-tv", name: "TV" }
    ];

    return (
        <>
            {/* loader section */}

            {/* loader section ends */}
            {/* Begin page */}
            <main className="h-100">
                {/* Header */}
                <header className="header position-fixed">
                    <div className="row">
                        <div className="col-auto">
                            <button type="button" className="btn btn-light btn-44 back-btn">
                                <i className="bi bi-arrow-left" />
                            </button>
                        </div>
                        <div className="col text-center">
                            <div className="logo-small">
                                <a href='/'> <img src="assets/img/logo.png" alt="" />
                                    <h5>
                                        <span className="text-secondary fw-light">Finance</span>
                                        <br />
                                        Wallet
                                    </h5>
                                </a>
                            </div>
                        </div>
                        <div className="col-auto">
                            <a
                                href="profile.html"
                                target="_self"
                                className="btn btn-light btn-44"
                            >
                                <i className="bi bi-person-circle" />
                                <span className="count-indicator" />
                            </a>
                        </div>
                    </div>
                </header>
                {/* Header ends */}
                {/* main page content */}
                <div className="main-container container">
                    {/* service provider */}
                    <div className="row mb-3">
                        <div className="col">
                            <h6 className="title">Popular service provider</h6>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12 px-0">
                            {/* swiper users connections */}
                            <div className="swiper-container connectionwiper">
                                <Swiper spaceBetween={10} slidesPerView='auto'>
                                    {services.map((service, index) => (
                                        <SwiperSlide key={index}>
                                            <a href="/sendmoney" className="card text-center">
                                                <div className="card-body">
                                                    <div className="avatar avatar-50 shadow-sm mb-2 rounded-10 bg-theme text-white">
                                                        <i className={`bi ${service.icon}`} />
                                                    </div>
                                                    <p className="text-color-theme size-12 small">{service.name}</p>
                                                </div>
                                            </a>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                    {/* service provider */}
                    <div className="row mb-3">
                        <div className="col">
                            <h6 className="title">Upcomming Payments</h6>
                        </div>
                        <div className="col-auto">
                            <a href="" className="small">
                                Add Service
                            </a>
                        </div>
                    </div>
                    <div className="row mb-1">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-auto">
                                            <div className="avatar avatar-44 shadow-sm rounded-10 bg-danger text-white">
                                                <i className="bi bi-phone vm" />
                                            </div>
                                        </div>
                                        <div className="col align-self-center ps-0">
                                            <p className="mb-0 size-12">
                                                <span className="text-color-theme fw-medium">
                                                    xxxxx88154
                                                </span>
                                                <span className="text-muted ">Recharge Expiring</span>
                                            </p>
                                            <p>
                                                50 ${" "}
                                                <small className="size-12 text-muted">
                                                    3 Days remaining
                                                </small>
                                            </p>
                                        </div>
                                        <div className="col-auto">
                                            <a
                                                href="/sendmoney"
                                                className="btn btn-default btn-44 shadow-sm"
                                            >
                                                <i className="bi bi-arrow-up-right-circle" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-auto">
                                            <div className="avatar avatar-44 shadow-sm rounded-10 bg-info text-white">
                                                <i className="bi bi-moisture vm" />
                                            </div>
                                        </div>
                                        <div className="col align-self-center ps-0">
                                            <p className="mb-0 size-12">
                                                <span className="text-color-theme fw-medium">
                                                    Water Bill
                                                </span>
                                                <span className="text-muted ">Upcoming payment</span>
                                            </p>
                                            <p>
                                                NA{" "}
                                                <small className="size-12 text-muted">
                                                    13 Days remaining
                                                </small>
                                            </p>
                                        </div>
                                        <div className="col-auto">
                                            <a
                                                href="/sendmoney"
                                                className="btn btn-default btn-44 shadow-sm"
                                            >
                                                <i className="bi bi-arrow-up-right-circle" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-auto">
                                            <div className="avatar avatar-44 shadow-sm rounded-10 bg-warning text-white">
                                                <i className="bi bi-lightning-charge vm" />
                                            </div>
                                        </div>
                                        <div className="col align-self-center ps-0">
                                            <p className="mb-0 size-12">
                                                <span className="text-color-theme fw-medium">
                                                    Electricity Bill
                                                </span>{" "}
                                                <span className="text-muted ">Pending payment</span>
                                            </p>
                                            <p>
                                                550 ${" "}
                                                <small className="size-12 text-muted">3 Days Overdue</small>
                                            </p>
                                        </div>
                                        <div className="col-auto">
                                            <a
                                                href="/sendmoney"
                                                className="btn btn-default btn-44 shadow-sm"
                                            >
                                                <i className="bi bi-arrow-up-right-circle" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-auto">
                                            <div className="avatar avatar-44 shadow-sm rounded-10 bg-theme text-white">
                                                <i className="bi bi-house vm" />
                                            </div>
                                        </div>
                                        <div className="col align-self-center ps-0">
                                            <p className="mb-0 size-12">
                                                <span className="text-color-theme fw-medium">
                                                    Home Loan EMI
                                                </span>{" "}
                                                <span className="text-muted ">Auto</span>
                                            </p>
                                            <p>
                                                3510${" "}
                                                <small className="size-12 text-muted">
                                                    20days remaining
                                                </small>
                                            </p>
                                        </div>
                                        <div className="col-auto ps-0">
                                            <button
                                                className="btn btn-default btn-44 shadow-sm"
                                                disabled=""
                                            >
                                                <i className="bi bi-arrow-clockwise" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Direct bill */}
                    <div className="row mb-3">
                        <div className="col">
                            <h6 className="title">Direct Bills</h6>
                        </div>
                    </div>
                    <div className="row mb-1 justify-content-center">
                        <div className="col-12">
                            <div className="card bg-opac-50 mb-3">
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-auto">
                                            <div className="avatar avatar-44 shadow-sm rounded-10">
                                                <img src="assets/img/user4.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div className="col align-self-center ps-0">
                                            <p className="mb-0 text-color-theme">To: Maxartkiller.com</p>
                                            <p className="text-muted small">Invoice ID: IDE0515416385</p>
                                        </div>
                                        <div className="col-auto">
                                            <a
                                                href="/sendmoney"
                                                className="btn btn-default btn-44 shadow-sm"
                                            >
                                                <i className="bi bi-arrow-up-right-circle" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <div className="avatar avatar-60 shadow-sm rounded-10 bg-danger text-white">
                                                        <img src="assets/img/company3.jpg" alt="" />
                                                    </div>
                                                </div>
                                                <div className="col align-self-center ps-0">
                                                    <p className="text-color-theme">
                                                        Walmart
                                                        <span className="tag bg-warning text-white px-2 float-end">
                                                            Incomplete
                                                        </span>
                                                    </p>
                                                    <div className="row">
                                                        <div className="col">118.00</div>
                                                        <div className="col-auto align-self-center text-end">
                                                            <span className="text-muted size-12 ">
                                                                Date: 18/07/2021
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* main page content ends */}
            </main>
            {/* Page ends*/}
        </>

    )
}

export default Bills