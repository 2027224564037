import React from 'react'

function Thankyou() {
    return (
        <>
            {/* loader section */}
         
            {/* loader section ends */}
            {/* Begin page content */}
            <main className="container-fluid h-100 ">
                <div className="row h-100">
                    <div className="col-11 col-sm-11 mx-auto">
                        {/* header */}
                        <div className="row">
                            <header className="header">
                                <div className="row">
                                    <div className="col">
                                        <div className="logo-small">
                                            <img src="assets/img/logo.png" alt="" />
                                            <h5>
                                                <span className="text-secondary fw-light">Finance</span>
                                                <br />
                                                Wallet
                                            </h5>
                                        </div>
                                    </div>
                                    <div className="col-auto align-self-center"></div>
                                </div>
                            </header>
                        </div>
                        {/* header ends */}
                    </div>
                    <div className="col-12 align-self-center pb-3">
                        <div className="row h-100">
                            <div className="col-12 px-0 align-self-center">
                                <div className="row mx-0">
                                    <div className="col-ld-6 position-relative thankyouimg2">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            width="442.994"
                                            height="323.173"
                                            viewBox="0 0 442.994 323.173"
                                            className="thankyoubg mb-4"
                                        >
                                            <defs>
                                                <linearGradient
                                                    id="linear-gradient"
                                                    x1="0.5"
                                                    x2="0.5"
                                                    y2={1}
                                                    gradientUnits="objectBoundingBox"
                                                >
                                                    <stop offset={0} stopColor="#09b2fd" />
                                                    <stop offset={1} stopColor="#6b00e5" />
                                                </linearGradient>
                                            </defs>
                                            <path
                                                id="verficationbg"
                                                d="M673.089,326.922c33.846,30,40.769,106.154,96.153,104.615S810.012,400,886.935,372.306s69.558-78.229,34.943-125.152-5.917-85.116-48.7-111.97S750.281,179.79,685.4,166.922s-61.285-31.4-87.179-48.505S517.759,104.545,506.2,140.15s24.507,55.951,16.184,90.169c-3.9,16.048-6.938,35.382,4.447,51.041,16,20.622,34.073,21.193,60.29,16.47C633.49,289.476,656.64,312.342,673.089,326.922Z"
                                                transform="translate(-503.892 -108.386)"
                                                fill="url(#linear-gradient)"
                                            />
                                        </svg>
                                        <div className="text">Withdrawn</div>
                                        <div className="circle small one" />
                                        <div className="circle two" />
                                        <div className="circle small three" />
                                        <img
                                            src="assets/img/checkmark.svg"
                                            alt=""
                                            className="slideimg"
                                        />
                                    </div>
                                    <div className="col-11 col-md-8 col-lg-4  col-ld-6 mx-auto">
                                        <h1 className="mb-4">
                                            <span className="text-secondary fw-light">Withdraw</span>
                                            <br />
                                            Successfully!
                                        </h1>
                                        <p className="text-secondary">
                                            You have successfully debited amount to your maestro card ends
                                            with the number XX365. Transaction ID: 58552544MAX2541
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-11 col-sm-11 mt-auto mx-auto py-4">
                        <div className="row ">
                            <div className="col-12 d-grid">
                                <a href="/" className="btn btn-default btn-lg shadow-sm">
                                    Back to Home
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            {/* Required jquery and libraries */}
        </>

    )
}

export default Thankyou