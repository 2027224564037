import React from 'react'

function Signup() {
  return (
    <>
  {/* loader section */}
  {/* <div className="container-fluid loader-wrap">
    <div className="row h-100">
      <div className="col-10 col-md-6 col-lg-5 col-xl-3 mx-auto text-center align-self-center">
        <div className="logo-wallet">
          <div className="wallet-bottom"></div>
          <div className="wallet-cards" />
          <div className="wallet-top"></div>
        </div>
        <p className="mt-4">
          <span className="text-secondary">Track finance with Wallet app</span>
          <br />
          <strong>Please wait...</strong>
        </p>
      </div>
    </div>
  </div> */}
  {/* loader section ends */}
  {/* Begin page content */}
  <main className="container-fluid h-100">
    <div className="row h-100">
      <div className="col-11 col-sm-11 mx-auto">
        {/* header */}
        <div className="row">
          <header className="header">
            <div className="row">
              <div className="col">
                 <div className="logo-small">
                                <a href='/'> <img src="assets/img/logo.png" alt="" />
                                    <h5>
                                        <span className="text-secondary fw-light">Finance</span>
                                        <br />
                                        Wallet
                                    </h5>
                                </a>
                            </div>
              </div>
              <div className="col-auto align-self-center">
                <a href="/signin">Sing in</a>
              </div>
            </div>
          </header>
        </div>
        {/* header ends */}
      </div>
      <div className="col-11 col-sm-11 col-md-6 col-lg-5 col-xl-3 mx-auto align-self-center py-4">
        <h1 className="mb-4">
          <span className="text-secondary fw-light">Create</span>
          <br />
          new account
        </h1>
        <div className="form-floating is-valid mb-3">
          <select className="form-control" id="country">
            <option selected="">United States (+1)</option>
            <option>United Kingdoms (+44)</option>
          </select>
          <label htmlFor="country">Contry</label>
        </div>
        <div className="form-floating is-valid mb-3">
          <input
            type="text"
            className="form-control"
            defaultValue="info@maxartkiller.com"
            placeholder="Email or Phone"
            id="emailphone"
          />
          <label htmlFor="emailphone">Email or Phone</label>
        </div>
        <div className="form-floating is-valid mb-3">
          <input
            type="text"
            className="form-control"
            defaultValue="maxartkiller"
            placeholder="Username"
            id="username"
          />
          <label htmlFor="username">Username</label>
        </div>
        <div className="form-floating is-valid mb-3">
          <input
            type="password"
            className="form-control"
            defaultValue="asdasdasdsd"
            placeholder="Password"
            id="password"
          />
          <label htmlFor="password">Password</label>
        </div>
        <div className="form-floating is-invalid mb-3">
          <input
            type="password"
            className="form-control"
            placeholder="Confirm Password"
            id="confirmpassword"
          />
          <label htmlFor="confirmpassword">Confirm Password</label>
          <button
            type="button"
            className="btn btn-link text-danger tooltip-btn"
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            title="Enter valid Password"
            id="passworderror"
          >
            <i className="bi bi-info-circle" />
          </button>
        </div>
        <p className="mb-3">
          <span className="text-muted">
            By clicking on Sign up button, you are agree to the our{" "}
          </span>{" "}
          <a href="">Terms and Conditions</a>
        </p>
      </div>
      <div className="col-11 col-sm-11 mt-auto mx-auto py-4">
        <div className="row ">
          <div className="col-12 d-grid">
            <a href="/verify" className="btn btn-default btn-lg shadow-sm">
              Sign Up
            </a>
          </div>
        </div>
      </div>
    </div>
  </main>
  {/* Required jquery and libraries */}
  {/* Customized jquery file  */}
  {/* PWA app service registration and works */}
  {/* page level custom script */}
</>

  )
}

export default Signup