import React from 'react'

function Rewards() {
    return (
        <>
            {/* loader section */}
        
            {/* loader section ends */}
            {/* Begin page */}
            <main className="h-100">
                {/* Header */}
                <header className="header position-fixed">
                    <div className="row">
                        <div className="col-auto">
                            <button type="button" className="btn btn-light btn-44 back-btn">
                                <i className="bi bi-arrow-left" />
                            </button>
                        </div>
                        <div className="col text-center">
                            <div className="logo-small">
                               <a href='/'> <img src="assets/img/logo.png" alt="" />
                                <h5>
                                    <span className="text-secondary fw-light">Finance</span>
                                    <br />
                                    Wallet
                                </h5>
                                </a>
                            </div>
                        </div>
                        <div className="col-auto">
                            <a
                                href="/profile"
                                target="_self"
                                className="btn btn-light btn-44"
                            >
                                <i className="bi bi-person-circle" />
                                <span className="count-indicator" />
                            </a>
                        </div>
                    </div>
                </header>
                {/* Header ends */}
                {/* main page content */}
                <div className="main-container container">
                    {/* summary blocks */}
                    <div className="row">
                        <div className="col-12 px-0">
                            <div className="swiper-container summayswiper">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <div className="card shadow-sm mb-2">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <div className="card">
                                                            <div className="card-body p-1">
                                                                <div className="avatar avatar-50 bg-warning text-white shadow-sm rounded-15">
                                                                    <i className="bi bi-star" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col px-0 align-self-center">
                                                        <p className="text-secondary size-12 mb-0">
                                                            Bonus Points
                                                        </p>
                                                        <p>48546 pts</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="card shadow-sm mb-2">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <div className="card">
                                                            <div className="card-body p-1">
                                                                <div className="avatar avatar-50 bg-success text-white shadow-sm rounded-15">
                                                                    <i className="bi bi-cash-stack" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col px-0 align-self-center">
                                                        <p className="text-secondary size-12 mb-0">Cashback</p>
                                                        <p>15 USD</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="card shadow-sm mb-2">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <div className="card">
                                                            <div className="card-body p-1">
                                                                <div className="avatar avatar-50 bg-primary text-white shadow-sm rounded-15">
                                                                    <i className="bi bi-controller" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col px-0 align-self-center">
                                                        <p className="text-secondary size-12 mb-0">Gameplay</p>
                                                        <p>105 coins</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="card shadow-sm mb-2">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <div className="card">
                                                            <div className="card-body p-1">
                                                                <div className="avatar avatar-50 bg-info text-white shadow-sm rounded-15">
                                                                    <i className="bi bi-droplet" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col px-0 align-self-center">
                                                        <p className="text-secondary size-12 mb-0">Fuel</p>
                                                        <p>3 ltr</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* offers banner*/}
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="card theme-bg overflow-hidden">
                                <figure className="m-0 p-0 position-absolute top-0 start-0 w-100 h-100 coverimg right-center-img">
                                    <img src="assets/img/offerbg.png" alt="" />
                                </figure>
                                <div className="card-body py-4">
                                    <div className="row">
                                        <div className="col-10 align-self-center">
                                            <h1 className="mb-3">
                                                <span className="fw-light">15% OFF</span>
                                                <br />
                                                GiftCard
                                            </h1>
                                            <p>
                                                Thank you for spending with us, You have received Gift Card.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* service provider */}
                    <div className="row mb-3">
                        <div className="col">
                            <h6 className="title">Popular service provider</h6>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12 px-0">
                            {/* swiper users connections */}
                            <div className="swiper-container connectionwiper">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <div className="card text-center">
                                            <div className="card-body">
                                                <div className="row justify-content-center mb-3">
                                                    <div className="col-auto">
                                                        <div className="card">
                                                            <div className="card-body p-1">
                                                                <div className="avatar avatar-50 shadow-sm rounded-15 theme-bg text-white">
                                                                    <img
                                                                        src="assets/img/company2-50.png"
                                                                        alt=""
                                                                        className=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="text-color-theme size-12 small mb-1">
                                                    Flat 20%
                                                </p>
                                                <div className="tag tag-small bg-warning border-warning text-white">
                                                    50 pts
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="card text-center">
                                            <div className="card-body">
                                                <div className="row justify-content-center mb-3">
                                                    <div className="col-auto">
                                                        <div className="card">
                                                            <div className="card-body p-1">
                                                                <div className="avatar avatar-50 shadow-sm rounded-15 theme-bg text-white">
                                                                    <img
                                                                        src="assets/img/company5.png"
                                                                        alt=""
                                                                        className=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="text-color-theme size-12 small mb-1">
                                                    20% Off
                                                </p>
                                                <div className="tag tag-small bg-warning border-warning text-white">
                                                    100 pts
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="card text-center">
                                            <div className="card-body">
                                                <div className="row justify-content-center mb-3">
                                                    <div className="col-auto">
                                                        <div className="card">
                                                            <div className="card-body p-1">
                                                                <div className="avatar avatar-50 shadow-sm rounded-15 theme-bg text-white">
                                                                    <img
                                                                        src="assets/img/company3.jpg"
                                                                        alt=""
                                                                        className=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="text-color-theme size-12 small mb-1">
                                                    25% Off
                                                </p>
                                                <div className="tag tag-small bg-warning border-warning text-white">
                                                    250 pts
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="card text-center">
                                            <div className="card-body">
                                                <div className="row justify-content-center mb-3">
                                                    <div className="col-auto">
                                                        <div className="card">
                                                            <div className="card-body p-1">
                                                                <div className="avatar avatar-50 shadow-sm rounded-15 theme-bg text-white">
                                                                    <img
                                                                        src="assets/img/company4.jpg"
                                                                        alt=""
                                                                        className=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="text-color-theme size-12 small mb-1">
                                                    10% Off
                                                </p>
                                                <div className="tag tag-small bg-warning border-warning text-white">
                                                    50 pts
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="card text-center">
                                            <div className="card-body">
                                                <div className="row justify-content-center mb-3">
                                                    <div className="col-auto">
                                                        <div className="card">
                                                            <div className="card-body p-1">
                                                                <div className="avatar avatar-50 shadow-sm rounded-15 theme-bg text-white">
                                                                    <img
                                                                        src="assets/img/company2-50.png"
                                                                        alt=""
                                                                        className=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="text-color-theme size-12 small mb-1">
                                                    Flat 20%
                                                </p>
                                                <div className="tag tag-small bg-warning border-warning text-white">
                                                    50 pts
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="card text-center">
                                            <div className="card-body">
                                                <div className="row justify-content-center mb-3">
                                                    <div className="col-auto">
                                                        <div className="card">
                                                            <div className="card-body p-1">
                                                                <div className="avatar avatar-50 shadow-sm rounded-15 theme-bg text-white">
                                                                    <img
                                                                        src="assets/img/company5.png"
                                                                        alt=""
                                                                        className=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="text-color-theme size-12 small mb-1">
                                                    20% Off
                                                </p>
                                                <div className="tag tag-small bg-warning border-warning text-white">
                                                    100 pts
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="card text-center">
                                            <div className="card-body">
                                                <div className="row justify-content-center mb-3">
                                                    <div className="col-auto">
                                                        <div className="card">
                                                            <div className="card-body p-1">
                                                                <div className="avatar avatar-50 shadow-sm rounded-15 theme-bg text-white">
                                                                    <img
                                                                        src="assets/img/company3.jpg"
                                                                        alt=""
                                                                        className=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="text-color-theme size-12 small mb-1">
                                                    25% Off
                                                </p>
                                                <div className="tag tag-small bg-warning border-warning text-white">
                                                    250 pts
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="card text-center">
                                            <div className="card-body">
                                                <div className="row justify-content-center mb-3">
                                                    <div className="col-auto">
                                                        <div className="card">
                                                            <div className="card-body p-1">
                                                                <div className="avatar avatar-50 shadow-sm rounded-15 theme-bg text-white">
                                                                    <img
                                                                        src="assets/img/company4.jpg"
                                                                        alt=""
                                                                        className=""
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="text-color-theme size-12 small mb-1">
                                                    10% Off
                                                </p>
                                                <div className="tag tag-small bg-warning border-warning text-white">
                                                    50 pts
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* rewards withdraw */}
                    <div className="row mb-1 justify-content-center">
                        <div className="col-12 col-md-8 col-lg-6">
                            <div className="card bg-opac-50 mb-3">
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-auto">
                                            <div className="card">
                                                <div className="card-body p-1">
                                                    <div className="avatar avatar-50 bg-warning text-white shadow-sm rounded-15">
                                                        <i className="bi bi-star" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col align-self-center ps-0">
                                            <p className="mb-0">Withdraw to account</p>
                                            <p className="text-secondary small">
                                                Get 1 USD for 100 points
                                            </p>
                                        </div>
                                    </div>
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <div className="row mb-4 position-relative">
                                                <div className="col pe-0">
                                                    <div className="form-group form-floating">
                                                        <input
                                                            type="text"
                                                            className="form-control "
                                                            id="amountpoints"
                                                            placeholder="Points"
                                                            defaultValue={48000}
                                                        />
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="amountpoints"
                                                        >
                                                            Points
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col align-self-center ps-0">
                                                    <div className="form-group form-floating">
                                                        <input
                                                            type="text"
                                                            className="form-control text-end"
                                                            id="amountcurrency"
                                                            placeholder="Currency"
                                                            defaultValue={480}
                                                        />
                                                        <label
                                                            className="form-control-label text-end pe-1 end-0 start-auto"
                                                            htmlFor="amountcurrency"
                                                        >
                                                            Receivable
                                                        </label>
                                                    </div>
                                                </div>
                                                <button className="btn btn-44 btn-success text-white shadow-sm position-absolute start-50 top-50 translate-middle">
                                                    <i className="bi bi-arrow-left-right" />
                                                </button>
                                            </div>
                                            <h6 className="title mb-3">Withdraw to Account</h6>
                                            <div className="row">
                                                <div className="col-12 px-0">
                                                    <div className="swiper-container cardswiper">
                                                        <div className="swiper-wrapper">
                                                            <div className="swiper-slide">
                                                                <div className="card theme-bg shadow-sm shadow-purple">
                                                                    <img
                                                                        src="assets/img/card-bg.png"
                                                                        alt=""
                                                                        className="cardimg"
                                                                    />
                                                                    <div className="card-body">
                                                                        <div className="row mb-4">
                                                                            <div className="col-auto align-self-center">
                                                                                <img src="assets/img/maestro.png" alt="" />
                                                                            </div>
                                                                            <div className="col align-self-center text-end">
                                                                                <p className="small">
                                                                                    <span className="text-muted size-10">
                                                                                        City Bank
                                                                                    </span>
                                                                                    <br />
                                                                                    <span className="">Credit Card</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <h6 className="fw-normal mb-3">
                                                                            000 0000 0001 546598
                                                                        </h6>
                                                                        <div className="row">
                                                                            <div className="col-auto">
                                                                                <p className="mb-0 text-muted size-10">
                                                                                    Expiry
                                                                                </p>
                                                                                <p>09/023</p>
                                                                            </div>
                                                                            <div className="col text-end">
                                                                                <p className="mb-0 text-muted size-10">
                                                                                    Card Holder
                                                                                </p>
                                                                                <p>Maxartkiller</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="swiper-slide">
                                                                <div className="card bg-danger shadow-sm shadow-danger">
                                                                    <div className="card-body">
                                                                        <div className="row mb-4">
                                                                            <div className="col-auto align-self-center">
                                                                                <img src="assets/img/visa.png" alt="" />
                                                                            </div>
                                                                            <div className="col align-self-center text-end">
                                                                                <p className="small">
                                                                                    <span className="text-muted size-10">
                                                                                        City Bank
                                                                                    </span>
                                                                                    <br />
                                                                                    <span className="">Credit Card</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <h6 className="fw-normal mb-3">
                                                                            000 0000 0001 546598
                                                                        </h6>
                                                                        <div className="row">
                                                                            <div className="col-auto">
                                                                                <p className="mb-0 text-muted size-10">
                                                                                    Expiry
                                                                                </p>
                                                                                <p>09/023</p>
                                                                            </div>
                                                                            <div className="col text-end">
                                                                                <p className="mb-0 text-muted size-10">
                                                                                    Card Holder
                                                                                </p>
                                                                                <p>Maxartkiller</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="swiper-slide">
                                                                <div className="card theme-radial-gradient border-0">
                                                                    <div className="card-body">
                                                                        <div className="row mb-4">
                                                                            <div className="col-auto align-self-center">
                                                                                <img src="assets/img/maestro.png" alt="" />
                                                                            </div>
                                                                            <div className="col align-self-center text-end">
                                                                                <p className="small">
                                                                                    <span className="text-muted size-10">
                                                                                        City Bank
                                                                                    </span>
                                                                                    <br />
                                                                                    <span className="">Credit Card</span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <h6 className="fw-normal mb-3">
                                                                            000 0000 0001 546598
                                                                        </h6>
                                                                        <div className="row">
                                                                            <div className="col-auto">
                                                                                <p className="mb-0 text-muted size-10">
                                                                                    Expiry
                                                                                </p>
                                                                                <p>09/023</p>
                                                                            </div>
                                                                            <div className="col text-end">
                                                                                <p className="mb-0 text-muted size-10">
                                                                                    Card Holder
                                                                                </p>
                                                                                <p>Maxartkiller</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="btn btn-lg btn-default shadow-sm w-100">
                                        Withdraw
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* large discount block*/}
                    <div className="row mb-1 justify-content-center">
                        <div className="col-6 col-md-4 col-lg-3">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-auto align-self-center">
                                            <div className="card">
                                                <div className="card-body p-1">
                                                    <div className="avatar avatar-50 bg-warning text-white shadow-sm rounded-15">
                                                        <img src="assets/img/company2-50.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col align-self-center ps-0">
                                            <p className="mb-0 text-color-theme">
                                                20<small>% Off</small>
                                            </p>
                                            <div className="tag tag-small bg-warning border-warning text-white px-2">
                                                50 pts
                                            </div>
                                        </div>
                                    </div>
                                    <p className="size-12">
                                        <span className="text-secondary">
                                            minimum spend 1220 USD and maximum discount 240 USD
                                        </span>
                                        <a href="">view details</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-auto align-self-center">
                                            <div className="card">
                                                <div className="card-body p-1">
                                                    <div className="avatar avatar-50 bg-warning text-white shadow-sm rounded-15">
                                                        <img src="assets/img/company4.jpg" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col align-self-center ps-0">
                                            <p className="mb-0 text-color-theme">
                                                60<small>% Off</small>
                                            </p>
                                            <div className="tag tag-small bg-warning border-warning text-white px-2">
                                                100 pts
                                            </div>
                                        </div>
                                    </div>
                                    <p className="size-12">
                                        <span className="text-secondary">
                                            minimum spend 4520 USD and maximum discount 600 USD
                                        </span>
                                        <a href="">view details</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-auto align-self-center">
                                            <div className="card">
                                                <div className="card-body p-1">
                                                    <div className="avatar avatar-50 bg-warning text-white shadow-sm rounded-15">
                                                        <img src="assets/img/company2-50.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col align-self-center ps-0">
                                            <p className="mb-0 text-color-theme">
                                                17<small>% Off</small>
                                            </p>
                                            <div className="tag tag-small bg-warning border-warning text-white px-2">
                                                250 pts
                                            </div>
                                        </div>
                                    </div>
                                    <p className="size-12">
                                        <span className="text-secondary">
                                            minimum spend 1220 USD and maximum discount 480 USD
                                        </span>
                                        <a href="">view details</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-auto align-self-center">
                                            <div className="card">
                                                <div className="card-body p-1">
                                                    <div className="avatar avatar-50 bg-warning text-white shadow-sm rounded-15">
                                                        <img src="assets/img/company3.jpg" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col align-self-center ps-0">
                                            <p className="mb-0 text-color-theme">
                                                55<small>% Off</small>
                                            </p>
                                            <div className="tag tag-small bg-warning border-warning text-white px-2">
                                                150 pts
                                            </div>
                                        </div>
                                    </div>
                                    <p className="size-12">
                                        <span className="text-secondary">
                                            minimum spend 4520 USD and maximum discount 680 USD
                                        </span>
                                        <a href="">view details</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* main page content ends */}
            </main>
            {/* Page ends*/}
        </>

    )
}

export default Rewards