import React from 'react'

function Products() {
    return (
        <>
            {/* loader section */}
       
            {/* loader section ends */}
            {/* Sidebar main menu */}
            <div className="sidebar-wrap  sidebar-overlay">
                {/* Add pushcontent or fullmenu instead overlay */}
                <div className="closemenu text-muted">Close Menu</div>
                <div className="sidebar ">
                    {/* user information */}
                    <div className="row my-3">
                        <div className="col-12 profile-sidebar">
                            <div className="clearfix" />
                            <div className="circle small one" />
                            <div className="circle small two" />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 194.287 141.794"
                                className="menubg"
                            >
                                <defs>
                                    <linearGradient
                                        id="linear-gradient"
                                        x1="0.5"
                                        x2="0.5"
                                        y2={1}
                                        gradientUnits="objectBoundingBox"
                                    >
                                        <stop offset={0} stopColor="#09b2fd" />
                                        <stop offset={1} stopColor="#6b00e5" />
                                    </linearGradient>
                                </defs>
                                <path
                                    id="menubg"
                                    d="M672.935,207.064c-19.639,1.079-25.462-3.121-41.258,10.881s-24.433,41.037-49.5,34.15-14.406-16.743-50.307-29.667-32.464-19.812-16.308-41.711S500.472,130.777,531.872,117s63.631,21.369,93.913,15.363,37.084-25.959,56.686-19.794,4.27,32.859,6.213,44.729,9.5,16.186,9.5,26.113S692.573,205.985,672.935,207.064Z"
                                    transform="translate(-503.892 -111.404)"
                                    fill="url(#linear-gradient)"
                                />
                            </svg>
                            <div className="row mt-3">
                                <div className="col-auto">
                                    <figure className="avatar avatar-80 rounded-20 p-1 bg-white shadow-sm">
                                        <img src="assets/img/user1.jpg" alt="" className="rounded-18" />
                                    </figure>
                                </div>
                                <div className="col px-0 align-self-center">
                                    <h5 className="mb-2">John Winsels</h5>
                                    <p className="text-muted size-12">
                                        New York City,
                                        <br />
                                        United States
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* user emnu navigation */}
                    <div className="row">
                        <div className="col-12">
                            <ul className="nav nav-pills">
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        aria-current="page"
                                        href="/"
                                    >
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-house-door" />
                                        </div>
                                        <div className="col">Dashboard</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        href="#"
                                        role="button"
                                        aria-expanded="false"
                                    >
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-cart" />
                                        </div>
                                        <div className="col">Shop</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-down plus" />{" "}
                                            <i className="bi bi-chevron-up minus" />
                                        </div>
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <a className="dropdown-item nav-link" href="/shop">
                                                <div className="avatar avatar-40  icon">
                                                    <i className="bi bi-shop" />
                                                </div>
                                                <div className="col align-self-center">Shop home</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/products">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-box-seam" />
                                                </div>
                                                <div className="col align-self-center">All Products</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/product">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-box-seam" />
                                                </div>
                                                <div className="col align-self-center">Product</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/cart">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-bag" />
                                                </div>
                                                <div className="col align-self-center">Cart</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/myorders">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-view-list" />
                                                </div>
                                                <div className="col align-self-center">My Orders</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/payment">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-cash-stack" />
                                                </div>
                                                <div className="col align-self-center">Payment</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/invoice">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-receipt" />
                                                </div>
                                                <div className="col align-self-center">Invoice</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/chat" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-chat-text" />
                                        </div>
                                        <div className="col">Messages</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/notifications" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-bell" />
                                        </div>
                                        <div className="col">Notification</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/blog" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-newspaper" />
                                        </div>
                                        <div className="col">Blogs</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/style" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-palette" />
                                        </div>
                                        <div className="col">
                                            Style <i className="bi bi-star-fill text-warning small" />
                                        </div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/pages" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-file-earmark-text" />
                                        </div>
                                        <div className="col">
                                            Pages <span className="badge bg-info fw-light">new</span>
                                        </div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/signin" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-box-arrow-right" />
                                        </div>
                                        <div className="col">Logout</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Sidebar main menu ends */}
            {/* Begin page */}
            <main className="h-100">
                {/* Header */}
                <header className="header position-fixed">
                    <div className="row">
                        <div className="col-auto">
                            <a
                                href="javascript:void(0)"
                                target="_self"
                                className="btn btn-light btn-44 menu-btn"
                            >
                                <i className="bi bi-list" />
                            </a>
                        </div>
                        <div className="col text-center">
                                <div className="logo-small">
                                   <a href='/'> <img src="assets/img/logo.png" alt="" />
                                    <h5>
                                        <span className="text-secondary fw-light">Finance</span>
                                        <br />
                                        Wallet
                                    </h5>
                                    </a>
                                </div>
                            </div>
                        <div className="col-auto">
                            <a
                                href="/profile"
                                target="_self"
                                className="btn btn-light btn-44"
                            >
                                <i className="bi bi-person-circle" />
                                <span className="count-indicator" />
                            </a>
                        </div>
                    </div>
                </header>
                {/* Header ends */}
                {/* main page content */}
                <div className="main-container container">
                    {/* products */}
                    <div className="row mb-3">
                        <div className="col align-self-center">
                            <h6 className="title">Products</h6>
                            <p className="text-secondary small">3250 products</p>
                        </div>
                        <div className="col-auto">
                            <nav>
                                <div className="nav nav-tabs border-0" id="nav-tab" role="tablist">
                                    <button
                                        className="btn btn-44 btn-outline-dark active text-normalcase"
                                        id="nav-thumbnails-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-thumbnails"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-thumbnails"
                                        aria-selected="true"
                                    >
                                        <i className="bi bi-grid" />
                                    </button>
                                    <button
                                        className="btn btn-44 btn-outline-dark ms-2 text-normalcase"
                                        id="nav-lists-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-lists"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-lists"
                                        aria-selected="false"
                                    >
                                        <i className="bi bi-list" />
                                    </button>
                                </div>
                            </nav>
                        </div>
                        <div className="col-12 text-center mt-3">
                            <p className="text-secondary">
                                Checkout thumbnails view and list view of all products
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="tab-content" id="nav-tabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="nav-thumbnails"
                                    role="tabpanel"
                                    aria-labelledby="nav-thumbnails-tab"
                                >
                                    {/* product medsize box  */}
                                    <div className="row mb-2">
                                        <div className="col-6 col-md-4 col-lg-3">
                                            <div className="card mb-3">
                                                <div className="card-body p-1 position-relative">
                                                    <div className="position-absolute start-0 top-0 m-2 z-index-1">
                                                        <div className="tag tag-small bg-theme">26% OFF</div>
                                                    </div>
                                                    <div className="position-absolute end-0 top-0 p-2 z-index-1 ">
                                                        <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-white bg-danger">
                                                            <i className="bi bi-heart size-10 vm" />
                                                        </button>
                                                    </div>
                                                    <figure className="avatar w-100 rounded-15 border">
                                                        <img
                                                            src="assets/img/categories4.png"
                                                            alt=""
                                                            className="w-100"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="card-body pt-2">
                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="small">
                                                                <small className="text-muted size-12">
                                                                    LAVA B500
                                                                </small>
                                                                <br />$ 265.00
                                                            </p>
                                                        </div>
                                                        <div className="col-auto px-0">
                                                            <button className="btn btn-sm btn-link text-color-theme">
                                                                <i className="bi bi-bag-plus" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-3">
                                            <div className="card mb-3">
                                                <div className="card-body p-1 position-relative">
                                                    <div className="position-absolute start-0 top-0 m-2 z-index-1">
                                                        <div className="tag tag-small bg-theme">30% OFF</div>
                                                    </div>
                                                    <div className="position-absolute end-0 top-0 p-2 z-index-1 ">
                                                        <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-danger">
                                                            <i className="bi bi-heart size-10 vm" />
                                                        </button>
                                                    </div>
                                                    <figure className="avatar w-100 rounded-15 border">
                                                        <img
                                                            src="assets/img/categories1.png"
                                                            alt=""
                                                            className="w-100"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="card-body pt-2">
                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="small">
                                                                <small className="text-muted size-12">
                                                                    Smasung S500
                                                                </small>
                                                                <br />$ 459.00
                                                            </p>
                                                        </div>
                                                        <div className="col-auto px-0">
                                                            <button className="btn btn-sm btn-link text-color-theme">
                                                                <i className="bi bi-bag-plus" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-3">
                                            <div className="card mb-3">
                                                <div className="card-body p-1 position-relative">
                                                    <div className="position-absolute start-0 top-0 m-2 z-index-1">
                                                        <div className="tag tag-small bg-danger text-white">
                                                            SALE
                                                        </div>
                                                    </div>
                                                    <div className="position-absolute end-0 top-0 p-2 z-index-1 ">
                                                        <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-white bg-danger">
                                                            <i className="bi bi-heart size-10 vm" />
                                                        </button>
                                                    </div>
                                                    <figure className="avatar w-100 rounded-15 border">
                                                        <img
                                                            src="assets/img/categories2.png"
                                                            alt=""
                                                            className="w-100"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="card-body pt-2">
                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="small">
                                                                <small className="text-muted size-12">
                                                                    Clothing
                                                                </small>
                                                                <br />$ 265.00
                                                            </p>
                                                        </div>
                                                        <div className="col-auto px-0">
                                                            <button className="btn btn-sm btn-link text-color-theme">
                                                                <i className="bi bi-bag-plus" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-3">
                                            <div className="card mb-3">
                                                <div className="card-body p-1 position-relative">
                                                    <div className="position-absolute start-0 top-0 m-2 z-index-1">
                                                        <div className="tag tag-small bg-warning text-white">
                                                            1 Offer
                                                        </div>
                                                    </div>
                                                    <div className="position-absolute end-0 top-0 p-2 z-index-1 ">
                                                        <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-danger">
                                                            <i className="bi bi-heart size-10 vm" />
                                                        </button>
                                                    </div>
                                                    <figure className="avatar w-100 rounded-15 border">
                                                        <img
                                                            src="assets/img/categories3.png"
                                                            alt=""
                                                            className="w-100"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="card-body pt-2">
                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="small">
                                                                <small className="text-muted size-12">
                                                                    Wash.Machine
                                                                </small>
                                                                <br />$ 254.00
                                                            </p>
                                                        </div>
                                                        <div className="col-auto px-0">
                                                            <button className="btn btn-sm btn-link text-color-theme">
                                                                <i className="bi bi-bag-plus" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-3">
                                            <div className="card mb-3">
                                                <div className="card-body p-1 position-relative">
                                                    <div className="position-absolute start-0 top-0 m-2 z-index-1">
                                                        <div className="tag tag-small bg-theme">26% OFF</div>
                                                    </div>
                                                    <div className="position-absolute end-0 top-0 p-2 z-index-1 ">
                                                        <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-white bg-danger">
                                                            <i className="bi bi-heart size-10 vm" />
                                                        </button>
                                                    </div>
                                                    <figure className="avatar w-100 rounded-15 border">
                                                        <img
                                                            src="assets/img/categories4.png"
                                                            alt=""
                                                            className="w-100"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="card-body pt-2">
                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="small">
                                                                <small className="text-muted size-12">
                                                                    LAVA B500
                                                                </small>
                                                                <br />$ 265.00
                                                            </p>
                                                        </div>
                                                        <div className="col-auto px-0">
                                                            <button className="btn btn-sm btn-link text-color-theme">
                                                                <i className="bi bi-bag-plus" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-3">
                                            <div className="card mb-3">
                                                <div className="card-body p-1 position-relative">
                                                    <div className="position-absolute start-0 top-0 m-2 z-index-1">
                                                        <div className="tag tag-small bg-theme">30% OFF</div>
                                                    </div>
                                                    <div className="position-absolute end-0 top-0 p-2 z-index-1 ">
                                                        <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-danger">
                                                            <i className="bi bi-heart size-10 vm" />
                                                        </button>
                                                    </div>
                                                    <figure className="avatar w-100 rounded-15 border">
                                                        <img
                                                            src="assets/img/categories1.png"
                                                            alt=""
                                                            className="w-100"
                                                        />
                                                    </figure>
                                                </div>
                                                <div className="card-body pt-2">
                                                    <div className="row">
                                                        <div className="col">
                                                            <p className="small">
                                                                <small className="text-muted size-12">
                                                                    Smasung S500
                                                                </small>
                                                                <br />$ 459.00
                                                            </p>
                                                        </div>
                                                        <div className="col-auto px-0">
                                                            <button className="btn btn-sm btn-link text-color-theme">
                                                                <i className="bi bi-bag-plus" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* product medsize box no gap */}
                                    <div className="row mb-4 g-0 ">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body p-0">
                                                    <div className="row mx-0">
                                                        <div className="col-6 col-md-4 col-lg-3 border-end p-3">
                                                            <div className="position-relative">
                                                                <div className="position-absolute start-0 top-0 z-index-1">
                                                                    <div className="tag tag-small bg-theme">
                                                                        26% OFF
                                                                    </div>
                                                                </div>
                                                                <div className="position-absolute end-0 top-0 z-index-1 ">
                                                                    <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-danger">
                                                                        <i className="bi bi-heart size-10 vm" />
                                                                    </button>
                                                                </div>
                                                                <figure className="avatar w-100 rounded-15">
                                                                    <img
                                                                        src="assets/img/categories4.png"
                                                                        alt=""
                                                                        className="w-100"
                                                                    />
                                                                </figure>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <p className="small">
                                                                            <small className="text-muted size-12">
                                                                                LAVA B500
                                                                            </small>
                                                                            <br />$ 265.00
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-auto px-0">
                                                                        <button className="btn btn-sm btn-link text-color-theme">
                                                                            <i className="bi bi-bag-plus" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-4 col-lg-3 p-3">
                                                            <div className="position-relative">
                                                                <div className="position-absolute start-0 top-0  z-index-1">
                                                                    <div className="tag tag-small bg-theme">
                                                                        30% OFF
                                                                    </div>
                                                                </div>
                                                                <div className="position-absolute end-0 top-0  z-index-1 ">
                                                                    <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-danger">
                                                                        <i className="bi bi-heart size-10 vm" />
                                                                    </button>
                                                                </div>
                                                                <figure className="avatar w-100 rounded-15">
                                                                    <img
                                                                        src="assets/img/categories1.png"
                                                                        alt=""
                                                                        className="w-100"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <p className="small">
                                                                        <small className="text-muted size-12">
                                                                            Smasung S500
                                                                        </small>
                                                                        <br />$ 459.00
                                                                    </p>
                                                                </div>
                                                                <div className="col-auto px-0">
                                                                    <button className="btn btn-sm btn-link text-color-theme">
                                                                        <i className="bi bi-bag-plus" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-4 col-lg-3 border-end p-3 border-top">
                                                            <div className="position-relative">
                                                                <div className="position-absolute start-0 top-0 z-index-1">
                                                                    <div className="tag tag-small bg-danger text-white">
                                                                        SALE
                                                                    </div>
                                                                </div>
                                                                <div className="position-absolute end-0 top-0 z-index-1 ">
                                                                    <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-white bg-danger">
                                                                        <i className="bi bi-heart size-10 vm" />
                                                                    </button>
                                                                </div>
                                                                <figure className="avatar w-100 rounded-15">
                                                                    <img
                                                                        src="assets/img/categories2.png"
                                                                        alt=""
                                                                        className="w-100"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <p className="small">
                                                                        <small className="text-muted size-12">
                                                                            Clothing
                                                                        </small>
                                                                        <br />$ 65.00
                                                                    </p>
                                                                </div>
                                                                <div className="col-auto px-0">
                                                                    <button className="btn btn-sm btn-link text-color-theme">
                                                                        <i className="bi bi-bag-plus" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-4 col-lg-3 p-3 border-top">
                                                            <div className="position-relative">
                                                                <div className="position-absolute start-0 top-0 z-index-1">
                                                                    <div className="tag tag-small bg-warning text-white">
                                                                        1 Offer
                                                                    </div>
                                                                </div>
                                                                <div className="position-absolute end-0 top-0 z-index-1 ">
                                                                    <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-danger">
                                                                        <i className="bi bi-heart size-10 vm" />
                                                                    </button>
                                                                </div>
                                                                <figure className="avatar w-100 rounded-15">
                                                                    <img
                                                                        src="assets/img/categories3.png"
                                                                        alt=""
                                                                        className="w-100"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <p className="small">
                                                                        <small className="text-muted size-12">
                                                                            Wash.Machine
                                                                        </small>
                                                                        <br />$ 254.00
                                                                    </p>
                                                                </div>
                                                                <div className="col-auto px-0">
                                                                    <button className="btn btn-sm btn-link text-color-theme">
                                                                        <i className="bi bi-bag-plus" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-4 col-lg-3 border-end p-3 border-top">
                                                            <div className="position-relative">
                                                                <div className="position-absolute start-0 top-0 z-index-1">
                                                                    <div className="tag tag-small bg-theme">
                                                                        26% OFF
                                                                    </div>
                                                                </div>
                                                                <div className="position-absolute end-0 top-0 z-index-1 ">
                                                                    <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-danger">
                                                                        <i className="bi bi-heart size-10 vm" />
                                                                    </button>
                                                                </div>
                                                                <figure className="avatar w-100 rounded-15">
                                                                    <img
                                                                        src="assets/img/categories4.png"
                                                                        alt=""
                                                                        className="w-100"
                                                                    />
                                                                </figure>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <p className="small">
                                                                            <small className="text-muted size-12">
                                                                                LAVA B500
                                                                            </small>
                                                                            <br />$ 265.00
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-auto px-0">
                                                                        <button className="btn btn-sm btn-link text-color-theme">
                                                                            <i className="bi bi-bag-plus" />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-md-4 col-lg-3 p-3 border-top">
                                                            <div className="position-relative">
                                                                <div className="position-absolute start-0 top-0  z-index-1">
                                                                    <div className="tag tag-small bg-theme">
                                                                        30% OFF
                                                                    </div>
                                                                </div>
                                                                <div className="position-absolute end-0 top-0  z-index-1 ">
                                                                    <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-danger">
                                                                        <i className="bi bi-heart size-10 vm" />
                                                                    </button>
                                                                </div>
                                                                <figure className="avatar w-100 rounded-15">
                                                                    <img
                                                                        src="assets/img/categories1.png"
                                                                        alt=""
                                                                        className="w-100"
                                                                    />
                                                                </figure>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <p className="small">
                                                                        <small className="text-muted size-12">
                                                                            Smasung S500
                                                                        </small>
                                                                        <br />$ 459.00
                                                                    </p>
                                                                </div>
                                                                <div className="col-auto px-0">
                                                                    <button className="btn btn-sm btn-link text-color-theme">
                                                                        <i className="bi bi-bag-plus" />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-12 text-center">
                                            <button className="btn btn-light shadow-sm text-color-theme">
                                                Load more...
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="nav-lists"
                                    role="tabpanel"
                                    aria-labelledby="nav-lists-tab"
                                >
                                    {/* product medsize list  */}
                                    <div className="row mb-2">
                                        <div className="col-12 col-md-6 ">
                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-auto position-relative">
                                                            <div className="position-absolute end-0 top-0 px-2 py-1 mx-2 z-index-1 ">
                                                                <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-white bg-danger">
                                                                    <i className="bi bi-heart size-10 vm" />
                                                                </button>
                                                            </div>
                                                            <figure className="avatar avatar-80 rounded-15 border">
                                                                <img
                                                                    src="assets/img/categories4.png"
                                                                    alt=""
                                                                    className="w-100"
                                                                />
                                                            </figure>
                                                        </div>
                                                        <div className="col position-relative">
                                                            <div className="position-absolute end-0 top-0 z-index-1 ">
                                                                <div className="tag tag-small bg-theme me-2">
                                                                    26% OFF
                                                                </div>
                                                            </div>
                                                            <p className="mb-0">
                                                                <small className="text-muted size-12">
                                                                    LAVA B500
                                                                </small>
                                                            </p>
                                                            <h5>$ 265.00</h5>
                                                            <button className="btn btn-sm px-0 text-color-theme">
                                                                <i className="bi bi-bag-plus" /> Add to Cart
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 ">
                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-auto position-relative">
                                                            <div className="position-absolute end-0 top-0 px-2 py-1 mx-2 z-index-1 ">
                                                                <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-white bg-danger">
                                                                    <i className="bi bi-heart size-10 vm" />
                                                                </button>
                                                            </div>
                                                            <figure className="avatar avatar-80 rounded-15 border">
                                                                <img
                                                                    src="assets/img/categories1.png"
                                                                    alt=""
                                                                    className="w-100"
                                                                />
                                                            </figure>
                                                        </div>
                                                        <div className="col position-relative">
                                                            <div className="position-absolute end-0 top-0 z-index-1 ">
                                                                <div className="tag tag-small bg-theme me-2">
                                                                    16% OFF
                                                                </div>
                                                            </div>
                                                            <p className="mb-0">
                                                                <small className="text-muted size-12">
                                                                    Samsug TV SM5500
                                                                </small>
                                                            </p>
                                                            <h5>$ 165.00</h5>
                                                            <button className="btn btn-sm px-0 text-color-theme">
                                                                <i className="bi bi-bag-plus" /> Add to Cart
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 ">
                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-auto position-relative">
                                                            <div className="position-absolute end-0 top-0 px-2 py-1 mx-2 z-index-1 ">
                                                                <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-danger">
                                                                    <i className="bi bi-heart size-10 vm" />
                                                                </button>
                                                            </div>
                                                            <figure className="avatar avatar-80 rounded-15 border">
                                                                <img
                                                                    src="assets/img/categories2.png"
                                                                    alt=""
                                                                    className="w-100"
                                                                />
                                                            </figure>
                                                        </div>
                                                        <div className="col position-relative">
                                                            <div className="position-absolute end-0 top-0 z-index-1 ">
                                                                <div className="tag tag-small bg-danger text-white me-2">
                                                                    On Sale
                                                                </div>
                                                            </div>
                                                            <p className="mb-0">
                                                                <small className="text-muted size-12">
                                                                    Top Cloths
                                                                </small>
                                                            </p>
                                                            <h5>$ 59.00</h5>
                                                            <button className="btn btn-sm px-0 text-color-theme">
                                                                <i className="bi bi-bag-plus" /> Add to Cart
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 ">
                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-auto position-relative">
                                                            <div className="position-absolute end-0 top-0 px-2 py-1 mx-2 z-index-1 ">
                                                                <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-danger">
                                                                    <i className="bi bi-heart size-10 vm" />
                                                                </button>
                                                            </div>
                                                            <figure className="avatar avatar-80 rounded-15 border">
                                                                <img
                                                                    src="assets/img/categories3.png"
                                                                    alt=""
                                                                    className="w-100"
                                                                />
                                                            </figure>
                                                        </div>
                                                        <div className="col position-relative">
                                                            <div className="position-absolute end-0 top-0 z-index-1 ">
                                                                <div className="tag tag-small bg-warning text-white me-2">
                                                                    1 Offer
                                                                </div>
                                                            </div>
                                                            <p className="mb-0">
                                                                <small className="text-muted size-12">
                                                                    Washing Machine
                                                                </small>
                                                            </p>
                                                            <h5>$ 265.00</h5>
                                                            <button className="btn btn-sm px-0 text-color-theme">
                                                                <i className="bi bi-bag-plus" /> Add to Cart
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 ">
                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-auto position-relative">
                                                            <div className="position-absolute end-0 top-0 px-2 py-1 mx-2 z-index-1 ">
                                                                <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-white bg-danger">
                                                                    <i className="bi bi-heart size-10 vm" />
                                                                </button>
                                                            </div>
                                                            <figure className="avatar avatar-80 rounded-15 border">
                                                                <img
                                                                    src="assets/img/categories4.png"
                                                                    alt=""
                                                                    className="w-100"
                                                                />
                                                            </figure>
                                                        </div>
                                                        <div className="col position-relative">
                                                            <div className="position-absolute end-0 top-0 z-index-1 ">
                                                                <div className="tag tag-small bg-theme me-2">
                                                                    26% OFF
                                                                </div>
                                                            </div>
                                                            <p className="mb-0">
                                                                <small className="text-muted size-12">
                                                                    LAVA B500
                                                                </small>
                                                            </p>
                                                            <h5>$ 265.00</h5>
                                                            <button className="btn btn-sm px-0 text-color-theme">
                                                                <i className="bi bi-bag-plus" /> Add to Cart
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 ">
                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-auto position-relative">
                                                            <div className="position-absolute end-0 top-0 px-2 py-1 mx-2 z-index-1 ">
                                                                <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-white bg-danger">
                                                                    <i className="bi bi-heart size-10 vm" />
                                                                </button>
                                                            </div>
                                                            <figure className="avatar avatar-80 rounded-15 border">
                                                                <img
                                                                    src="assets/img/categories1.png"
                                                                    alt=""
                                                                    className="w-100"
                                                                />
                                                            </figure>
                                                        </div>
                                                        <div className="col position-relative">
                                                            <div className="position-absolute end-0 top-0 z-index-1 ">
                                                                <div className="tag tag-small bg-theme me-2">
                                                                    16% OFF
                                                                </div>
                                                            </div>
                                                            <p className="mb-0">
                                                                <small className="text-muted size-12">
                                                                    Samsug TV SM5500
                                                                </small>
                                                            </p>
                                                            <h5>$ 165.00</h5>
                                                            <button className="btn btn-sm px-0 text-color-theme">
                                                                <i className="bi bi-bag-plus" /> Add to Cart
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 ">
                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-auto position-relative">
                                                            <div className="position-absolute end-0 top-0 px-2 py-1 mx-2 z-index-1 ">
                                                                <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-danger">
                                                                    <i className="bi bi-heart size-10 vm" />
                                                                </button>
                                                            </div>
                                                            <figure className="avatar avatar-80 rounded-15 border">
                                                                <img
                                                                    src="assets/img/categories2.png"
                                                                    alt=""
                                                                    className="w-100"
                                                                />
                                                            </figure>
                                                        </div>
                                                        <div className="col position-relative">
                                                            <div className="position-absolute end-0 top-0 z-index-1 ">
                                                                <div className="tag tag-small bg-danger text-white me-2">
                                                                    On Sale
                                                                </div>
                                                            </div>
                                                            <p className="mb-0">
                                                                <small className="text-muted size-12">
                                                                    Top Cloths
                                                                </small>
                                                            </p>
                                                            <h5>$ 59.00</h5>
                                                            <button className="btn btn-sm px-0 text-color-theme">
                                                                <i className="bi bi-bag-plus" /> Add to Cart
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 ">
                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-auto position-relative">
                                                            <div className="position-absolute end-0 top-0 px-2 py-1 mx-2 z-index-1 ">
                                                                <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-danger">
                                                                    <i className="bi bi-heart size-10 vm" />
                                                                </button>
                                                            </div>
                                                            <figure className="avatar avatar-80 rounded-15 border">
                                                                <img
                                                                    src="assets/img/categories3.png"
                                                                    alt=""
                                                                    className="w-100"
                                                                />
                                                            </figure>
                                                        </div>
                                                        <div className="col position-relative">
                                                            <div className="position-absolute end-0 top-0 z-index-1 ">
                                                                <div className="tag tag-small bg-warning text-white me-2">
                                                                    1 Offer
                                                                </div>
                                                            </div>
                                                            <p className="mb-0">
                                                                <small className="text-muted size-12">
                                                                    Washing Machine
                                                                </small>
                                                            </p>
                                                            <h5>$ 265.00</h5>
                                                            <button className="btn btn-sm px-0 text-color-theme">
                                                                <i className="bi bi-bag-plus" /> Add to Cart
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* main page content ends */}
            </main>
            {/* Page ends*/}
        </>

    )
}

export default Products