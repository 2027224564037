import React from 'react'

function Userlist() {
    return (
        <>
            {/* loader section */}
            
            {/* loader section ends */}
            {/* Begin page */}
            <main className="h-100">
                {/* Header */}
                <header className="header position-fixed">
                    <div className="row">
                        <div className="col-auto">
                            <button type="button" className="btn btn-light btn-44 back-btn">
                                <i className="bi bi-arrow-left" />
                            </button>
                        </div>
                        <div className="col text-center">
                                <div className="logo-small">
                                   <a href='/'> <img src="assets/img/logo.png" alt="" />
                                    <h5>
                                        <span className="text-secondary fw-light">Finance</span>
                                        <br />
                                        Wallet
                                    </h5>
                                    </a>
                                </div>
                            </div>
                        <div className="col-auto">
                            <a
                                href="profile.html"
                                target="_self"
                                className="btn btn-light btn-44"
                            >
                                <i className="bi bi-person-circle" />
                                <span className="count-indicator" />
                            </a>
                        </div>
                    </div>
                </header>
                {/* Header ends */}
                {/* main page content */}
                <div className="main-container container">
                    {/* user block */}
                    <div className="row">
                        <div className="col-6 col-sm-4 col-md-3">
                            <div className="card mb-4 shadow-sm">
                                <div className="card-body text-center">
                                    <div className="row align-items-center ">
                                        <a href="profile.html " className="col-auto mx-auto">
                                            <div className="card mb-3">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-80 mx-auto rounded-15">
                                                        <img src="assets/img/user1.jpg" alt="" />
                                                    </figure>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <p className="mb-0 text-color-theme text-center">John McMohan</p>
                                    <p className="text-small text-secondary small">Chief Designer</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3">
                            <div className="card mb-4 shadow-sm">
                                <div className="card-body text-center">
                                    <div className="row align-items-center ">
                                        <a href="profile.html " className="col-auto mx-auto">
                                            <div className="card mb-3">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-80 mx-auto rounded-15">
                                                        <img src="assets/img/user2.jpg" alt="" />
                                                    </figure>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <p className="mb-0 text-color-theme text-center">
                                        Alizee D'Souza
                                    </p>
                                    <p className="text-small text-secondary small">Lead Designer</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3">
                            <div className="card mb-4 shadow-sm p-2 bg-theme text-dark">
                                <div className="card-body text-center bg-white rounded-15 shadow-sm">
                                    <div className="row align-items-center ">
                                        <a href="profile.html " className="col-auto mx-auto">
                                            <div className="card mb-3">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-80 mx-auto rounded-15">
                                                        <img src="assets/img/user3.jpg" alt="" />
                                                    </figure>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <p className="mb-0 text-color-theme text-center">Selvy Arihant</p>
                                    <p className="text-small text-secondary small">Marketting Lead</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3">
                            <div className="card mb-4 shadow-sm p-2 bg-theme text-dark">
                                <div className="card-body text-center bg-white rounded-15 shadow-sm">
                                    <div className="row align-items-center ">
                                        <a href="profile.html " className="col-auto mx-auto">
                                            <div className="card mb-3">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-80 mx-auto rounded-15">
                                                        <img src="assets/img/user4.jpg" alt="" />
                                                    </figure>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <p className="mb-0 text-color-theme text-center">Ahaan D'Souza</p>
                                    <p className="text-small text-secondary small">React Native</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3">
                            <div className="card mb-4 shadow-sm p-2 bg-theme text-dark">
                                <div className="card-body text-center bg-white rounded-15 shadow-sm">
                                    <div className="row align-items-center ">
                                        <a href="profile.html " className="col-auto mx-auto">
                                            <div className="card mb-3">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-80 mx-auto rounded-15">
                                                        <img src="assets/img/user2.jpg" alt="" />
                                                    </figure>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <p className="mb-0 text-color-theme text-center">Jeny Roy</p>
                                </div>
                                <div className="card-footer border-0 text-center">
                                    <p className="text-small text-muted small">UX Designer</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-4 col-md-3">
                            <div className="card mb-4 shadow-sm p-2 bg-theme text-dark">
                                <div className="card-body text-center bg-white rounded-15 shadow-sm">
                                    <div className="row align-items-center ">
                                        <a href="profile.html " className="col-auto mx-auto">
                                            <div className="card mb-3">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-80 mx-auto rounded-15">
                                                        <img src="assets/img/user1.jpg" alt="" />
                                                    </figure>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <p className="mb-0 text-color-theme text-center">Joy Arihant</p>
                                </div>
                                <div className="card-footer border-0 text-center">
                                    <p className="text-small text-muted small">UX Designer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* User list items  */}
                    <div className="row">
                        <div className="col-12">
                            <div className="card shadow-sm mb-4 ">
                                <ul className="list-group list-group-flush bg-none">
                                    <li className="list-group-item border-0">
                                        <div className="row">
                                            <div className="col-auto">
                                                <div className="card">
                                                    <div className="card-body p-1">
                                                        <figure className="avatar avatar-44 rounded-15">
                                                            <img src="assets/img/user1.jpg" alt="" />
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <p>
                                                    Ajinkya McMohan
                                                    <br />
                                                    <small className="text-secondary">
                                                        Jr. Software Engineer
                                                    </small>
                                                </p>
                                            </div>
                                            <div className="col-auto text-end">
                                                <p>
                                                    <small className="text-secondary">
                                                        Online{" "}
                                                        <span className="avatar avatar-6 rounded-circle bg-success d-inline-block" />
                                                    </small>
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item border-0">
                                        <div className="row">
                                            <div className="col-auto">
                                                <div className="card">
                                                    <div className="card-body p-1">
                                                        <figure className="avatar avatar-44 rounded-15">
                                                            <img src="assets/img/user2.jpg" alt="" />
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col px-0">
                                                <p>
                                                    Ajinkya McMohan
                                                    <br />
                                                    <small className="text-secondary">Project Manager</small>
                                                </p>
                                            </div>
                                            <div className="col-auto text-end">
                                                <p>
                                                    <small className="text-secondary">
                                                        Online{" "}
                                                        <span className="avatar avatar-6 rounded-circle bg-success d-inline-block" />
                                                    </small>
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item border-0">
                                        <div className="row">
                                            <div className="col-auto">
                                                <div className="card">
                                                    <div className="card-body p-1">
                                                        <figure className="avatar avatar-44 rounded-15">
                                                            <img src="assets/img/user3.jpg" alt="" />
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col px-0">
                                                <p>
                                                    Ajinkya McMohan
                                                    <br />
                                                    <small className="text-secondary">Lead UX Designer</small>
                                                </p>
                                            </div>
                                            <div className="col-auto text-end">
                                                <p>
                                                    <small className="text-secondary">
                                                        Online{" "}
                                                        <span className="avatar avatar-6 rounded-circle bg-success d-inline-block" />
                                                    </small>
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item border-0">
                                        <div className="row">
                                            <div className="col-auto">
                                                <div className="card">
                                                    <div className="card-body p-1">
                                                        <figure className="avatar avatar-44 rounded-15">
                                                            <img src="assets/img/user1.jpg" alt="" />
                                                        </figure>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col px-0">
                                                <p>
                                                    Ajinkya McMohan
                                                    <br />
                                                    <small className="text-secondary">
                                                        Sr. Marketing Head
                                                    </small>
                                                </p>
                                            </div>
                                            <div className="col-auto text-end">
                                                <p>
                                                    <small className="text-secondary">
                                                        Online{" "}
                                                        <span className="avatar avatar-6 rounded-circle bg-success d-inline-block" />
                                                    </small>
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                            <div className="card mb-4 shadow-sm p-2">
                                <div className="card-body text-center shadow-sm rounded-15">
                                    <div className="row align-items-center ">
                                        <a href="profile.html " className="col-auto mx-auto">
                                            <div className="card mb-3">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-100 mx-auto rounded-15">
                                                        <img src="assets/img/user1.jpg" alt="" />
                                                    </figure>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <h6 className="card-title mb-0 text-color-theme text-center">
                                        John McMohan
                                    </h6>
                                    <p className="text-small text-secondary small">Chief Designer</p>
                                    <p>
                                        Lorem ipsum dolor sit amet, consec tetur adipisicing elit, sed
                                        eiusmod exercitation ullamco.
                                    </p>
                                </div>
                                <div className="card-footer text-center border-0">
                                    <a href="" className="text-secondary mx-2">
                                        <i className="bi bi-facebook" />
                                    </a>
                                    <a href="" className="text-secondary mx-2">
                                        <i className="bi bi-twitter" />
                                    </a>
                                    <a href="" className="text-secondary mx-2">
                                        <i className="bi bi-linkedin" />
                                    </a>
                                    <a href="" className="text-secondary mx-2">
                                        <i className="bi bi-envelope" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                            <div className="card mb-4 shadow-sm p-2 bg-theme">
                                <div className="card-body text-center shadow-sm rounded-15 bg-white text-dark ">
                                    <div className="row align-items-center ">
                                        <a href="profile.html " className="col-auto mx-auto">
                                            <div className="card mb-3">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-100 mx-auto rounded-15">
                                                        <img src="assets/img/user2.jpg" alt="" />
                                                    </figure>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <h6 className="card-title mb-0 text-color-theme text-center">
                                        Alizee D'Souza
                                    </h6>
                                    <p className="text-small text-secondary small">Lead Designer</p>
                                    <p>
                                        Lorem ipsum dolor sit amet, consec tetur adipisicing elit, sed
                                        eiusmod exercitation ullamco.
                                    </p>
                                </div>
                                <div className="card-footer text-center border-0">
                                    <a href="" className="text-white mx-2">
                                        <i className="bi bi-facebook" />
                                    </a>
                                    <a href="" className="text-white mx-2">
                                        <i className="bi bi-twitter" />
                                    </a>
                                    <a href="" className="text-white mx-2">
                                        <i className="bi bi-linkedin" />
                                    </a>
                                    <a href="" className="text-white mx-2">
                                        <i className="bi bi-envelope" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                            <div className="card mb-4 shadow-sm p-2">
                                <div className="card-body text-center shadow-sm rounded-15">
                                    <div className="row align-items-center ">
                                        <a href="profile.html " className="col-auto mx-auto">
                                            <div className="card mb-3">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-100 mx-auto rounded-15">
                                                        <img src="assets/img/user3.jpg" alt="" />
                                                    </figure>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <h6 className="card-title mb-0 text-color-theme text-center">
                                        Joy Arihant
                                    </h6>
                                    <p className="text-small text-secondary small">UX Designer</p>
                                    <p>
                                        Lorem ipsum dolor sit amet, consec tetur adipisicing elit, sed
                                        eiusmod exercitation ullamco.
                                    </p>
                                </div>
                                <div className="card-footer text-center border-0">
                                    <a href="" className="text-secondary mx-2">
                                        <i className="bi bi-facebook" />
                                    </a>
                                    <a href="" className="text-secondary mx-2">
                                        <i className="bi bi-twitter" />
                                    </a>
                                    <a href="" className="text-secondary mx-2">
                                        <i className="bi bi-linkedin" />
                                    </a>
                                    <a href="" className="text-secondary mx-2">
                                        <i className="bi bi-envelope" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* main page content ends */}
            </main>
            {/* Page ends*/}
        </>

    )
}

export default Userlist