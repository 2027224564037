import React from 'react'

function Verify() {
    return (
        <>
            {/* loader section */}

            {/* loader section ends */}
            {/* Begin page content */}
            <main className="container-fluid h-100 ">
                <div className="row h-100">
                    <div className="col-11 col-sm-11 mx-auto">
                        {/* header */}
                        <div className="row">
                            <header className="header">
                                <div className="row">
                                    <div className="col">
                                         <div className="logo-small">
                                <a href='/'> <img src="assets/img/logo.png" alt="" />
                                    <h5>
                                        <span className="text-secondary fw-light">Finance</span>
                                        <br />
                                        Wallet
                                    </h5>
                                </a>
                            </div>
                                    </div>
                                    <div className="col-auto align-self-center">
                                        <a href="/signin">Sing in</a>
                                    </div>
                                </div>
                            </header>
                        </div>
                        {/* header ends */}
                    </div>
                    <div className="col-11 col-sm-11 col-md-6 col-lg-5 col-xl-3 mx-auto align-self-center py-4">
                        <h1 className="mb-3">
                            <span className="text-secondary fw-light">Verify your</span>
                            <br />
                            Email ID
                        </h1>
                        <p className="text-secondary mb-4">
                            We have send you an email with OTP with instruction.
                        </p>
                        <div className="form-group form-floating mb-3 is-valid">
                            <input
                                type="text"
                                className="form-control"
                                defaultValue="maxartkiller"
                                id="OTP"
                                placeholder="Enter OTP"
                            />
                            <label className="form-control-label" htmlFor="OTP">
                                Enter option
                            </label>
                        </div>
                    </div>
                    <div className="col-11 col-sm-11 mt-auto mx-auto py-4">
                        <div className="row ">
                            <div className="col-auto text-center mx-auto">
                                <span className="progressstimer">
                                    <img src="assets/img/progress.png" alt="" />
                                    <span className="timer" id="timer">
                                        3:00
                                    </span>
                                </span>
                                <br />
                                <p className="mb-3">
                                    <span className="text-muted">Didn't received yet?</span>{" "}
                                    <a href="">Resend OTP</a>
                                </p>
                            </div>
                            <div className="col-12 d-grid">
                                <a
                                    href="/thankyou"
                                    className="btn btn-default btn-lg shadow-sm"
                                >
                                    Verify Account
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>

    )
}

export default Verify