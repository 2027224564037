import React from 'react'

function Blogdetails() {
  return (
    <>
  {/* loader section */}

  {/* loader section ends */}
  {/* Begin page */}
  <main className="h-100">
    {/* Header */}
    <header className="header position-fixed">
      <div className="row">
        <div className="col-auto">
          <button type="button" className="btn btn-light btn-44 back-btn">
            <i className="bi bi-arrow-left" />
          </button>
        </div>
        <div className="col text-center">
          <div className="logo-small">
            <img src="assets/img/logo.png" alt="" />
            <h5>
              <span className="text-secondary fw-light">Finance</span>
              <br />
              Wallet
            </h5>
          </div>
        </div>
        <div className="col-auto">
          <a
            href="/profile"
            target="_self"
            className="btn btn-light btn-44"
          >
            <i className="bi bi-person-circle" />
            <span className="count-indicator" />
          </a>
        </div>
      </div>
    </header>
    {/* Header ends */}
    {/* main page content */}
    <div className="main-container container top-20">
      {/* Blog/News Details banner */}
      <div className="row">
        <div className="col-12 px-0">
          <div className="card mb-4 overflow-hidden shadow-sm card-theme text-white rounded-0">
            <div className="overlay" />
            <div className="coverimg h-100 w-100 position-absolute opacity-3">
              <img src="assets/img/news1.jpg" alt="" />
            </div>
            <div className="card-body">
              <div className="row mb-5">
                <div className="col">
                  <span className="tag bg-theme">Trending</span>
                </div>
                <div className="col-auto">
                  <button className="btn btn-info text-white avatar avatar-40 p-0 rounded-circle shadow-sm">
                    <i className="bi bi-share" />
                  </button>
                  <button className="btn btn-success text-white avatar avatar-40 p-0 rounded-circle shadow-sm">
                    <i className="bi bi-bookmark" />
                  </button>
                </div>
              </div>
              <br />
              <a
                href="/blogdetails"
                className="h4 mb-1 text-normal d-block text-white"
              >
                Best Discovery ever in UX
              </a>
              <p className="mb-3">Published on: 26/12/2021</p>
              <div className="d-flex">
                <figure className="avatar avatar-40 rounded-15">
                  <img src="assets/img/user2.jpg" alt="" />
                </figure>
                <p className="mx-2 lh-small align-self-center">
                  Alessia Monks
                  <br />
                  <span className="small">Author</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Blogs/News Content  */}
      <div className="row">
        <div className="col-12 col-md-10 col-lg-8 mx-auto">
          <p className="text-secondary">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p>
          <figure className="overflow-hidden rounded-15 text-center">
            <img src="assets/img/news3.jpg" alt="" className="mw-100 mx-auto" />
          </figure>
          <h5 className="mb-3">Best class template with details</h5>
          <p className="text-secondary">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p>
          <div className="row">
            <div className="col-12">
              <figure className="overflow-hidden rounded-15 text-center">
                <img
                  src="assets/img/news1.jpg"
                  alt=""
                  className="w-100 mx-auto"
                />
              </figure>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-6 mb-3">
                  <figure className="overflow-hidden rounded-15 text-center">
                    <img
                      src="assets/img/news2.jpg"
                      alt=""
                      className="w-100 mx-auto"
                    />
                  </figure>
                </div>
                <div className="col-6">
                  <figure className="overflow-hidden rounded-15 text-center">
                    <img
                      src="assets/img/news3.jpg"
                      alt=""
                      className="w-100 mx-auto"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
          <h3>Do share</h3>
          <h5 className="mb-3 text-secondary">Help us to get more</h5>
          <p className="text-secondary">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p>
          <div className="card mb-4 overflow-hidden shadow-sm card-theme text-white">
            <div className="overlay" />
            <div className="coverimg h-100 w-100 position-absolute opacity-3">
              <img src="assets/img/news1.jpg" alt="" />
            </div>
            <div className="card-body">
              <br />
              <br />
              <br />
              <a
                href="/blogdetails"
                className="h5 text-normal d-block text-white"
              >
                Best Discovery ever in UX
              </a>
              <p className="small">December 2021</p>
              <div className="small d-flex">
                <figure className="avatar avatar-36 rounded-15">
                  <img src="assets/img/user2.jpg" alt="" />
                </figure>
                <p className="mx-2">
                  Alessia Monks
                  <br />
                  <span className="text-muted">Author</span>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="card mb-4 overflow-hidden shadow-sm card-theme text-white">
                <div className="overlay" />
                <div className="coverimg h-100 w-100 position-absolute opacity-3">
                  <img src="assets/img/news3.jpg" alt="" />
                </div>
                <div className="card-body">
                  <br />
                  <br />
                  <br />
                  <a
                    href="/blogdetails"
                    className="h6 text-normal d-block text-white"
                  >
                    Best Discovery ever in UX
                  </a>
                  <p className="small">Upcoming</p>
                  <div className="small d-flex">
                    <figure className="avatar avatar-20 rounded-10">
                      <img src="assets/img/user2.jpg" alt="" />
                    </figure>
                    <p className="mx-2">Alessia Monks</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="card mb-4 overflow-hidden shadow-sm card-theme  text-white">
                <div className="overlay" />
                <div className="coverimg h-100 w-100 position-absolute opacity-3">
                  <img src="assets/img/news1.jpg" alt="" />
                </div>
                <div className="card-body">
                  <br />
                  <br />
                  <br />
                  <a
                    href="/blogdetails"
                    className="h6 text-normal d-block text-white"
                  >
                    Best Discovery ever in UX
                  </a>
                  <p className="small">Published </p>
                  <div className="small d-flex">
                    <figure className="avatar avatar-20 rounded-10">
                      <img src="assets/img/user2.jpg" alt="" />
                    </figure>
                    <p className="mx-2">Alessia Monks</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h5 className="mb-3">Help us to get more</h5>
          <p className="text-secondary mb-4">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p>
          <div className="row mb-4">
            <div className="col-12">
              <div className="row py-3 gx-3">
                <div className="col-auto position-relative">
                  <div className="card">
                    <div className="card-body p-1">
                      <figure className="avatar avatar-50 rounded-15">
                        <img src="assets/img/user1.jpg" alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <a
                    href="/profile"
                    className="mb-0 text-normal d-block text-color-theme"
                  >
                    Ajinkya McMohan <i className="bi bi-arrow-right-short" />
                  </a>
                  <p className="mb-3">
                    Purchased
                    <span className="float-end small">
                      <i className="bi bi-star-fill text-warning" />
                      <i className="bi bi-star-fill text-warning" />
                      <i className="bi bi-star-fill text-warning" />
                      <i className="bi bi-star-fill text-warning" />
                      <i className="bi bi-star text-warning" />
                    </span>
                  </p>
                  <p className="text-secondary">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Pellentesque sollicitudin dignissim nisi, eget malesuada
                    ligula ultricies sit amet. Suspendisse efficitur ex eu est
                    placerat mattis.
                  </p>
                </div>
              </div>
              <div className="row py-3 gx-3">
                <div className="col-auto position-relative">
                  <div className="card">
                    <div className="card-body p-1">
                      <figure className="avatar avatar-50 rounded-15">
                        <img src="assets/img/user2.jpg" alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <a
                    href="/profile"
                    className="mb-0 text-normal d-block text-color-theme"
                  >
                    Jessica Willson <i className="bi bi-arrow-right-short" />
                  </a>
                  <p className="mb-3">
                    Purchased
                    <span className="float-end small">
                      <i className="bi bi-star-fill text-warning" />
                      <i className="bi bi-star-fill text-warning" />
                      <i className="bi bi-star text-warning" />
                      <i className="bi bi-star text-warning" />
                      <i className="bi bi-star text-warning" />
                    </span>
                  </p>
                  <p className="text-secondary">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Pellentesque sollicitudin dignissim nisi, eget malesuada
                    ligula ultricies sit amet. Suspendisse efficitur ex eu est
                    placerat mattis.
                  </p>
                </div>
              </div>
              <div className="row py-3 gx-3">
                <div className="col-auto position-relative">
                  <div className="card">
                    <div className="card-body p-1">
                      <figure className="avatar avatar-50 rounded-15">
                        <img src="assets/img/user3.jpg" alt="" />
                      </figure>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <a
                    href="/profile"
                    className="mb-0 text-normal d-block text-color-theme"
                  >
                    Bhagat Chaturvedy <i className="bi bi-arrow-right-short" />
                  </a>
                  <p className="mb-3">
                    Purchased
                    <span className="float-end small">
                      <i className="bi bi-star-fill text-warning" />
                      <i className="bi bi-star-fill text-warning" />
                      <i className="bi bi-star-fill text-warning" />
                      <i className="bi bi-star text-warning" />
                      <i className="bi bi-star text-warning" />
                    </span>
                  </p>
                  <p className="text-secondary">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Pellentesque sollicitudin dignissim nisi, eget malesuada
                    ligula ultricies sit amet. Suspendisse efficitur ex eu est
                    placerat mattis.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* main page content ends */}
  </main>
  {/* Page ends*/}
</>

  )
}

export default Blogdetails