import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css"; // Import Swiper styles
import "swiper/css";

function Shop() {
    const categories = [
        {
            image: 'assets/img/categories1.png',
            title: 'Electronics',
        },
        {
            image: 'assets/img/categories2.png',
            title: 'Clothing',
        },
        {
            image: 'assets/img/categories3.png',
            title: 'Appliances',
        },
        {
            image: 'assets/img/categories4.png',
            title: 'Mobiles',
        },
        {
            image: 'assets/img/categories5.png',
            title: 'iPhone',
        },
    ];

    const products = [
        {
            image: 'assets/img/categories3.jpg',
            title: 'LAVAA 3500',
            price: '$ 459.00',
        },
        {
            image: 'assets/img/categories4.png',
            title: 'Galaxy S20',
            price: '$ 459.00',
        },
        {
            image: 'assets/img/categories5.png',
            title: 'iPhone 12SR',
            price: '$ 1012.00',
        },
        {
            image: 'assets/img/categories3.jpg',
            title: 'LAVAA 3500',
            price: '$ 459.00',
        },
        {
            image: 'assets/img/categories4.png',
            title: 'Galaxy S20',
            price: '$ 459.00',
        },
    ];


    return (
        <>
            {/* loader section */}

            {/* loader section ends */}
            {/* Sidebar main menu */}
            <div className="sidebar-wrap  sidebar-overlay">
                {/* Add pushcontent or fullmenu instead overlay */}
                <div className="closemenu text-secondary">Close Menu</div>
                <div className="sidebar ">
                    {/* user information */}
                    <div className="row my-3">
                        <div className="col-12 profile-sidebar">
                            <div className="clearfix" />
                            <div className="circle small one" />
                            <div className="circle small two" />
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 194.287 141.794"
                                className="menubg"
                            >
                                <defs>
                                    <linearGradient
                                        id="linear-gradient"
                                        x1="0.5"
                                        x2="0.5"
                                        y2={1}
                                        gradientUnits="objectBoundingBox"
                                    >
                                        <stop offset={0} stopColor="#09b2fd" />
                                        <stop offset={1} stopColor="#6b00e5" />
                                    </linearGradient>
                                </defs>
                                <path
                                    id="menubg"
                                    d="M672.935,207.064c-19.639,1.079-25.462-3.121-41.258,10.881s-24.433,41.037-49.5,34.15-14.406-16.743-50.307-29.667-32.464-19.812-16.308-41.711S500.472,130.777,531.872,117s63.631,21.369,93.913,15.363,37.084-25.959,56.686-19.794,4.27,32.859,6.213,44.729,9.5,16.186,9.5,26.113S692.573,205.985,672.935,207.064Z"
                                    transform="translate(-503.892 -111.404)"
                                    fill="url(#linear-gradient)"
                                />
                            </svg>
                            <div className="row mt-3">
                                <div className="col-auto">
                                    <figure className="avatar avatar-80 rounded-20 p-1 bg-white shadow-sm">
                                        <img src="assets/img/user1.jpg" alt="" className="rounded-18" />
                                    </figure>
                                </div>
                                <div className="col px-0 align-self-center">
                                    <h5 className="mb-2">John Winsels</h5>
                                    <p className="text-muted size-12">
                                        New York City,
                                        <br />
                                        United States
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* user emnu navigation */}
                    <div className="row">
                        <div className="col-12">
                            <ul className="nav nav-pills">
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        aria-current="page"
                                        href="/"
                                    >
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-house-door" />
                                        </div>
                                        <div className="col">Dashboard</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        href="#"
                                        role="button"
                                        aria-expanded="false"
                                    >
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-cart" />
                                        </div>
                                        <div className="col">Shop</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-down plus" />{" "}
                                            <i className="bi bi-chevron-up minus" />
                                        </div>
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <a className="dropdown-item nav-link" href="/shop">
                                                <div className="avatar avatar-40  icon">
                                                    <i className="bi bi-shop" />
                                                </div>
                                                <div className="col align-self-center">Shop home</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/products">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-box-seam" />
                                                </div>
                                                <div className="col align-self-center">All Products</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/product">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-box-seam" />
                                                </div>
                                                <div className="col align-self-center">Product</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/cart">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-bag" />
                                                </div>
                                                <div className="col align-self-center">Cart</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/myorders">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-view-list" />
                                                </div>
                                                <div className="col align-self-center">My Orders</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/payment">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-cash-stack" />
                                                </div>
                                                <div className="col align-self-center">Payment</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item nav-link" href="/invoice">
                                                <div className="avatar avatar-40 icon">
                                                    <i className="bi bi-receipt" />
                                                </div>
                                                <div className="col align-self-center">Invoice</div>
                                                <div className="arrow">
                                                    <i className="bi bi-chevron-right" />
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/chat" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-chat-text" />
                                        </div>
                                        <div className="col">Messages</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/notifications" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-bell" />
                                        </div>
                                        <div className="col">Notification</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/blog" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-newspaper" />
                                        </div>
                                        <div className="col">Blogs</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/style" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-palette" />
                                        </div>
                                        <div className="col">
                                            Style <i className="bi bi-star-fill text-warning small" />
                                        </div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/pages" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-file-earmark-text" />
                                        </div>
                                        <div className="col">
                                            Pages <span className="badge bg-info fw-light">new</span>
                                        </div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/signin" tabIndex={-1}>
                                        <div className="avatar avatar-40 icon">
                                            <i className="bi bi-box-arrow-right" />
                                        </div>
                                        <div className="col">Logout</div>
                                        <div className="arrow">
                                            <i className="bi bi-chevron-right" />
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Sidebar main menu ends */}
            {/* Begin page */}
            <main className="h-100">
                {/* Header */}
                <header className="header position-fixed">
                    <div className="row">
                        <div className="col-auto">
                            <a
                                href="javascript:void(0)"
                                target="_self"
                                className="btn btn-light btn-44 menu-btn"
                            >
                                <i className="bi bi-list" />
                            </a>
                        </div>
                        <div className="col text-center">
                            <div className="logo-small">
                                <a href='/'> <img src="assets/img/logo.png" alt="" />
                                    <h5>
                                        <span className="text-secondary fw-light">Finance</span>
                                        <br />
                                        Wallet
                                    </h5>
                                </a>
                            </div>
                        </div>
                        <div className="col-auto">
                            <a
                                href="/profile"
                                target="_self"
                                className="btn btn-light btn-44"
                            >
                                <i className="bi bi-person-circle" />
                                <span className="count-indicator" />
                            </a>
                        </div>
                    </div>
                </header>
                {/* Header ends */}
                {/* main page content */}
                <div className="main-container container">
                    {/* search */}
                    <div className="row mb-3">
                        <div className="col-12 overflow-hidden">
                            {/* input */}
                            <div className="row">
                                <div className="col position-relative align-self-center">
                                    <div className="form-group form-floating mb-3 is-valid">
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue=""
                                            id="email"
                                            placeholder="Search"
                                        />
                                        <label className="form-control-label" htmlFor="email">
                                            Search
                                        </label>
                                    </div>
                                </div>
                                <div className="col-auto align-self-center">
                                    <button className="btn btn-light btn-44 filter-btn">
                                        <i className="bi bi-filter size-22" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*categories */}
                    <div className="row mb-3">
                        <div className="col-12 px-0">
                            {/* swiper categories */}
                            {/* <div className="swiper-container connectionwiper">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide text-center">
                                            <a href="/products" className="card text-center mb-2">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-80 rounded-18">
                                                        <img src="assets/img/categories1.png" alt="" />
                                                    </figure>
                                                </div>
                                            </a>
                                            <p className="size-10 text-secondary">Electronics</p>
                                        </div>
                                        <div className="swiper-slide text-center">
                                            <a href="/products" className="card text-center mb-2">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-80 rounded-20">
                                                        <img src="assets/img/categories2.png" alt="" />
                                                    </figure>
                                                </div>
                                            </a>
                                            <p className="size-10 text-secondary">Clothing</p>
                                        </div>
                                        <div className="swiper-slide text-center">
                                            <a href="/products" className="card text-center mb-2">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-80 rounded-20">
                                                        <img src="assets/img/categories3.png" alt="" />
                                                    </figure>
                                                </div>
                                            </a>
                                            <p className="size-10 text-secondary">Appliences</p>
                                        </div>
                                        <div className="swiper-slide text-center">
                                            <a href="/products" className="card text-center mb-2">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-80 rounded-20">
                                                        <img src="assets/img/categories4.png" alt="" />
                                                    </figure>
                                                </div>
                                            </a>
                                            <p className="size-10 text-secondary">Mobiles</p>
                                        </div>
                                        <div className="swiper-slide text-center">
                                            <a href="/products" className="card text-center mb-2">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-80 rounded-20">
                                                        <img src="assets/img/categories5.png" alt="" />
                                                    </figure>
                                                </div>
                                            </a>
                                            <p className="size-10 text-secondary">iPhone</p>
                                        </div>
                                    </div>
                                </div> */}

                            <div className="swiper-container connectionwiper">
                                <Swiper
                                    spaceBetween={10}
                                    slidesPerView="auto" // Change this to show multiple slides at once
                                >
                                    {categories.map((category, index) => (
                                        <SwiperSlide key={index} className="text-center">
                                            <a href="/products" className="card text-center mb-2">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-80 rounded-20">
                                                        <img src={category.image} alt={category.title} />
                                                    </figure>
                                                </div>
                                            </a>
                                            <p className="size-10 text-secondary">{category.title}</p>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                    {/*high light */}
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="card theme-bg">
                                <img
                                    src="assets/img/apple-watch.png"
                                    alt=""
                                    className="iwatchposition"
                                />
                                <div className="card-body py-3">
                                    <div className="row">
                                        <div className="col-auto align-self-center">
                                            <h4>
                                                <span className="fw-light">15% OFF</span>
                                                <br />
                                                iWatch
                                            </h4>
                                        </div>
                                        <div className="col-auto align-self-center ms-auto text-end">
                                            <img src="assets/img/visa.png" alt="" />
                                            <p className="size-10">
                                                <span className="text-muted">Buy at 1450.00</span>
                                                <br />
                                                Using Credit Card
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*products */}
                    <div className="row mb-3">
                        <div className="col-12 px-0">
                            {/* swiper categories */}
                            {/* <div className="swiper-container connectionwiper">
                                    <div className="swiper-wrapper">
                                        <div className="swiper-slide text-center">
                                            <a href="/product" className="card text-center bg-theme">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-90 rounded-15 mb-1">
                                                        <img src="assets/img/categories3.jpg" alt="" />
                                                    </figure>
                                                    <p className="text-center size-12">
                                                        <small className="text-muted">LAVAA 3500</small>
                                                        <br />$ 459.00
                                                    </p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="swiper-slide text-center">
                                            <a href="/product" className="card text-center bg-theme">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-90 rounded-15 mb-1">
                                                        <img src="assets/img/categories4.png" alt="" />
                                                    </figure>
                                                    <p className="text-center size-12">
                                                        <small className="text-muted">Galaxy S20</small>
                                                        <br />$ 459.00
                                                    </p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="swiper-slide text-center">
                                            <a href="/product" className="card text-center bg-theme">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-90 rounded-15 mb-1">
                                                        <img src="assets/img/categories5.png" alt="" />
                                                    </figure>
                                                    <p className="text-center size-12">
                                                        <small className="text-muted">iPhone 12SR</small>
                                                        <br />$ 1012.00
                                                    </p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="swiper-slide text-center">
                                            <a href="/product" className="card text-center bg-theme">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-90 rounded-15 mb-1">
                                                        <img src="assets/img/categories3.jpg" alt="" />
                                                    </figure>
                                                    <p className="text-center size-12">
                                                        <small className="text-muted">LAVAA 3500</small>
                                                        <br />$ 459.00
                                                    </p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="swiper-slide text-center">
                                            <a href="/product" className="card text-center bg-theme">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-90 rounded-15 mb-1">
                                                        <img src="assets/img/categories4.png" alt="" />
                                                    </figure>
                                                    <p className="text-center size-12">
                                                        <small className="text-muted">Galaxy S20</small>
                                                        <br />$ 459.00
                                                    </p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div> */}

                            <div className="swiper-container connectionwiper">
                                <Swiper
                                    spaceBetween={10}
                                    slidesPerView="auto" // Adjust as needed

                                >
                                    {products.map((product, index) => (
                                        <SwiperSlide key={index} className="text-center">
                                            <a href="/product" className="card text-center bg-theme">
                                                <div className="card-body p-1">
                                                    <figure className="avatar avatar-90 rounded-15 mb-1">
                                                        <img src={product.image} alt={product.title} />
                                                    </figure>
                                                    <p className="text-center size-12">
                                                        <small className="text-muted">{product.title}</small>
                                                        <br />{product.price}
                                                    </p>
                                                </div>
                                            </a>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                    {/* product medsize box  */}
                    <div className="row mb-2">
                        <div className="col-6 col-md-4 col-lg-3">
                            <div className="card mb-3">
                                <div className="card-body p-1 position-relative">
                                    <div className="position-absolute start-0 top-0 m-2 z-index-1">
                                        <div className="tag tag-small bg-theme">26% OFF</div>
                                    </div>
                                    <div className="position-absolute end-0 top-0 p-2 z-index-1 ">
                                        <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-white bg-danger">
                                            <i className="bi bi-heart size-10 vm" />
                                        </button>
                                    </div>
                                    <figure className="avatar w-100 rounded-15 border">
                                        <img
                                            src="assets/img/categories4.png"
                                            alt=""
                                            className="w-100"
                                        />
                                    </figure>
                                </div>
                                <div className="card-body pt-2">
                                    <div className="row">
                                        <div className="col">
                                            <p className="small">
                                                <small className="text-muted size-12">LAVA B500</small>
                                                <br />$ 265.00
                                            </p>
                                        </div>
                                        <div className="col-auto px-0">
                                            <button className="btn btn-sm btn-link text-color-theme">
                                                <i className="bi bi-bag-plus" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            <div className="card mb-3">
                                <div className="card-body p-1 position-relative">
                                    <div className="position-absolute start-0 top-0 m-2 z-index-1">
                                        <div className="tag tag-small bg-theme">30% OFF</div>
                                    </div>
                                    <div className="position-absolute end-0 top-0 p-2 z-index-1 ">
                                        <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-danger">
                                            <i className="bi bi-heart size-10 vm" />
                                        </button>
                                    </div>
                                    <figure className="avatar w-100 rounded-15 border">
                                        <img
                                            src="assets/img/categories1.png"
                                            alt=""
                                            className="w-100"
                                        />
                                    </figure>
                                </div>
                                <div className="card-body pt-2">
                                    <div className="row">
                                        <div className="col">
                                            <p className="small">
                                                <small className="text-muted size-12">Smasung S500</small>
                                                <br />$ 459.00
                                            </p>
                                        </div>
                                        <div className="col-auto px-0">
                                            <button className="btn btn-sm btn-link text-color-theme">
                                                <i className="bi bi-bag-plus" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            <div className="card mb-3">
                                <div className="card-body p-1 position-relative">
                                    <div className="position-absolute start-0 top-0 m-2 z-index-1">
                                        <div className="tag tag-small bg-danger text-white">SALE</div>
                                    </div>
                                    <div className="position-absolute end-0 top-0 p-2 z-index-1 ">
                                        <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-white bg-danger">
                                            <i className="bi bi-heart size-10 vm" />
                                        </button>
                                    </div>
                                    <figure className="avatar w-100 rounded-15 border">
                                        <img
                                            src="assets/img/categories2.png"
                                            alt=""
                                            className="w-100"
                                        />
                                    </figure>
                                </div>
                                <div className="card-body pt-2">
                                    <div className="row">
                                        <div className="col">
                                            <p className="small">
                                                <small className="text-muted size-12">Clothing</small>
                                                <br />$ 265.00
                                            </p>
                                        </div>
                                        <div className="col-auto px-0">
                                            <button className="btn btn-sm btn-link text-color-theme">
                                                <i className="bi bi-bag-plus" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3">
                            <div className="card mb-3">
                                <div className="card-body p-1 position-relative">
                                    <div className="position-absolute start-0 top-0 m-2 z-index-1">
                                        <div className="tag tag-small bg-warning text-white">
                                            1 Offer
                                        </div>
                                    </div>
                                    <div className="position-absolute end-0 top-0 p-2 z-index-1 ">
                                        <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-danger">
                                            <i className="bi bi-heart size-10 vm" />
                                        </button>
                                    </div>
                                    <figure className="avatar w-100 rounded-15 border">
                                        <img
                                            src="assets/img/categories3.png"
                                            alt=""
                                            className="w-100"
                                        />
                                    </figure>
                                </div>
                                <div className="card-body pt-2">
                                    <div className="row">
                                        <div className="col">
                                            <p className="small">
                                                <small className="text-muted size-12">Wash.Machine</small>
                                                <br />$ 254.00
                                            </p>
                                        </div>
                                        <div className="col-auto px-0">
                                            <button className="btn btn-sm btn-link text-color-theme">
                                                <i className="bi bi-bag-plus" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* offer banner*/}
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="card theme-bg overflow-hidden">
                                <figure
                                    className="m-0 p-0 position-absolute top-0 start-0 w-100 h-100 coverimg right-center-img"
                                    style={{ backgroundImage: 'url("assets/img/offerbg.png")' }}
                                >
                                    <img
                                        src="assets/img/offerbg.png"
                                        alt=""
                                        style={{ display: "none" }}
                                    />
                                </figure>
                                <div className="card-body py-3">
                                    <div className="row">
                                        <div className="col-10 align-self-center">
                                            <h1 className="mb-3">
                                                <span className="fw-light">15% OFF</span>
                                                <br />
                                                GiftCard
                                            </h1>
                                            <p>
                                                Thank you for spending with us, You have received Gift Card.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* product medsize box no gap */}
                  <div className="row mb-4 g-0 ">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="row mx-0">
                                        <div className="col-6 col-md-4 col-lg-3 border-end p-3">
                                            <div className="position-relative">
                                                <div className="position-absolute start-0 top-0 z-index-1">
                                                    <div className="tag tag-small bg-theme">26% OFF</div>
                                                </div>
                                                <div className="position-absolute end-0 top-0 z-index-1 ">
                                                    <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-danger">
                                                        <i className="bi bi-heart size-10 vm" />
                                                    </button>
                                                </div>
                                                <figure className="avatar w-100 rounded-15">
                                                    <img
                                                        src="assets/img/categories4.png"
                                                        alt=""
                                                        className="w-100"
                                                    />
                                                </figure>
                                                <div className="row">
                                                    <div className="col">
                                                        <p className="small">
                                                            <small className="text-muted size-12">
                                                                LAVA B500
                                                            </small>
                                                            <br />$ 265.00
                                                        </p>
                                                    </div>
                                                    <div className="col-auto px-0">
                                                        <button className="btn btn-sm btn-link text-color-theme">
                                                            <i className="bi bi-bag-plus" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-3 p-3">
                                            <div className="position-relative">
                                                <div className="position-absolute start-0 top-0  z-index-1">
                                                    <div className="tag tag-small bg-theme">30% OFF</div>
                                                </div>
                                                <div className="position-absolute end-0 top-0  z-index-1 ">
                                                    <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-danger">
                                                        <i className="bi bi-heart size-10 vm" />
                                                    </button>
                                                </div>
                                                <figure className="avatar w-100 rounded-15">
                                                    <img
                                                        src="assets/img/categories1.png"
                                                        alt=""
                                                        className="w-100"
                                                    />
                                                </figure>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="small">
                                                        <small className="text-muted size-12">
                                                            Smasung S500
                                                        </small>
                                                        <br />$ 459.00
                                                    </p>
                                                </div>
                                                <div className="col-auto px-0">
                                                    <button className="btn btn-sm btn-link text-color-theme">
                                                        <i className="bi bi-bag-plus" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mx-0 border-top">
                                        <div className="col-6 col-md-4 col-lg-3 border-end p-3">
                                            <div className="position-relative">
                                                <div className="position-absolute start-0 top-0 z-index-1">
                                                    <div className="tag tag-small bg-danger text-white">
                                                        SALE
                                                    </div>
                                                </div>
                                                <div className="position-absolute end-0 top-0 z-index-1 ">
                                                    <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-white bg-danger">
                                                        <i className="bi bi-heart size-10 vm" />
                                                    </button>
                                                </div>
                                                <figure className="avatar w-100 rounded-15">
                                                    <img
                                                        src="assets/img/categories2.png"
                                                        alt=""
                                                        className="w-100"
                                                    />
                                                </figure>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="small">
                                                        <small className="text-muted size-12">Clothing</small>
                                                        <br />$ 65.00
                                                    </p>
                                                </div>
                                                <div className="col-auto px-0">
                                                    <button className="btn btn-sm btn-link text-color-theme">
                                                        <i className="bi bi-bag-plus" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 col-lg-3 p-3">
                                            <div className="position-relative">
                                                <div className="position-absolute start-0 top-0 z-index-1">
                                                    <div className="tag tag-small bg-warning text-white">
                                                        1 Offer
                                                    </div>
                                                </div>
                                                <div className="position-absolute end-0 top-0 z-index-1 ">
                                                    <button className="btn btn-sm btn-26 roudned-circle shadow-sm shadow-danger text-danger">
                                                        <i className="bi bi-heart size-10 vm" />
                                                    </button>
                                                </div>
                                                <figure className="avatar w-100 rounded-15">
                                                    <img
                                                        src="assets/img/categories3.png"
                                                        alt=""
                                                        className="w-100"
                                                    />
                                                </figure>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="small">
                                                        <small className="text-muted size-12">
                                                            Wash.Machine
                                                        </small>
                                                        <br />$ 254.00
                                                    </p>
                                                </div>
                                                <div className="col-auto px-0">
                                                    <button className="btn btn-sm btn-link text-color-theme">
                                                        <i className="bi bi-bag-plus" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                    {/* popular shopping  */}
                    <div className="row mb-3 gap-0">
                        <div className="col">
                            <h6 className="title">Popular</h6>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <ul className="list-group list-group-flush bg-none">
                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <div className="avatar avatar-44 shadow-sm border card rounded-15">
                                                        <img src="assets/img/categories2.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="col align-self-center ps-0">
                                                    <p className="text-secondary size-10 mb-0">Clothing</p>
                                                    <p>ZIVACA SK10</p>
                                                </div>
                                                <div className="col align-self-center text-end">
                                                    <p className="text-secondary text-muted size-10 mb-0">
                                                        On Sale
                                                    </p>
                                                    <p>80.00</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <div className="avatar avatar-44 shadow-sm border card rounded-15">
                                                        <img src="assets/img/categories4.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="col align-self-center ps-0">
                                                    <p className="text-secondary size-10 mb-0">Mobiles</p>
                                                    <p>LAVAA 3005</p>
                                                </div>
                                                <div className="col align-self-center text-end">
                                                    <p className="text-secondary text-muted size-10 mb-0">
                                                        Best Exchange
                                                    </p>
                                                    <p>250.00</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <div className="avatar avatar-44 shadow-sm border card rounded-15">
                                                        <img src="assets/img/categories1.png" alt="" />
                                                    </div>
                                                </div>
                                                <div className="col align-self-center ps-0">
                                                    <p className="text-muted size-10 mb-0">Electronics</p>
                                                    <p>Samsung CT30</p>
                                                </div>
                                                <div className="col align-self-center text-end">
                                                    <p className="text-secondary text-muted size-10 mb-0">
                                                        20% OFF
                                                    </p>
                                                    <p>355.00</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* main page content ends */}
            </main>
            {/* Page ends*/}
            {/* Footer */}
            <footer className="footer">
                <div className="container">
                    <ul className="nav nav-pills nav-justified">
                        <li className="nav-item">
                            <a className="nav-link" href="/">
                                <span>
                                    <i className="nav-icon bi bi-house" />
                                    <span className="nav-text">Home</span>
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/statistics">
                                <span>
                                    <i className="nav-icon bi bi-binoculars" />
                                    <span className="nav-text">Statistics</span>
                                </span>
                            </a>
                        </li>
                        <li className="nav-item centerbutton">
                            <button
                                type="button"
                                className="nav-link"
                                data-bs-toggle="modal"
                                data-bs-target="#menumodal"
                                id="centermenubtn"
                            >
                                <span className="theme-radial-gradient">
                                    <i className="bi bi-grid size-22" />
                                </span>
                            </button>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link active" href="/shop">
                                <span>
                                    <i className="nav-icon bi bi-bag" />
                                    <span className="nav-text">Shop</span>
                                </span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/wallet">
                                <span>
                                    <i className="nav-icon bi bi-wallet2" />
                                    <span className="nav-text">Wallet</span>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </footer>
            {/* Menu Modal */}
            <div className="modal fade" id="menumodal" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-sm modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content shadow">
                        <div className="modal-body">
                            <h1 className="mb-4">
                                <span className="text-secondary fw-light">Quick</span>
                                <br />
                                Actions!
                            </h1>
                            <div className="text-center">
                                <img src="assets/img/QRCode.png" alt="" className="mb-2" />
                                <p className="small text-secondary mb-4">
                                    Ask to scan this QR-Code
                                    <br />
                                    to accept money
                                </p>
                            </div>
                            <div className="row justify-content-center mb-4">
                                <div className="col-auto text-center">
                                    <a
                                        href="/bills"
                                        className="avatar avatar-70 p-1 shadow-sm shadow-danger rounded-20 bg-opac mb-2"
                                        data-bs-dismiss="modal"
                                    >
                                        <div className="icons text-danger">
                                            <i className="bi bi-receipt-cutoff size-24" />
                                        </div>
                                    </a>
                                    <p className="size-10 text-secondary">Pay Bill</p>
                                </div>
                                <div className="col-auto text-center">
                                    <a
                                        href="/sendmoney"
                                        className="avatar avatar-70 p-1 shadow-sm shadow-purple rounded-20 bg-opac mb-2"
                                        data-bs-dismiss="modal"
                                    >
                                        <div className="icons text-purple">
                                            <i className="bi bi-arrow-up-right size-24" />
                                        </div>
                                    </a>
                                    <p className="size-10 text-secondary">Send Money</p>
                                </div>
                                <div className="col-auto text-center">
                                    <a
                                        href="/receivemoney"
                                        className="avatar avatar-70 p-1 shadow-sm shadow-success rounded-20 bg-opac mb-2"
                                        data-bs-dismiss="modal"
                                    >
                                        <div className="icons text-success">
                                            <i className="bi bi-arrow-down-left size-24" />
                                        </div>
                                    </a>
                                    <p className="size-10 text-secondary">Receive Money</p>
                                </div>
                            </div>
                            <div className="row justify-content-center mb-2">
                                <div className="col-auto text-center">
                                    <a
                                        href="/withdraw"
                                        className="avatar avatar-70 p-1 shadow-sm shadow-secondary rounded-20 bg-opac mb-2"
                                        data-bs-dismiss="modal"
                                    >
                                        <div className="icons text-secondary">
                                            <i className="bi bi-bank size-24" />
                                        </div>
                                    </a>
                                    <p className="size-10 text-secondary">Withdraw</p>
                                </div>
                                <div className="col-auto text-center">
                                    <a
                                        href="/addmoney"
                                        className="avatar avatar-70 p-1 shadow-sm shadow-warning rounded-20 bg-opac mb-2"
                                        data-bs-dismiss="modal"
                                    >
                                        <div className="icons text-warning">
                                            <i className="bi bi-wallet2 size-24" />
                                        </div>
                                    </a>
                                    <p className="size-10 text-secondary">Add Money</p>
                                </div>
                                <div className="col-auto text-center">
                                    <div
                                        className="avatar avatar-70 p-1 shadow-sm shadow-info rounded-20 bg-opac mb-2"
                                        data-bs-dismiss="modal"
                                    >
                                        <div className="icons text-info">
                                            <i className="bi bi-tv size-24" />
                                        </div>
                                    </div>
                                    <p className="size-10 text-secondary">Recharge</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer ends*/}
            {/* filter menu */}
            <div className="filter">
                <div className="card shadow h-100">
                    <div className="card-header">
                        <div className="row">
                            <div className="col align-self-center">
                                <h6 className="mb-0">Filter Criteria</h6>
                                <p className="text-secondary small">2154 products</p>
                            </div>
                            <div className="col-auto px-0">
                                <button className="btn btn-link text-danger filter-close">
                                    <i className="bi bi-x size-22" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body overflow-auto">
                        <div className="mb-4">
                            <h6>Select Range</h6>
                            <div id="rangeslider" className="mt-4" />
                        </div>
                        <div className="row mb-4">
                            <div className="col">
                                <div className="form-floating">
                                    <input
                                        type="number"
                                        className="form-control"
                                        min={0}
                                        max={500}
                                        defaultValue={100}
                                        step={1}
                                        id="input-select"
                                    />
                                    <label htmlFor="input-select">Minimum</label>
                                </div>
                            </div>
                            <div className="col-auto align-self-center"> to </div>
                            <div className="col">
                                <div className="form-floating">
                                    <input
                                        type="number"
                                        className="form-control"
                                        min={0}
                                        max={500}
                                        defaultValue={200}
                                        step={1}
                                        id="input-number"
                                    />
                                    <label htmlFor="input-number">Maximum</label>
                                </div>
                            </div>
                        </div>
                        <div className="form-floating mb-4">
                            <select className="form-control" id="filtertype">
                                <option selected="">Cloths</option>
                                <option>Electronics</option>
                                <option>Furniture</option>
                            </select>
                            <label htmlFor="filtertype">Select Shopping Type</label>
                        </div>
                        <div className="form-group floating-form-group active mb-4">
                            <h6 className="mb-3">Select Facilities</h6>
                            <div className="form-check form-switch mb-2">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="men"
                                    defaultChecked=""
                                />
                                <label className="form-check-label" htmlFor="men">
                                    Men
                                </label>
                            </div>
                            <div className="form-check form-switch mb-2">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="woman"
                                    defaultChecked=""
                                />
                                <label className="form-check-label" htmlFor="woman">
                                    Women
                                </label>
                            </div>
                            <div className="form-check form-switch mb-2">
                                <input type="checkbox" className="form-check-input" id="Sport" />
                                <label className="form-check-label" htmlFor="Sport">
                                    Sport
                                </label>
                            </div>
                            <div className="form-check form-switch mb-2">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="homedecor"
                                />
                                <label className="form-check-label" htmlFor="homedecor">
                                    Home Decor
                                </label>
                            </div>
                            <div className="form-check form-switch mb-2">
                                <input type="checkbox" className="form-check-input" id="kidsplay" />
                                <label className="form-check-label" htmlFor="kidsplay">
                                    Kid's Play
                                </label>
                            </div>
                        </div>
                        <div className="form-floating mb-3">
                            <input type="text" className="form-control" placeholder="Keyword" />
                            <label htmlFor="input-select">Keyword</label>
                        </div>
                        <div className="form-floating mb-3">
                            <select className="form-control" id="discount">
                                <option>10% </option>
                                <option>30%</option>
                                <option>50%</option>
                                <option>80%</option>
                            </select>
                            <label htmlFor="discount">Offer Discount</label>
                        </div>
                    </div>
                    <div className="card-footer">
                        <button className="btn btn-lg btn-success w-100 shadow-sm shadow-success">
                            Search
                        </button>
                    </div>
                </div>
            </div>
            {/* filter menu ends*/}
        </>
    )
}

export default Shop