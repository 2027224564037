import React from 'react'

function Receivemoney() {
    return (
        <>
            {/* loader section */}

            {/* loader section ends */}
            {/* Begin page */}
            <main className="h-100">
                {/* Header */}
                <header className="header position-fixed">
                    <div className="row">
                        <div className="col-auto">
                            <button type="button" className="btn btn-light btn-44 back-btn">
                                <i className="bi bi-arrow-left" />
                            </button>
                        </div>
                        <div className="col text-center">
                                <div className="logo-small">
                                   <a href='/'> <img src="assets/img/logo.png" alt="" />
                                    <h5>
                                        <span className="text-secondary fw-light">Finance</span>
                                        <br />
                                        Wallet
                                    </h5>
                                    </a>
                                </div>
                            </div>
                        <div className="col-auto">
                            <a
                                href="/profile"
                                target="_self"
                                className="btn btn-light btn-44"
                            >
                                <i className="bi bi-person-circle" />
                                <span className="count-indicator" />
                            </a>
                        </div>
                    </div>
                </header>
                {/* Header ends */}
                {/* main page content */}
                <div className="main-container container">
                    {/* select Amount */}
                    <div className="row">
                        <div className="col-12 text-center mb-4">
                            <input
                                type="text"
                                className="trasparent-input text-center"
                                defaultValue={100.0}
                                placeholder="Enter Amount"
                            />
                            <div className="text-center">
                                <span className="text-secondary">Amount in USD</span>
                                <button
                                    className="btn btn-link btn-sm text-secondary px-1 dropdown-toggle"
                                    type="button"
                                    id="ln1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                ></button>
                                <ul
                                    className="dropdown-menu dropdown-menu-end shadow border-0 w-110"
                                    aria-labelledby="ln1"
                                >
                                    <li>
                                        <a className="dropdown-item active" href="#">
                                            USD
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            AUD
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            BTC
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            ETH
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* group user */}
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group form-floating is-valid mb-1">
                                <input
                                    type="text"
                                    className="form-control "
                                    id="receivefrom"
                                    placeholder="Receive From"
                                    defaultValue="Maxartkiller"
                                />
                                <label className="form-control-label" htmlFor="receivefrom">
                                    Receive From
                                </label>
                                <button type="button" className="text-color-theme tooltip-btn">
                                    <i className="bi bi-person-badge" />
                                </button>
                            </div>
                            <div className="card mb-4">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-auto">
                                            <div className="card">
                                                <div className="card-body p-1">
                                                    <div className="form-check avatar">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="contact1"
                                                            defaultChecked=""
                                                        />
                                                        <label
                                                            htmlFor="contact1"
                                                            className="avatar avatar-44 shadow-sm rounded-15"
                                                        >
                                                            <img src="assets/img/user1.jpg" alt="" />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col align-self-center ps-0">
                                            <p className="mb-1 text-color-theme">Maxartkiller</p>
                                            <p className="text-secondary size-12">+4789680000000</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-4">
                                <div className="card-header border-0">
                                    <p>Selected Contacts</p>
                                </div>
                                <div className="card-body pt-0">
                                    <div className="row">
                                        <div className="col-auto">
                                            <div className="avatar-group">
                                                <div className="avatar avatar-50 rounded-20 shadow-sm bg-white p-1">
                                                    <img
                                                        src="assets/img/user2.jpg"
                                                        alt=""
                                                        className="rounded-15"
                                                    />
                                                </div>
                                                <div className="avatar avatar-50 rounded-20 shadow-sm bg-white p-1">
                                                    <img
                                                        src="assets/img/user3.jpg"
                                                        alt=""
                                                        className="rounded-15"
                                                    />
                                                </div>
                                                <div className="avatar avatar-50 rounded-20 shadow-sm bg-white p-1">
                                                    <img
                                                        src="assets/img/user4.jpg"
                                                        alt=""
                                                        className="rounded-15"
                                                    />
                                                </div>
                                                <div className="avatar avatar-50 rounded-20 shadow-sm bg-white p-1">
                                                    <img
                                                        src="assets/img/user1.jpg"
                                                        alt=""
                                                        className="rounded-15"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col align-self-center ps-0">
                                            <p className="text-secondary small">
                                                +15
                                                <br />
                                                More
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group form-floating is-valid mb-3">
                                <input
                                    type="text"
                                    className="form-control "
                                    id="Subject"
                                    placeholder="Subject"
                                    defaultValue="Need Help"
                                />
                                <label className="form-control-label" htmlFor="Subject">
                                    Subject
                                </label>
                            </div>
                            <div className="form-group form-floating is-valid mb-4">
                                <textarea
                                    className="form-control "
                                    id="description"
                                    placeholder="Description"
                                    defaultValue={
                                        "Need help 3 months medical emergency \n                        "
                                    }
                                />
                                <label className="form-control-label" htmlFor="description">
                                    Description
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* send requet button */}
                    <div className="row mb-4">
                        <div className="col-12 ">
                            <a
                                href="/thankyou"
                                className="btn btn-default btn-lg shadow-sm w-100"
                            >
                                Send Request
                            </a>
                        </div>
                    </div>
                </div>
                {/* main page content ends */}
            </main>
            {/* Page ends*/}
        </>

    )
}

export default Receivemoney