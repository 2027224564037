import React from 'react'

function Pay() {
    return (
        <>
            {/* loader section */}
       
            {/* loader section ends */}
            {/* Begin page */}
            <main className="h-100">
                {/* Header */}
                <header className="header position-fixed">
                    <div className="row">
                        <div className="col-auto">
                            <button type="button" className="btn btn-light btn-44 back-btn">
                                <i className="bi bi-arrow-left" />
                            </button>
                        </div>
                        <div className="col text-center">
                                <div className="logo-small">
                                   <a href='/'> <img src="assets/img/logo.png" alt="" />
                                    <h5>
                                        <span className="text-secondary fw-light">Finance</span>
                                        <br />
                                        Wallet
                                    </h5>
                                    </a>
                                </div>
                            </div>
                        <div className="col-auto">
                            <a
                                href="profile.html"
                                target="_self"
                                className="btn btn-light btn-44"
                            >
                                <i className="bi bi-person-circle" />
                                <span className="count-indicator" />
                            </a>
                        </div>
                    </div>
                </header>
                {/* Header ends */}
                {/* main page content */}
                <div className="main-container container">
                    {/* banner */}
                    <div className="row my-5">
                        <div className="col-12 text-center">
                            <img src="assets/img/QRCode.png" alt="" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <h1 className="mb-3">
                                <span className="text-secondary fw-light">Scan QR</span>
                                <br />
                                Pay &amp; Instant
                            </h1>
                            <p className="text-secondary mb-3">
                                Its instant and easy. There are 1500k sellers accept payment via
                                Finwallapp. To Scan Allow access to camera.
                            </p>
                            <p className="text-secondary">
                                Now you can also make payments for 100 other standard payment
                                services.
                            </p>
                        </div>
                        <div className="col-12 mb-4 text-center">
                            <button
                                className="btn btn-default btn-lg mb-3 px-4 shadow-sm"
                                data-bs-target="#cammodal"
                                data-bs-toggle="modal"
                            >
                                <i className="bi bi-camera mx-2" /> Enable Camera
                            </button>
                        </div>
                    </div>
                    {/* offers banner */}
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="card theme-bg overflow-hidden">
                                <figure className="m-0 p-0 position-absolute top-0 start-0 w-100 h-100 coverimg right-center-img">
                                    <img src="assets/img/offerbg.png" alt="" />
                                </figure>
                                <div className="card-body py-4">
                                    <div className="row">
                                        <div className="col-10 align-self-center">
                                            <h1 className="mb-3">
                                                <span className="fw-light">15% OFF</span>
                                                <br />
                                                GiftCard
                                            </h1>
                                            <p>
                                                Thank you for spending with us, You have received Gift Card.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* camera modal*/}
                    <div
                        className="modal fade"
                        id="cammodal"
                        tabIndex={-1}
                        aria-labelledby="cammodalLabel"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-xsm modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h6 className="modal-title" id="cammodalLabel">
                                        Give Permission
                                    </h6>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                    />
                                </div>
                                <div className="modal-body text-center">
                                    <div className="avatar avatar-80 p-1 shadow-sm shadow-primary rounded-15 mb-4">
                                        <div className="icons bg-primary text-white rounded-12">
                                            <i className="bi bi-camera size-24" />
                                        </div>
                                    </div>
                                    <h3 className="text-color-theme mb-2">Camera Access</h3>
                                    <p className="text-secondary">
                                        To scan QR code we need to access camera please allow app to
                                        access camera.
                                    </p>
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-lg btn-primary rounded-15"
                                    data-bs-dismiss="modal"
                                >
                                    Allow
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* main page content ends */}
            </main>
            {/* Page ends*/}

        </>

    )
}

export default Pay